
import React from 'react';
import PropTypes from 'prop-types';
import { scrollTo } from '../Utils/Utils';

function ScrollButton({ anchor, text, black, style }) {
    let id = black ? "section05Black" : "section05";
    let className = black ? "scrollButtonBlack" : "scrollButton";

    return (
        <div id={id} className={className}>
            <button style={{ ...style }} onClick={() => { scrollTo(anchor, "smooth"); }} className='hidden-btn'><span></span>{text}</button>
        </div>
    );
}

ScrollButton.propTypes = {
    anchor: PropTypes.string, 
    text: PropTypes.string,
    black: PropTypes.bool,
    style: PropTypes.object
};

export default ScrollButton;