import React from "react";
import PropTypes from 'prop-types';
import { AiOutlineClose } from "react-icons/ai";
import HoverButton from "./HoverButton";

function Modal({ children, closeButton, fadeIn, isMobile, onClose, style }) {
    let modalContentStyle = { "backgroundColor": "#fff", "position": "absolute", "width": "50%", "boxShadow": "0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.19)", "zIndex": "4", "cursor": "default", ...style };
    let modalOverlayStyle = { "display": "flex", "justifyContent": "center", "alignItems": "center", "position": "fixed", "zIndex": "3", "backgroundColor": "rgba(0, 0, 0, 0.25)", "height": "100vh", "width": "100vw", "left": "0px", "top": "0px", "cursor": "pointer" };
    if(fadeIn) {
        Object.assign(modalOverlayStyle, { "animation": `fadeIn ${fadeIn}s` });
    }
    return(
        <div style={modalOverlayStyle} className="modal">
            <button className="hidden-btn" onClick={() => onClose()} style={{ "width": "100%", "height": "100%" }} />
            <div style={isMobile ? {...modalContentStyle, "width": "85%" } : modalContentStyle} className="modal_content">
                {closeButton ? (
                    <div style={{ "textAlign":"right", "padding": "12px 8px 8px 8px", "display": "flex", "justifyContent": "right" }}>
                        <HoverButton 
                            normalIcon={<AiOutlineClose style={{ "transform": "scale(1.5)", "color": "grey" }} className="ai-edit-icon"/>}
                            hoverIcon={<AiOutlineClose style={{ "transform": "scale(1.5)", "color": "black" }} className="ai-edit-icon"/>}
                            updateParent={onClose}
                            style={{ "display": "inline" }}
                        />
                    </div>
                ) : null}
                <div style={closeButton ? { "padding": "0px 48px 48px 48px" } : { "padding": "48px" }}>
                    { children }
                </div>
            </div>
        </div>
    );
}

Modal.propTypes = {
    closeButton: PropTypes.bool,
    fadeIn: PropTypes.number,
    isMobile: PropTypes.bool,
    onclose: PropTypes.func
};

export default Modal;