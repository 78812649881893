import React, { useState, useMemo, useRef, useEffect } from 'react'
import TinderCard from 'react-tinder-card'
import { createDeepClone, getObjectValue, log } from '../Utils/Utils';
import { deriveImageKey } from '../Utils/ImageDBUtils';
import { createBase64URI } from '../Utils/WeatherUtils';
import Loading from '../pages/Loading';

function DeerTinder ({ isMobile, images, imagesObject, updateImagesObject }) {
    const [currentIndex, setCurrentIndex] = useState(images.length - 1);
    const [hasLeftScreen, setHasLeftScreen] = useState(true);
    const [imagesToBeLabeledObject, setImagesToBeLabeledObject] = useState({});
    const [dataFetched, setDataFetched] = useState(false);
    // used for outOfFrame closure
    const currentIndexRef = useRef(currentIndex);
    const childRefs = useMemo(() => Array(Object.keys(imagesToBeLabeledObject).length).fill(0).map(() => React.createRef()), [imagesToBeLabeledObject]);
    const canGoBack = imagesToBeLabeledObject ? currentIndex < Object.keys(imagesToBeLabeledObject).length - 1 : currentIndex < images.length - 1;
    const canSwipe = currentIndex >= 0 && hasLeftScreen;

    const updateCurrentIndex = (val) => {
        setCurrentIndex(val);
        currentIndexRef.current = val;
    };

    const swiped = (direction, image, index) => {
        setHasLeftScreen(false);
        updateCurrentIndex(index - 1);
        let imageKey = deriveImageKey(image);
        let dataPoint = 0;
        if(direction === "right") {
            dataPoint = 1;
        }
        Object.assign(imagesToBeLabeledObject[imageKey].data, { "Deer Sighting": dataPoint });
    };

    const outOfFrame = (image, idx) => {
        log(`${image.name} (${idx}) left the screen!`, currentIndexRef.current);
        // handle the case in which go back is pressed before card goes outOfFrame
        currentIndexRef.current >= idx && childRefs[idx].current.restoreCard();

        let imageKey = deriveImageKey(image);

        setHasLeftScreen(true);
        updateImagesObject({ [imageKey]: imagesToBeLabeledObject[imageKey] }, true);
    };

    const swipe = async (dir) => {
        if (canSwipe && currentIndex < Object.keys(imagesToBeLabeledObject).length) {
            await childRefs[currentIndex].current.swipe(dir); // Swipe the card!
        }
    };

    const goBack = async () => {
        if (!canGoBack) {
            return;
        }
        const newIndex = currentIndex + 1;

        let image = images[newIndex];
        let imageKey = deriveImageKey(image);
        delete imagesToBeLabeledObject[imageKey].data["Deer Sighting"];

        updateCurrentIndex(newIndex);
        await childRefs[newIndex].current.restoreCard();

        updateImagesObject({ [imageKey]: imagesToBeLabeledObject[imageKey] }, true);
    };

    // Arrow Key Functionality
    useEffect(() => {
        const handleKeyDown = (e) => {
            let direction = '';
            switch (e.key) {
                case 'ArrowLeft':
                    direction = 'left';
                    break;
                case 'ArrowRight':
                    direction = 'right';
                    break;
                default:
                    break;
            }
            if(canSwipe && direction) {
                swipe(direction);
            }
        };
    
        window.addEventListener('keydown', handleKeyDown);
    
        return () => {
          window.removeEventListener('keydown', handleKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(imagesObject && Object.keys(imagesObject).length > 0) {
            let newImagesToBeLabeledObject = createDeepClone(imagesToBeLabeledObject);
            Object.keys(imagesObject).map((key) => {
                let image = imagesObject[key];
                if(image && ((getObjectValue(image, "data") && typeof getObjectValue(image.data, "Deer Sighting") === "number") || !getObjectValue(image, "url"))) {
                    return true;
                } else {
                    Object.assign(newImagesToBeLabeledObject, { [key]: { ...image }});
                    return true;
                }
            });
            setCurrentIndex(Object.keys(newImagesToBeLabeledObject).length - 1)
            setImagesToBeLabeledObject(newImagesToBeLabeledObject);
            setDataFetched(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imagesObject]);

    if(dataFetched && imagesToBeLabeledObject && Object.keys(imagesToBeLabeledObject).length > 0) {
        return (
            <div>
                <div style={isMobile ? { "height": "185px" } : { "height": "260px" }}>
                    {Object.keys(imagesToBeLabeledObject).map((key, index) => {
                        let image = imagesToBeLabeledObject[key];
                        return(
                            <TinderCard
                                ref={childRefs[index]}
                                className='tinder-card-container'
                                key={image.name}
                                onSwipe={(dir) => swiped(dir, images[index], index)}
                                onCardLeftScreen={() => outOfFrame(images[index], index)}
                            >
                                <div className='tinder-card'>
                                    <img src={createBase64URI(image.url)} alt={`Your previously uploaded: ${image.name}`} style={{ "width": "100%", "borderRadius": "20px" }}/>
                                </div>
                            </TinderCard>
                        );
                    })}
                </div>
                <div className='tinder-buttons'>
                    <button className='main-button' onClick={() => swipe('left')} disabled={!canSwipe}>No Deer</button>
                    <button className='main-button' style={{ 'backgroundColor': '#c3c4d3', "marginLeft": "16px", "marginRight": "16px" }} onClick={() => goBack()} disabled={!canGoBack}>Undo</button>
                    <button className='main-button' onClick={() => swipe('right')} disabled={!canSwipe}>Deer</button>
                </div>
                <div style={{ "textAlign": "center" }}>
                    <p>Deer Vision AI Prediction: <b>{Object.values(imagesToBeLabeledObject)[currentIndex].data.prediction > 0.5 ? "Deer" : "No Deer" }</b></p>
                </div>
            </div>
        )
    } else if(dataFetched) {
        return(
            <div style={{ "width": "100%" }}>
                <p>No images left to label, upload some more images</p>
            </div>
        );
    } else {
        return(
            <Loading isMobile={isMobile} text={"data to be labeled"} />
        )
    }
}

export default DeerTinder;