import React from "react";
import PropTypes from 'prop-types';

function ToolTip({ style, text }) {
    return (
        <div style={style ? { ...style } : {}} className="help-tip">
            <p>
                {text}
            </p>
        </div>
    );
}

ToolTip.propTypes = {
    style: PropTypes.object,
    text: PropTypes.string
};

export default ToolTip;