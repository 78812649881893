import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import PropTypes from 'prop-types';

function Authentication({ auth, location }) {
    const [currentProfile, setCurrentProfile] = useState(null);
    const [dbError, setDBError] = useState('');
    const [userError, setUserError] = useState('');
    const { referrer } = useParams();

    const navigate = useNavigate();
    let pathname = location.pathname;
    let authenticated = auth.isAuthenticated();

    useEffect(() => {
        if(referrer) {
            localStorage.setItem("redirect", referrer);
            setTimeout(() => {
                navigate("/login");
            }, 50);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [referrer]);

    useEffect(() => {
        //Code does run when component mounts
        //Check for authenticated being true
        if(authenticated && pathname === "/callback") {
            let currentProfile = auth.getProfile();
            setCurrentProfile(currentProfile);
            if(localStorage.getItem("redirect")) {
                const redirectUrl = localStorage.getItem("redirect");
                localStorage.removeItem("redirect");
                setTimeout(() => {
                    navigate(`/${redirectUrl}`);
                }, 100);
            } else {
                setTimeout(() => {
                    navigate(`/deer_vision/${currentProfile.sub}/locations`);
                }, 100);
            } 
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authenticated, pathname]);

    //If authentication fails run this
    useEffect(() => {
        // code to run on component mount
        if(pathname === "/callback") {
            document.title = "Login";
            window.scrollTo(0, 0);
            let authSuccess = auth.handleAuthentication();
            if(!authSuccess) {
                window.location.pathname = "/login/"
            }
        } else if (pathname === "/logout") {
            document.title = "Logout";
            window.scrollTo(0, 0);
            auth.logout();
        } else if (pathname === "/login" || pathname === "/register") {
            document.title = "Login";
            window.scrollTo(0, 0);
            auth.login(window.location.href);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    return (
        <div style={{ "minHeight":"91.9vh" }} className="w3-content w3-container w3-padding-64" id="about">
            <div style={{ "display": "flex", "justifyContent": "center", "marginTop": "128px" }}>
                <div style={{ "maxWidth": "650px" }}>
                    {currentProfile ? (
                        <p>
                            Hello {currentProfile.email}, you have been successfully
                            logged in! If you are not automatically redirected{" "}
                            <Link to={`/deer_vision/${currentProfile.sub}/locations`}>Click here</Link>.
                        </p>
                    ) : pathname === "/logout" ? (
                        <p>You are being logged out please wait for this page to redirect!</p>
                    ) : dbError ? (
                        <p>Login Failed please <Link to="/login">Click here</Link>, refresh the page, and try again.</p>
                    ) : (
                        <p>You are being securely logged in.</p>
                    )}
                    <div className="form-error">{userError}</div>
                </div>
            </div>
        </div>
    );
}

Authentication.propTypes = {
    auth: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
};

export default Authentication;