import React from "react";
import PropTypes from 'prop-types';
import Product from "./Product";

function ProductList({ isMobile, defaultproductSize, products }) {
    return (
        <React.Fragment>
            {products && products.length > 0 ? (
                <React.Fragment>
                    {products.map((item, index) => {
                        return <Product key={index} defaultproductSize={defaultproductSize} isMobile={isMobile} product={item} />;
                    })}
                </React.Fragment>
            ) : (
                <h3>Unfortunately no products matched your search parameters</h3>
            )}
        </React.Fragment>
    );
}

ProductList.propTypes = {
    isMobile: PropTypes.bool,
    defaultproductSize: PropTypes.string,
    products: PropTypes.array
};  

export default ProductList;