import { utils, writeFile } from 'xlsx';
import { convertLocaletoFullISOFormat, convertLocaletoISOFormat, getObjectValue } from './Utils';

export const nextString = (str) => {
    if (!str) {
        return 'A';  // return 'A' if str is empty or null
    }
  
    let tail = '';
    let i = str.length -1;
    let char = str[i];
    // find the index of the first character from the right that is not a 'Z'
    while (char === 'Z' && i > 0) {
        i--;
        char = str[i];
        tail = 'A' + tail;   // tail contains a string of 'A'
    }
    if (char === 'Z') { // the string was made only of 'Z'
        return 'AA' + tail;
    }
    // increment the character that was not a 'Z'
    return str.slice(0, i) + String.fromCharCode(char.charCodeAt(0) + 1) + tail;
};
  
export const nthString = (str, n) => {
    let newString = str;
    for (let i = 0; i <= n; i++) {
        newString = nextString(newString);
    }
    return newString;
};

export const handleMergeCells = async (objectArray, ws) => {
    let offset = 0;
    let mergedCells = await Promise.all(objectArray.map((mergeObject) => {
        let header = Object.keys(mergeObject)[0];
        // console.log(`New Origin: ${nthString('', offset)}1`);
        utils.sheet_add_aoa(ws, [[header]], { origin: `${nthString('', offset)}1` });
    
        let endColumn = offset + mergeObject[header];
        let mergedCells = { s: { r: 0, c: offset }, e: { r: 0, c: endColumn } };
    
        offset = endColumn + 1;
        return mergedCells;
    }));
    return mergedCells;
};

export const insert_delete_rows_at = async (action, origin_row, num_of_rows, wb, input_sheet_name) => {
    return new Promise((resolve) => {
        let sheet_name = input_sheet_name ? input_sheet_name : 'Sheet1';
        let coords = Object.keys(wb['Sheets'][sheet_name]);
        coords = coords.filter(item => (item.charAt(0) !== '!'));
        
        let incremented_coords = {};
        
        // this thing is the bounding coords i guess, so it needs to be incremented
        let wb_ref = wb['Sheets'][sheet_name]['!ref'];
        let wb_ref_arr = wb_ref.split(':');
        let deepest_coord = wb_ref_arr[1];
        
        let deepest_row, deepest_col;
        if (/\d/.test(deepest_coord.charAt(1))) {
            //second char in coord is a digit (e.g. A12)
            deepest_row = deepest_coord.substring(1);
            deepest_col = deepest_coord.substring(0, 1);
        }
        else {
            deepest_row = deepest_coord.substring(2);
            deepest_col = deepest_coord.substring(0, 2);
        }
        
        switch(action) {
            case "insert": 
                deepest_row = (parseInt(deepest_row) + parseInt(num_of_rows)).toString();
                break;
            case "delete":
                deepest_row = (parseInt(deepest_row) - parseInt(num_of_rows)).toString();
                break;
        default:
            break;
        }
        
        let new_wb_ref = 	wb_ref_arr[0] + ':' + deepest_col + deepest_row;
        wb['Sheets'][sheet_name]['!ref'] = new_wb_ref;
        
        for (let i = 0; i < coords.length; i++) {
            let row, col;
            
            if (/\d/.test(coords[i].charAt(1))) {
                //second char in coord is a digit (e.g. A12)
                row = coords[i].substring(1);
                col = coords[i].substring(0, 1);

            }
            else {
                //second char in coord is NOT a digit (e.g. AC1)
                row = coords[i].substring(2);
                col = coords[i].substring(0, 2);
            }
            
            if (row >= origin_row) {
                // store the coords if they're >= to specified origin row
                let new_row;
                
                switch(action) {
                    case "insert": 
                        new_row = (parseInt(row) + parseInt(num_of_rows)).toString();
                        break;
                    case "delete":
                        new_row = (parseInt(row) - parseInt(num_of_rows)).toString();
                        break;
                    default:
                        break;
                }

                incremented_coords[coords[i]] = col + new_row;
            }
        }
        
        for (let orig_coord in incremented_coords) {
            // insert new rows prepended with an underscore so we don't overwrite the coords that 
            // haven't been copied yet
            let tmp_new_coord = '_'+incremented_coords[orig_coord];
            wb['Sheets'][sheet_name][tmp_new_coord] = wb['Sheets'][sheet_name][orig_coord];
        }
        
        for(let orig_coord in incremented_coords) {
            // now delete all the original coords
            delete(wb['Sheets'][sheet_name][orig_coord]);
        }
        
        for(let orig_coord in incremented_coords) {
            // and finally, removed the underscore to make the tmp coords official
            let tmp_new_coord = '_'+incremented_coords[orig_coord];
            let real_new_coord = incremented_coords[orig_coord];
            wb['Sheets'][sheet_name][real_new_coord] = wb['Sheets'][sheet_name][tmp_new_coord];
            delete(wb['Sheets'][sheet_name][tmp_new_coord]);
        }
        
        resolve(wb);
    });
};

export const generateExcelFile = async (objectArray, headersArray) => {
    // generate workbook and worksheet
    let workbook = utils.book_new();
  
    const worksheet = utils.json_to_sheet(objectArray);

    utils.book_append_sheet(workbook, worksheet, "Ledger");

    await Promise.all(headersArray.map(async(array, index) => {
        let newWorkSheet = workbook.Sheets["Ledger"];
    
        if((index + 1) !== headersArray.length) {
            workbook = await insert_delete_rows_at('insert', 1, 1, workbook, "Ledger");
        }
    
        let rowToAdd = [array];
        if(typeof array[index] === "object") {
            let mergedCells = await handleMergeCells(array, newWorkSheet);
            newWorkSheet["!merges"] = mergedCells;
        } else {
            utils.sheet_add_aoa(newWorkSheet, rowToAdd, { origin: `A${index + 1}` });
        }

        return true;
    }));
  
    return workbook;
};

export const downloadExcelFile = async (startDate, endDate, objects, headers, location) => {
    let workbook = await generateExcelFile(objects, headers);
                                        
    let fileName = `${convertLocaletoISOFormat(startDate, true)}_${convertLocaletoISOFormat(endDate, true)}_deer_vision_data_${location}`;
    writeFile(workbook, `${fileName}.xlsx`, { compression: true });
};

export const convertTimeSeriesChartDataToExcelData = async (timeSeriesCoordinateObject, timeSeriesCoordinateKey, startDate, endDate) => {
    let columnHeaders = ["Date", ...Object.keys(timeSeriesCoordinateObject)];
    let headers = [[{ [`Deer Vision Data from ${convertLocaletoFullISOFormat(startDate)} to ${convertLocaletoFullISOFormat(endDate)} for ${timeSeriesCoordinateKey}`]: columnHeaders.length }], columnHeaders];

    let spreadsheetDataObject = {};
    await Promise.all(Object.keys(timeSeriesCoordinateObject).map(async(dataType) => {
        let data = timeSeriesCoordinateObject[dataType].data;
        let datakeys = [...Object.keys(data)];
        datakeys.sort((a,b) => {
            // Turn your strings into dates, and then subtract them to get a value that is either negative, positive, or zero.
            return new Date(a) - new Date(b);
        });
        await Promise.all(datakeys.map((date) => {
            let dateKey = convertLocaletoFullISOFormat(new Date(date));
            let existingSpreadsheetDateData = getObjectValue(spreadsheetDataObject, dateKey) ? spreadsheetDataObject[dateKey] : {}
            Object.assign(spreadsheetDataObject, { [dateKey]: { ...existingSpreadsheetDateData, [dataType]: data[date] } });
            return true;
        }));
    }));

    let spreadsheetData = await Promise.all(Object.keys(spreadsheetDataObject).map((date) => {
        return { "Date": date, ...spreadsheetDataObject[date] };
    }));

    // console.log(spreadsheetData);

    return [spreadsheetData, headers];
};