import { log } from "./Utils";
import { Buffer } from 'buffer';

const objectStoreName = 'images';
const dbLatestVersion = 1;
export const stringSeperator = '|';

const indexedDBSupport = () => {
    return 'indexedDB' in window;
};

const persistStorage = async () => {
    return navigator.storage && navigator.storage.persist && navigator.storage.persist();
};
  
const isStoragePersisted = async () => {
    return navigator.storage && navigator.storage.persisted && navigator.storage.persisted();
}

const buildImageDB = async (dbName) => {
    return await new Promise(async(resolve, reject) => {
        if (!indexedDBSupport()) reject("Your browser doesn't support IndexedBD");

        await persistStorage();

        let db;
        
        const openRequest = indexedDB.open(dbName, dbLatestVersion);
        
        openRequest.onupgradeneeded = (e) => {
            db = e.target.result;
            log('running onupgradeneeded');
            const objectStore = db.createObjectStore(objectStoreName,  { keyPath: "id" });

            // Indexes
            objectStore.createIndex("id", "id", { unique: true });
            objectStore.createIndex("url", "url", { unique: false });
            objectStore.createIndex("data", "data", { unique: false });
            objectStore.createIndex("created", "created", { unique: false });
            objectStore.createIndex("lastUpdated", "lastUpdated", { unique: false });    
            objectStore.createIndex("name", "name", { unique: false });    
            objectStore.createIndex("cloudId", "cloudId", { unique: true });    
            // Transaction completed
            objectStore.transaction.oncompleted = (e)=> {
                log(`Object store "${objectStoreName}" created`);
            }
        };
        openRequest.onsuccess = (e) => {
            db = e.target.result;
            resolve(db);
        };
        openRequest.onerror = (e) => {
            reject(`DB Failed to be opened or created ${e}`);
        };
    });
};

export const deleteImageDB = async (dbName) => {
    return await new Promise(async(resolve, reject) => {
        if (!indexedDBSupport()) reject("Your browser doesn't support IndexedBD");

        await persistStorage();

        let db;
        
        const openRequest = indexedDB.deleteDatabase(dbName);

        openRequest.onsuccess = () => {
            log(`DB ${dbName} deleted`);
            resolve(db);
        };
        openRequest.onerror = (e) => {
            reject(`DB Failed to be deleted: ${e}`);
        };
    });
};

const createImageItem = (imageObject) => {
    const imageItem = {
        id: imageObject.id,
        url: imageObject.url,
        data: imageObject.data,
        name: imageObject.name,
        created: new Date().getTime(),
        lastUpdated: new Date().getTime(),
        cloudId: imageObject.cloudId
    };

    return imageItem;
}

const updateOrAddImageItem = (store, imageObject) => {
    const imageItem = createImageItem(imageObject);

    // Create a request to update
    const request = store.put(imageItem);

    request.onsuccess = () => {
        log(`Image item updated: ${request.result}`, "", false, false);
    }

    request.onerror = (err)=> {
        log(`Error adding new Image item: ${err}`, "", false, true);
        store.add(imageItem);
    }
};

const removeImageItem = (store, id) => {
    let request = store.delete(id);

    request.onsuccess = ()=> {
        log(`Image Item deleted: ${id}`, "", false, false);
    }

    request.onerror = (err)=> {
        log(`Error deleting image item: ${err}`, "", false, true);
    }
};

const getAllImages = async (dbTransaction) => {
    return await new Promise((resolve, reject) => {
        const store = dbTransaction.objectStore(objectStoreName);

        const request = store.openCursor();

        let allImagesObject = {};

        request.onerror = (err) => {
            log(`Failed to fetch image data: ${err}`, "IndexedDB Storage Error", false, true);
        };
        request.onsuccess = (event) => {
            let cursor = event.target.result;
            if (cursor) {
                let key = cursor.primaryKey;
                let value = cursor.value;
                Object.assign(allImagesObject, { [key]: value });
                cursor.continue();
            }
            else {
                // no more results
                resolve(allImagesObject);
            }
        };
    });
};

const emptyImageStore = (dbTransaction) => {
    const store = dbTransaction.objectStore(objectStoreName);
    const request = store.clear();

    request.onsuccess = ()=> {
        log(`Object Store "${objectStoreName}" emptied`, "", false, false);
    }

    request.onerror = (err)=> {
        log(`Failed to empty Object Store ${objectStoreName}: ${err}`, "", false, true);
    }
};

const populateImageDB = async (dbTransaction, imageObjects) => {
    const store = dbTransaction.objectStore(objectStoreName);
    imageObjects.forEach((imageObject) => {
        updateOrAddImageItem(store, imageObject);
    });
};
  
export const getImageSession = async (dbName) => {
    let db = await buildImageDB(dbName);
    const dbTransaction = db.transaction(objectStoreName, "readonly");
    
    // Pull Image Session From IndexedDB Storage
    let imagesObjects = await getAllImages(dbTransaction);

    let images = await Promise.all(Object.keys(imagesObjects).map(async (id) => {
        let [fileName, fileSize, fileType] = id.split(stringSeperator);
        let imageObject = imagesObjects[id];
        return base64ToFile(imageObject.url, fileName, fileType);
    }));

    dbTransaction.oncomplete = () => {
        db.close();
    };
  
    // Pull Image Session From Other Locations
    /* Insert Other Methods Here */
  
    // Return Images array
    return [images, imagesObjects];
};

export const saveImageSession = async (dbName, imageObjects) => {
    let db = await buildImageDB(dbName);

    const dbTransaction = db.transaction(objectStoreName, "readwrite");
    populateImageDB(dbTransaction, imageObjects);

    dbTransaction.oncomplete = () => {
        db.close();
    };
};

export const removeImageObject = async (dbName, imageObjectId) => {
    let db = await buildImageDB(dbName);

    const dbTransaction = db.transaction(objectStoreName, "readwrite");

    const store = dbTransaction.objectStore(objectStoreName);
    removeImageItem(store, imageObjectId);

    dbTransaction.oncomplete = () => {
        db.close();
    };
};

export const buildImageDBObjects = async (images) => {
    let newImagesObject = {};
    await Promise.all(images.map(async(image) => {
        let id = deriveImageKey(image);
        let imgURL = URL.createObjectURL(image);
        let dimensions = await getHeightAndWidthFromDataUrl(imgURL);
        let url = await fileToBase64(image)
        Object.assign(newImagesObject, { [id]: { url, id, ...dimensions, "data": {}, "name": image.name }});
        return(true);
    }));

    return newImagesObject;
};

export const base64ToFile = async (base64String, fileName, fileType) => {
    let uint8array = Buffer.from(base64String, 'base64');
    let blob = new Blob([uint8array], { type: fileType });
    let newFile = new File([blob], fileName, { type: fileType });
    return newFile;
};

export const fileToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
    
        reader.onload = () => {
            const base64String = reader.result.split(',')[1]; // Extracting the base64 string from the Data URL
            resolve(base64String);
        };
    
        reader.onerror = error => {
            reject(error);
        };
    
        reader.readAsDataURL(file);
    });
};

export const getHeightAndWidthFromDataUrl = async (dataURL) => {
    return await new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
            resolve({
                height: img.height,
                width: img.width
            });
        }
        img.src = dataURL
    });
};

export const deriveImageKey = (image) => {
    let imageKey = `${image.name}${stringSeperator}${image.size}${stringSeperator}${image.type}`;
    return imageKey;
};