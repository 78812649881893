import React, { useState } from "react";
import { IoCloseCircleOutline, IoCloseCircleSharp } from "react-icons/io5";
import PropTypes from 'prop-types';

function RemovalButton ({ deleteObject, updateParent, style }) {
    const [isHovered, setHovered] = useState(false);

    return(
        <button onClick={() => updateParent(deleteObject, "delete")} className="hidden-btn" style={{ "display":"flex", "justifyContent":"center", "alignItems":"center", "marginLeft": "8px", "marginTop": "3px", ...style }} onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} >{!isHovered ?(<IoCloseCircleOutline style={{ "transform": "scale(1.5)" }}  />) : (<IoCloseCircleSharp style={{ "transform": "scale(1.5)" }} />)}</button>
    );
}

RemovalButton.propTypes = {
    deleteObject: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object
    ]).isRequired,
    updateParent: PropTypes.func.isRequired,
    style: PropTypes.object
};  

export default RemovalButton