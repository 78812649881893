import React from "react";
import PropTypes from 'prop-types';
import ProductList from "./ProductList";

function ProductContainer({ isMobile, defaultproductSize, products }) {
    return (
        <>  
            {/* Product grid */}
            <div style={isMobile ? { "display": "flex", "flexWrap": "wrap", "width": "100%", "justifyContent": "center" } : { "display": "flex", "flexWrap": "wrap", "width": "100%" }} className="w3-col l3 s6">
                <ProductList defaultproductSize={defaultproductSize} products={products} isMobile={isMobile} />
            </div>
        </>
    );
}

ProductContainer.propTypes = {
    isMobile: PropTypes.bool,
    defaultproductSize: PropTypes.string,
    products: PropTypes.array
};  

export default ProductContainer;