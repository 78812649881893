import React, { useState, useRef, useEffect } from "react";
import PropTypes from 'prop-types';

function Paypal({ cost, description, type, updateParent, postPayment }) {
  const [loaded, setLoaded] = useState(false);

  let paypalStyle = {
    shape: 'rect',
    color: 'black',
    layout: 'vertical',
  }
  if(type === "subsciption") {
    Object.assign(paypalStyle, { label: 'subscribe' });
  }

  let paypalRef = useRef();

  useEffect(() => {
    //Runs when component mounts
    try {
      //Load PayPal Script
      const script = document.createElement("script");
      var buttonObject = {};
      if(type === "authorize") {
        script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}&intent=authorize`;
        buttonObject = {
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  intent: 'AUTHORIZE',
                  description: description,
                  amount: {
                    currency_code: "USD",
                    value: Number(cost).toFixed(2),
                  },
                },
              ],
              application_context: { shipping_preference: 'NO_SHIPPING' }
            });
          },
        };
      } else if(type === "subscription") { 
        script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}&vault=true&intent=subscription`;
        buttonObject = {
          createSubscription: (data, actions) => {
            return actions.subscription.create({
              /* Creates the subscription */
              // plan_id: process.env.REACT_APP_PAYPAL_SUBSCRIPTION_PLAN_ID
            });
          },
        }
      } else {
        script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}`;
        buttonObject = {
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  description: description,
                  amount: {
                    currency_code: "USD",
                    value: Number(cost).toFixed(2),
                  },
                },
              ],
              application_context: { shipping_preference: 'NO_SHIPPING' }
            });
          },
        };
      }
      script.addEventListener("load", () => setLoaded(true));
      document.body.appendChild(script);
      if (loaded) {
        //Subscription Handler
        setTimeout(() => {
          window.paypal
            .Buttons(
            {
              style: paypalStyle,
              ...buttonObject,
              onApprove: async (data, actions) => {
                let order = '';
                if(type === "authorize") {
                  order = await actions.order.authorize();
                } else if(type === "subscription") {
                  order = data.subscriptionID;
                } else {
                  order = await actions.order.capture();
                }
                if(postPayment) {
                  await postPayment(true, order);
                }
                //console.log(order);
                updateParent({ paidFor: true, orderDetails: order });
              },
              onCancel: function (data) {
                // Show a cancel page, or return to cart
              },
            }
            ).render(paypalRef);
        });
      }
    } catch (error) {
      console.log(error);
    }
  });
  //End of useEffect

  //Beginning of render return
  return (<div ref={(v) => (paypalRef = v)} />);
}

Paypal.propTypes = {
  cost: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  description: PropTypes.string,
  type: PropTypes.string,
  updateParent: PropTypes.func.isRequired,
  postPayment: PropTypes.func,
};

export default Paypal;