import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { camelCaseToHumanReadable } from "../Utils/Utils";

function FormInput({ clearButton, comparator, disabled, dropDownOptions, errors, updateParent, arrayIndex, isMobile, max, min, mobileStyle, name, object, placeholder, reaction, resultType, step, style, type, value }) {
    const [selectOptions, setSelectOptions] = useState('');
    
    useEffect(() => {
        if(type === "dropdown" && name && dropDownOptions && dropDownOptions.length > 0) {
            let selectOptions = buildForm(type, dropDownOptions, name);
            setSelectOptions(selectOptions);
        }
    }, [type, name, dropDownOptions]);

    const buildForm = (type, values, name, extraValues) => {
        let formOptions = "";
        if(values.length > 0) {
            formOptions = values.map((item, tempIndex) => {
                if (type === "dropdown") {
                    let value = item;
                    let desc = item;
                    if(name === "color") {
                        value = JSON.stringify({ [name] : item, "displayColor": extraValues[tempIndex] });
                    } else if(name === "manufacturer" || name === "machine") {
                        value= item.id;
                        desc = item.name;
                    }
                    return (
                        <option value={value} name={name} key={tempIndex}>{desc}</option>
                    );
                }  else {
                    return null;
                }
            });
        }
        return formOptions;
    };

    const handleChange = (e) => {
        let index = typeof(arrayIndex) === 'number' ? arrayIndex : '';
        let type = resultType ? resultType : '';
        // console.log("Index: ", index);
        // console.log("Type: ", type);

        let updateObject = e;
        if(e.hasOwnProperty("target") && e.target.type === "checkbox" && e.target.value === "true") {
            updateObject = { [e.target.name]: true };
        } else if(e.hasOwnProperty("target") && e.target.type === "checkbox" && e.target.value === "false") {
            updateObject = { [e.target.name]: false };
        } else if(e.hasOwnProperty("target") && e.target.type === "radio") {
            let val = value && typeof value === "object" ? JSON.parse(e.target.value) : e.target.value;
            updateObject = { [e.target.name]: val };
        } else if(e.hasOwnProperty("target") && step === "0.0001") {
            updateObject = { [e.target.name]: parseFloat(e.target.value) };
        } else if(e.hasOwnProperty("target") && step) {
            updateObject = { [e.target.name]: parseInt(e.target.value) };
        } else if(e.hasOwnProperty("target")) {
            updateObject = { [e.target.name]: e.target.value};
        } else {
            updateObject = { [name]: e };
        }
        // console.log("Update Object: ", updateObject);

        if(updateParent) {
            updateParent(updateObject, type, index);
        }
    };

    if(name && typeof object !== 'undefined') {
        // Convert Camel Case strings to plain english capital strings, i.e. "partNumber" to "Part Number"
        let newPlaceholder = '';
        if(placeholder && typeof placeholder !== 'object') {
            newPlaceholder = camelCaseToHumanReadable(placeholder);
        }

        return (
            <div style={isMobile ? { "marginBottom": "24px", ...mobileStyle } : { ...style }}>
                <div style={{ "display": "flex" }}>
                    {type === "dropdown" && selectOptions && selectOptions.length > 0 ? (
                        <select
                            style={{ "paddingLeft": "8px", "width": "100%", "height": "28.5px", "border": "none", "boxShadow" : "1px 0 0 0 black inset, 0 1px 0 0 black, 1px 1px 0 0 black inset, 1px 0 0 0 black, 0 1px 0 0 black", ...reaction }}
                            className="form-dropdown"
                            name={name}
                            value={object[name]}
                            onChange={(e) => { handleChange(e); }}
                            disabled={disabled}
                        >
                            <option value="" disabled defaultValue hidden>{placeholder}</option>
                            {selectOptions}
                        </select>
                    ) :  type === "switch" ? (
                        <React.Fragment>
                            <input
                                name={name}
                                checked={object[name]}
                                value={!object[name]}
                                onChange={(e) => { handleChange(e); }}
                                className="react-switch-checkbox"
                                id={resultType && resultType.length > 0 ? `react-switch-new${resultType[1]}` : "react-switch-new"}
                                type="checkbox"
                            />
                            <label
                                style={object[name] ? { "background": '#06D6A0' } : { "background": 'grey' }}
                                className="react-switch-label"
                                htmlFor={resultType && resultType.length > 0 ? `react-switch-new${resultType[1]}` : "react-switch-new"}
                            >
                                <span className="react-switch-button" />
                            </label>

                        </React.Fragment>
                    ) : type === "checkbox" ? (
                        <div style={{ "display": "flex", "width": "100%", "justifyContent": "center", "marginBottom": "16px" }}>
                            <input
                                style={{ "marginRight":"6px" }}
                                type="checkbox"
                                name={name}
                                checked={typeof object === "object" ? object[name] : object}
                                value={typeof object === "object" ? !object[name] : !object}
                                onChange={(e) => { handleChange(e); }}
                            />
                            <label>
                                {placeholder}
                            </label>
                        </div>
                    ) : type === "radio" ? (
                        <div style={typeof placeholder === "object" ? { "marginBottom": "16px" } : { "display": "flex", "width": "100%", "justifyContent": "center", "marginBottom": "16px" }}>
                            <input
                                style={{ "marginRight":"6px" }}
                                type="radio"
                                name={name}
                                checked={typeof object === "object" && typeof value === "object" && comparator ? object[name][comparator] === value[comparator] : 
                                         typeof object === "object" ? object[name] === value :
                                         typeof value === "object" && comparator ? object === value[comparator] : 
                                         object === value}
                                value={typeof value === "object" ? JSON.stringify(value): value}
                                onChange={(e) => { handleChange(e); }}
                                id={typeof arrayIndex === 'number' ? `${name}-${arrayIndex}` : name}
                            />
                            <label>
                                {placeholder}
                            </label>
                        </div>
                    ) : (
                        <input
                            style={{ "width": "100%", "paddingLeft": "8px", "paddingTop": "2px", "border": "none", "boxShadow" : "1px 0 0 0 black inset, 0 1px 0 0 black, 1px 1px 0 0 black inset, 1px 0 0 0 black, 0 1px 0 0 black", ...reaction }}
                            name={name}
                            placeholder={newPlaceholder}
                            max={max ? max : ''}
                            min={min ? min : ''}
                            step={step ? step : ''}
                            value={object[name]}
                            onChange={(e) => { handleChange(e); }}
                            disabled={disabled}
                        />
                    )}
                    {clearButton ? (
                        <button
                            className="hidden-btn"
                            style={{ "color": "blue", "marginLeft": "8px" }}
                            onClick={() => {
                                handleChange('');
                            }}
                        >
                            clear
                        </button>
                    ) : null}
                </div> 
                {errors ? (
                    <div className="form-error">{errors[`${name}Error`]}</div>
                ) : null}
            </div>
        );
    }
}

FormInput.propTypes = {
    clearButton: PropTypes.bool,
    disabled: PropTypes.bool,
    errors: PropTypes.object,
    index: PropTypes.number,
    isMobile: PropTypes.bool,
    max: PropTypes.string,
    min: PropTypes.string,
    mobileStyle: PropTypes.object,
    name: PropTypes.string.isRequired,
    object: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.bool
    ]).isRequired,
    placeholder: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ]),
    reaction: PropTypes.object,
    resultType: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
    setObject: PropTypes.func,
    step: PropTypes.string,
    style: PropTypes.object,
    type: PropTypes.string.isRequired,
    updateParent: PropTypes.func,
};

export default FormInput;