import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { GrFormCheckmark } from "react-icons/gr";
import PropTypes from 'prop-types';
import { getPrivacyPreferences, updatePrivacyPreferences, validateInput } from "../Utils/Utils";

function CommPreferences({ isMobile }) {
    const [profile, setProfile] = useState({ email: "", marketing: false, important: false, none: false });
    const [profileError, setProfileError] = useState('');
    const [profileUpdateSuccess, setProfileUpdateSuccess] = useState('');
    const [dataFetched, setDataFetched] = useState(false);
    const { refEmail } = useParams();

    useEffect(() => {
        document.title = 'Communication Preferences';
    });

    const handleChange = async (e) => {
        let updateObject = '';
        if(e.hasOwnProperty('target')) {
            if(e.target.type === "checkbox") {
                if(e.target.name === "none" && e.target.checked) {
                    updateObject = { [e.target.name]: e.target.checked, "important": false, "marketing": false };
                } else {
                    updateObject = { [e.target.name]: e.target.checked, "none": false };
                } 
            } else {
                updateObject = { [e.target.name]: e.target.value };
            }
            setProfileFunc(updateObject);
        }
    };

    const setProfileFunc = async (updateObject) => {
        setProfile((prevProfile) => {
            let newProfile = { ...prevProfile, ...updateObject};
            if(!newProfile.important && !newProfile.marketing) {
                Object.assign(newProfile, { "none": true });
            }
            return(newProfile);
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let response = await updatePrivacyPreferences(profile);
        if(response) {
            setProfileUpdateSuccess(`Communication Preferences successfully updated.`);
        } else {
            setProfileError("Communication Preferences failed to update, please refresh the page and try again.");
        }
    };

    useEffect(() => {
        if(refEmail) {
            getPrivacyPreferencesObject(refEmail);

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refEmail]);

    const getPrivacyPreferencesObject = async (refEmail) => {
        setProfileError('');
        let email = refEmail ? refEmail : profile.email;
        let errorsArray = await validateInput([{ name: "email", displayName: "email", input: email, type: "text", specialConditions: [`includes(${email},@)`]}]);
        if(errorsArray) {
            if(errorsArray[0]) {
                let privacyPreferences = await getPrivacyPreferences(email);
                if(privacyPreferences) {
                    setProfileFunc(privacyPreferences);
                } else {
                    setProfileFunc({ none: false, important: true, marketing: true });
                }
                setDataFetched(true);
            } else {
                setProfileError(errorsArray[1][0].errorDisplay);
            }
        } else {
            setProfileError('A problem occurred please refresh the page and try again.');
        }
    };
 
    return (
        <div style={{ "minHeight":"92vh" }} className="w3-content w3-container w3-padding-64" id="about">
            <div style={{ "display": "flex", "justifyContent": "center" }}>
                <div style={{ "maxWidth": "650px" }}>
                    <h4><b>Communication Preferences</b></h4>
                    {refEmail ? (
                        null
                    ) : (
                        <React.Fragment>
                            <p>Please enter your email address.</p>
                            <div style={{ "display": "flex" }}>
                                <input
                                    className="w3-input w3-border"
                                    style={{ "width":"100%", "marginRight": "16px" }}
                                    placeholder="Email"
                                    name="email"
                                    value={profile.email}
                                    type="text"
                                    onChange={(e) => handleChange(e)}
                                    id="email"
                                />
                                <button className="sage-green hidden-btn" onClick={() => { getPrivacyPreferencesObject(); }} disabled={!profile.email} style={{ "width": "48px" }}>
                                    <GrFormCheckmark style={{ "transform": "scale(2)" }}/>
                                </button>
                            </div>
                        </React.Fragment>
                        
                    )}
                    <p>Please select the communications you would like to recieve from Vale Outdoors.</p>
                    <div className="comms-pref-container">
                        <form className="comms-pref-form">
                            <div>
                                <input
                                    className="form-container"
                                    type="checkbox"
                                    name="marketing"
                                    id="marketing"
                                    checked={profile.marketing}
                                    onChange={(e) => handleChange(e)}
                                    disabled={!dataFetched}
                                />
                                <label htmlFor="agree">
                                    {" "}
                                    Yes I want to receive marketing related emails!
                                </label>
                            </div>
                            <div style={{ "marginTop": "8px" }}>
                                <input
                                    className="form-container"
                                    type="checkbox"
                                    name="important"
                                    id="important"
                                    checked={profile.important}
                                    onChange={(e) => handleChange(e)}
                                    disabled={!dataFetched}
                                />
                                <label htmlFor="important">
                                    {" "}
                                    Yes I want to receive emails related to Vale Outdoors orders!
                                </label>
                            </div>
                            <div style={{ "marginTop": "8px" }}>
                                <input
                                    className="form-container"
                                    type="checkbox"
                                    name="none"
                                    id="none"
                                    checked={profile.none}
                                    onChange={(e) => handleChange(e)}
                                    disabled={!dataFetched}
                                />
                                <label htmlFor="none">
                                    {" "}
                                    I do not want to receive any notifications from Vale Outdoors Group.
                                </label>
                            </div>
                            <div style={{ "marginTop": "32px", "textAlign": "right" }}>
                                <button
                                    id="updateprefer-btn"
                                    className="w3-button w3-padding-large w3-border midnight-button"
                                    onClick={(e) => handleSubmit(e)}
                                    disabled={!dataFetched}
                                >
                                    <b>
                                        UPDATE PREFERENCES
                                    </b>
                                </button>
                            </div>
                            <div className="form-error" style={{ "marginTop": "32px", "textAlign": "right" }}>{profileError}</div>
                            <div className="form-error" style={{ "marginTop": "32px", "textAlign": "right", "color":"green" }}>{profileUpdateSuccess}</div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

CommPreferences.propTypes = {
    isMobile: PropTypes.bool,
};

export default CommPreferences;
