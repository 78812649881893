import { createDeepClone } from "../Utils/Utils";

const START_POSITION = [0, 0, 0]
const ROTATION_TYPES = ["RT_WHD", "RT_HWD", "RT_HDW", "RT_DHW", "RT_DWH", "RT_WDH"];
const AXES = {
    "WIDTH": 0,
    "HEIGHT": 1,
    "DEPTH": 2
};

const rectangleIntersect = (item1, item2, x, y) => {
    let d1 = getDimension(item1);
    let d2 = getDimension(item2);

    let cx1 = item1.position[x] + d1[x] / 2;
    let cy1 = item1.position[y] + d1[y] / 2;
    let cx2 = item2.position[x] + d2[x] / 2;
    let cy2 = item2.position[y] + d2[y] / 2;

    let ix = Math.max(cx1, cx2) - Math.min(cx1, cx2);
    let iy = Math.max(cy1, cy2) - Math.min(cy1, cy2);

    return ix < (d1[x] + d2[x]) / 2 && iy < (d1[y] + d2[y]) / 2;
};

const intersect = (item1, item2) => {
    let intersectWidthHeight = rectangleIntersect(item1, item2, AXES.WIDTH, AXES.HEIGHT);
    let intersectHeightDepth = rectangleIntersect(item1, item2, AXES.HEIGHT, AXES.DEPTH);
    let intersectWidthDepth = rectangleIntersect(item1, item2, AXES.WIDTH, AXES.DEPTH);
    return(intersectWidthHeight && intersectHeightDepth && intersectWidthDepth);
};

export const printItem = (item) => {
    //If item
    if(item.hasOwnProperty("position")) {
        console.log(`${item.name} ${item.xdist} x ${item.ydist} x ${item.zdist} ${item.weight} g ${item.position} ${item.rotationType} ${getVolume(item)}in3`);
    }
    // If box
    else {
        console.log(`${item.name} ${item.xdist} x ${item.ydist} x ${item.zdist} ${item.totalWeight} g / ${item.maxWeight} g ${getVolume(item)}in3`);
    }
};

const getVolume = (item) => {
    return(item.xdist * item.ydist * item.zdist);
};

const getDimension = (item) => {
    let dimension = [];
    let rotationType = item.rotationType;
    if(rotationType === "RT_WHD") {
        // Width, Height, Depth
        dimension = [item.xdist, item.ydist, item.zdist];
    } else if(rotationType === "RT_HWD") {
        // Height, Width, Depth
        dimension = [item.ydist, item.xdist, item.zdist];
    } else if(rotationType === "RT_HDW") {
        // Height, Depth, Width
        dimension = [item.ydist, item.zdist, item.xdist];
    } else if(rotationType === "RT_DHW") {
        // Depth, Height, Width
        dimension = [item.zdist, item.ydist, item.xdist];
    } else  if(rotationType === "RT_DWH") {
        // Depth, Width, Height
        dimension = [item.zdist, item.xdist, item.ydist];
    } else  if(rotationType === "RT_WDH") {
        // Width, Depth, Height
        dimension = [item.xdist, item.zdist, item.ydist];
    }

    return dimension;
};

const putItem = (box, item, pivot) => {
    let fit = false;
    let validItemPosition = item.position;
    Object.assign(item, { "position": pivot });

    for(let i = 0; i < ROTATION_TYPES.length; i++) {
        Object.assign(item, { rotationType: ROTATION_TYPES[i] });
        let dimension = getDimension(item);
        if(box.xdist < pivot[0] + dimension[0] || box.ydist < pivot[1] + dimension[1] || box.zdist < pivot[2] + dimension[2]) {
            continue;
        }
        fit = true;

        for(let j = 0; j < box.items.length; j++) {
            let currentItem = box.items[j];
            if(intersect(currentItem, item)) {
                fit = false;
                break;
            }
        }

        if(fit) {
            if(box.totalWeight + item.weight > box.maxWeight) {
                fit = false;
                return fit;
            }
            Object.assign(box, { "totalWeight": box.totalWeight + item.weight });
            box.items.push(item);
        }

        if(!fit) {
            Object.assign(item, { "position": validItemPosition });
        }
        return fit;
    }

    if(!fit) {
        Object.assign(item, { "position": validItemPosition });
    }
    return fit;
};

const packToBox = (box, item) => {
    let unfittedItems = [];
    let fitted = false;
    if(box.items && box.items.length === 0) {
        let ableToBePutIn = putItem(box, item, START_POSITION);
        if(!ableToBePutIn) {
            unfittedItems.push(item);
        }
        return unfittedItems;
    }

    for(let i = 0; i < Object.keys(AXES).length; i++) {
        let axis = i;
        let ItemsinBox = box.items;

        for(let j = 0; j < ItemsinBox.length; j++) {
            let itemInBox = ItemsinBox[j];
            let pivot = [0, 0, 0];
            let dimension = getDimension(itemInBox);
            let width = dimension[0];
            let height = dimension[1];
            let depth = dimension[2];
            if(axis === AXES.WIDTH) {
                pivot = [itemInBox.position[0] + width, itemInBox.position[1], itemInBox.position[2]];
            } else if (axis === AXES.HEIGHT) {
                pivot = [itemInBox.position[0], itemInBox.position[1] + height, itemInBox.position[2]];
            } else if(axis === AXES.DEPTH) {
                pivot = [itemInBox.position[0], itemInBox.position[1], itemInBox.position[2] + depth];
            }
            let wasAbleToFit = putItem(box, item, pivot);
            if(wasAbleToFit) {
                fitted = true;
                break;
            }
        }

        if(fitted) {
            break;
        }
    }

    if(!fitted) {
        unfittedItems.push(item);
    }

    return unfittedItems;
};

const reformatItems = (items) => {
    let newItems = items.map((item) => {
        console.log(item);
        let shippingInfo = { ...item };
        delete shippingInfo.objectInfo;
        
        return({ ...item.objectInfo, shippingInfo });
    });
    return newItems;
};

const packBox = (inputBoxes, inputItems) => {
    let boxes = createDeepClone(inputBoxes);
    let additionalItems = [];

    // Populate item array with reformatted items fit for packing algorithm
    let items = inputItems.map((item) => {
        let objectInfo = { ...item };
        delete objectInfo.shippingInfo;
        // Spread products into individual quantities so that each part has individual unique position
        if(objectInfo.hasOwnProperty("quantity") && objectInfo.quantity && objectInfo.quantity > 1) {
            let quant = Array.from(Array(objectInfo.quantity - 1));
            quant.forEach(() => {
                additionalItems.push({ ...item.shippingInfo, objectInfo, "position": START_POSITION, "rotationType": 0 })
            });
        }
        return({ ...item.shippingInfo, objectInfo, "position": START_POSITION, "rotationType": 0, "quantity": 1 });
    });

    // Combine all items of quantitiy 1
    items = [...items, ...additionalItems];

    // Sort by volume in descending order
    items.sort((a, b) => getVolume(b) - getVolume(a));

    let totalUnfittedItems = [];
    let results = boxes.map((box) => {
        Object.assign(box, { totalWeight: 0 });
        Object.assign(box, { items: [] });

        let unfittedItems = [];
        let response = items.map((item) => {
            return(packToBox(box, item));
        });

        response.forEach((unfittedItem) => {
            unfittedItems = [...totalUnfittedItems, ...unfittedItem];
        });
        return unfittedItems;
    });
    results.forEach((unfittedItems) => {
        totalUnfittedItems = [...totalUnfittedItems, ...unfittedItems];
    });

    totalUnfittedItems = reformatItems(totalUnfittedItems);

    return [boxes, totalUnfittedItems];
};

const checkPackageFit = (boxOptions, remainingBoxOptions, unfittedItems, packedBoxes, timesFailedToPlacePart) => {
    let newlyPackedBoxes = [];
    let currentTimesFailedToPlacePart = timesFailedToPlacePart;
    // if there are remaining box permutations to check, check them
    if(remainingBoxOptions.length > 0) {
      // Check fit for selectedBox
      let boxTest = packBox([remainingBoxOptions[0]], unfittedItems);
      if(boxTest && boxTest.length > 0 && boxTest[1] && boxTest[1].length > 0) {
        // Remove current box from remainingBoxOptions
        let newBoxOptions = createDeepClone(remainingBoxOptions);
        newBoxOptions.shift();
  
        // Increment failure condition
        currentTimesFailedToPlacePart = currentTimesFailedToPlacePart + 1;
  
        // If the current box does not work check the next size box
        newlyPackedBoxes = checkPackageFit(boxOptions, newBoxOptions, boxTest[1], packedBoxes, currentTimesFailedToPlacePart);
      } else {
        // console.log(`Adding ${remainingBoxOptions[0].name} Box: `, boxTest[0]);
        newlyPackedBoxes = [...packedBoxes, ...boxTest[0]];
      }
    } else {
      // Opposite Base failure condition if parts cannot be fitted
      if(timesFailedToPlacePart <= boxOptions.length) {
        currentTimesFailedToPlacePart = 0;
        let newBoxOptions = createDeepClone(boxOptions);
  
        // Restart the process basically adding a new box to the array
        newlyPackedBoxes = checkPackageFit(boxOptions, newBoxOptions, unfittedItems, packedBoxes, currentTimesFailedToPlacePart);
      }
    }
    return newlyPackedBoxes;
};

const formatProductsForPacking = (inputItems) => {
    let additionalItems = [];

    // Populate item array with reformatted items fit for packing algorithm
    let items = inputItems.map((item) => {
        let objectInfo = { ...item };
        delete objectInfo.shippingInfo;
        // Spread products into individual quantities so that each part has individual unique position
        if(objectInfo.hasOwnProperty("quantity") && objectInfo.quantity && objectInfo.quantity > 1) {
            let quant = Array.from(Array(objectInfo.quantity - 1));
            quant.forEach(() => {
                additionalItems.push({ ...item.shippingInfo, objectInfo, "position": START_POSITION, "rotationType": 0 })
            });
        }
        return({ ...item.shippingInfo, objectInfo, "position": START_POSITION, "rotationType": 0, "quantity": 1 });
    });

    // Combine all unit items into one array
    items = [...items, ...additionalItems];

    // Sort by volume in descending order
    items.sort((a, b) => getVolume(b) - getVolume(a));

    return items
};

const volumeBasedPackageFit = (boxOptions, prevUnfittedItems, unfittedItems, packedBoxes) => {
    // If function fails to pack items two times in a row quit to avoid infinite loop
    if(unfittedItems.length === prevUnfittedItems.length) {
        return;
    }

    // Created because checkPackageFit modifies box objects
    let potentialBoxOptions = createDeepClone(boxOptions);


    for(let i = 0; i < potentialBoxOptions.length; i++) {
        let box = potentialBoxOptions[i];
        Object.assign(box, { "totalWeight": 0 });
        Object.assign(box, { "items": [] });
        let boxVolume = getVolume(box);

        let totalVolume = 0;
        let stillUnfittedItems = [];
        for(let j = 0; j < unfittedItems.length; j++) {
            let item = unfittedItems[j];
            totalVolume = totalVolume + getVolume(item);

            if(boxVolume > totalVolume) {
                Object.assign(box, { "items": [...box.items, item]});
                Object.assign(box, { "totalWeight": box.totalWeight + item.weight });
            } else {
                stillUnfittedItems.push(item);
            }
        }
        if(boxVolume > totalVolume && stillUnfittedItems.length === 0) {
            packedBoxes.push(box);
            break;
        }

        // If the biggest box still has remaining unfitted parts, add current box to packed boxes and change call this function again with new boxes
        if(stillUnfittedItems.length > 0 && i === potentialBoxOptions.length - 1) {
            packedBoxes.push(box);
            volumeBasedPackageFit(boxOptions, unfittedItems, stillUnfittedItems, packedBoxes)
        }
    }
};

export const packShippingBoxes = (boxOptions, products) => {
    let packedBoxes = [];

    let unfittedItems = formatProductsForPacking(products);
    let prevUnfittedItems = [];
    volumeBasedPackageFit(boxOptions, prevUnfittedItems, unfittedItems, packedBoxes);

    // packedBoxes = checkPackageFit(boxOptions, boxOptions, products, packedBoxes, 0);

    return packedBoxes;
};