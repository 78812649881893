import React from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

function Product({ isMobile, defaultproductSize, product }) {
    return (
        <div className="w3-container" style={isMobile ? { "width": "200px" } : defaultproductSize ? { "width": defaultproductSize } : { "width": "400px" }}>
            <div className="w3-display-container">
                <Link to={`/equipment/${product.slug}`}>
                    {product.image ? (
                        <img src={Array.isArray(product.image) ? product.image[0] : product.image} alt={product.name} style={{ "width": "100%" }} />
                    ) : (
                        <div style={{ "width": "197px", "height": "158px" }} />
                    )}
                </Link>
                {product.new ? (
                    <span className="w3-tag w3-display-topleft">New</span>
                ) : null}
                {product.showcase ? null : (
                    <div className="w3-display-middle w3-display-hover">
                        <Link to={`/equipment/${product.slug}`}><button className="w3-button w3-black">Buy now <i className="fa fa-shopping-cart"></i></button></Link>
                    </div>
                )}
            </div>
            <Link id={`${product.name}-store-product-link`} style={{  "color": "inherit", "textDecoration": "inherit" }} to={`/equipment/${product.slug}`}><p>{product.name}<br /><b>${product.price}</b></p></Link>
        </div>
    );
}

Product.propTypes = {
    isMobile: PropTypes.bool,
    defaultproductSize: PropTypes.string,
    product: PropTypes.object
};

export default Product;