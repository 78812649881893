import { isDate, makeNetworkRequest, getObjectValue, createDeepClone, normalizeData, prepopulateData, log } from './Utils';
import Zlib from "react-zlib-js";
import { Buffer } from 'buffer';
import { getMoonIllumination } from "suncalc";

const getMeteomaticsHourlyData = async (startDate, endDate, freeTier, zip, lat, lon) => {
    const timeStep = 'PT1H';
    let weatherData = await getMeteomaticsData(timeStep, startDate, endDate, freeTier, zip, lat, lon);
    return weatherData;
};
  
const getMeteomaticsData = async (timeStep, minDate, maxDate, freeTier, zip, lat, lon, accessToken) => {
    const forecastTime = (maxDate.getTime() - minDate.getTime()) / (1000 * 3600 * 24); //Days
    let startDate = null;
    if(freeTier && forecastTime > 10) {
        startDate = new Date(maxDate.getTime() - (10 * 24 * 3600 * 1000));
        startDate = startDate.toISOString();
    } else {
        startDate = minDate;
    }
    let endDate = maxDate;
    
    let weatherForecast = null;
    let queryParams = null;
    if(process.env.REACT_APP_NODE_ENV === "development") {
        let localWeatherData = '';
        try {
            localWeatherData = require('../images/weather_data.txt');
        } catch (error) {
            console.log("Development Weather Data could not be found.");
        }
        if(localWeatherData) {
            weatherForecast = JSON.parse(await (await fetch(localWeatherData)).text());
            queryParams = [
                { "parameter": "wind_speed_10m:ms", "name": "Wind Speed (m/s)", "color": "#6dca89" }, { "parameter": "wind_dir_10m:d", "name": "Wind Direction at 10m (Degrees)", "color": "#096324" }, 
                { "parameter": "t_2m:C", "name": "Temperature (C)", "color": "#df2b2b" }, { "parameter": "msl_pressure:hPa", "name": "Pressure (hPa)", "color": "#701379" }, 
                { "parameter": "precip_1h:mm", "name": "Precipitation 1 Hr Intervals (mm)", "color": "#3e669c" }, { "parameter": "weather_symbol_1h:idx", "name": "Weather State", "color": "#dda500" },
                { "parameter": "sunrise:sql", "name": "Sunrise Times", "color": "#ef84f7" }, { "parameter": "sunset:sql", "name": "Sunset Times", "color": "#d65000" }, 
                //{ "parameter": "moon_age:d", "name": "Moon Age", "color": "#818181" }
            ];
        }
    } else {
        let response = await triggerMeteomaticsAPI(timeStep, startDate, endDate, zip, lat, lon, accessToken);
        weatherForecast = response[0];
        queryParams = response[1];
    }
    // console.log(weatherForecast);
    
    return weatherForecast;
};

const buildMeteomaticsGraph = async (weatherForecast, queryParams) => {
    let graphsToBuild = [
        { 
            "Temperature": { 
                "chartType": "radar", 
                "datasets": ["wind_speed_10m:ms", "wind_dir_10m:d"], 
                "conversionSchema": { 
                    "wind_speed_10m:ms": { 
                        "label": "Wind Speed (mph)", 
                        "conversion": "m/s to mph" 
                    }
                }
            }
        }
    ];

    if(weatherForecast) {
        let timeSeriesData = {};
        let overallDataset = []; 
        let dataConversionSchema = { "wind_speed_10m:ms": { "label": "Wind Speed (mph)", "conversion": "m/s to mph" }}
        let normalizedGraphData = await Promise.all(weatherForecast.map(async (graph) => {
            let rawData = graph.coordinates[0].dates;
            let correspondingParameter = queryParams[queryParams.findIndex((item) => item.parameter === graph.parameter)];
            let label = correspondingParameter.name;
            let correspondingDataConversionSchema = null;
            if(dataConversionSchema.hasOwnProperty(graph.parameter)) {
                correspondingDataConversionSchema = dataConversionSchema[graph.parameter]
                label = correspondingDataConversionSchema.label
            }
            let normalizedData = await normalizeData(rawData, correspondingDataConversionSchema);
            let dataSetItem = { label, "data": normalizedData, "backgroundColor": correspondingParameter.color, "borderColor": correspondingParameter.color }
            overallDataset.push(dataSetItem);
    
            let timeSeriesCoordinateKey = `${graph.coordinates[0].lat} ${graph.coordinates[0].lon}`;
            // If time series data has not been defined for this lat lon, assign it and pre-populate sighting data with unit values
            if(!getObjectValue(timeSeriesData, timeSeriesCoordinateKey)) {
                Object.assign(timeSeriesData, { [timeSeriesCoordinateKey]: {} });
        
                let deerUnitDataToGenerate = [{ "name": "Deer Sighting", "unitValue": 0 }, { "name": "Deer Quantity", "unitValue": 0 }, { "name": "Doe Quantity", "unitValue": 0 }, { "name": "Buck Quantity", "unitValue": 0 }];
                deerUnitDataToGenerate.map(async (dataToGenerate) => {
                    let unitData = await prepopulateData(rawData, dataToGenerate.unitValue);
                    Object.assign(timeSeriesData[timeSeriesCoordinateKey], { [dataToGenerate.name]: { "data": unitData } });
                });
            }
            let timeSeriesCoordinateObject = timeSeriesData[timeSeriesCoordinateKey];
    
            let dataName = label;
            if(getObjectValue(dataConversionSchema, graph.parameter) && getObjectValue(dataConversionSchema[graph.parameter], "normalize")) {
                dataName = `Normalized ${label.split(" (")[0]}`;
                let newDataConversionSchema = createDeepClone(correspondingDataConversionSchema);
                Object.assign(newDataConversionSchema, { "normalize": false });
                Object.assign(timeSeriesCoordinateObject, { [dataName]: { "parameter": graph.parameter, "data": await normalizeData(rawData, newDataConversionSchema) } });
            }
            Object.assign(timeSeriesCoordinateObject, { [label]: { "parameter": graph.parameter, "data": normalizedData } });
    
            return { "parameter": graph.parameter, "name": label, "datasets": [dataSetItem] };
        }));
        // console.log(normalizedGraphData);
    
        weatherForecast = { "individualGraphs" : normalizedGraphData, "overallGraph": { "datasets": overallDataset }, "originalData": weatherForecast, timeSeriesData };
    }
};

export const convertMeteomaticsDataToJSON = async (weatherData) => {
    let weatherTimeSeriesData = {};
    await Promise.all(weatherData.map((weatherDataObject) => {
        // console.log(weatherDataObject);
        let data = weatherDataObject.coordinates[0].dates;
        let parameter = weatherDataObject.parameter;
        Object.assign(weatherTimeSeriesData, { [parameter]: { "data": {} } });
        data.map((dataPoint) => {
            let date = dataPoint.date;
            let value = dataPoint.value;
            Object.assign(weatherTimeSeriesData[parameter].data, { [date]: value });
            return true;
        });
        return true;
    }));
    console.log(weatherTimeSeriesData);

    return weatherTimeSeriesData;
};
  
const getNOAAData = async (startDate, endDate, zip) => {
    let formattedStartDate = startDate;
    if(isDate(startDate)) {
        formattedStartDate = startDate.toISOString().split("T")[0];
    }
  
    let formattedEndDate = endDate;
    if(isDate(endDate)) {
        formattedEndDate = endDate.toISOString().split("T")[0];
    }
  
    let response = await triggerNOAAAPI(zip, formattedStartDate, formattedEndDate);
    console.log(response);
};

const getOikolabHourlyData = async (startDate, endDate, zip, lat, lon) => {
    const timeStep = 'H';
    let weatherData = await getOikolabData(timeStep, startDate, endDate, zip, lat, lon);
    return weatherData;
};

const getOikolabData = async (timeStep, minDate, maxDate, zip, lat, lon) => {
    let startDate = minDate;
    let endDate = maxDate;
    
    let weatherForecast = null;
    let queryParams = null;
    if(process.env.REACT_APP_NODE_ENV === "development") {
        let localWeatherData = '';
        try {
            localWeatherData = require('../images/raw_weather_data.txt');
        } catch (error) {
            console.log("Development Weather Data could not be found.");
        }
        if(localWeatherData) {
            weatherForecast = JSON.parse(await (await fetch(localWeatherData)).text());
            queryParams = [
                { "parameter": "wind_speed", "name": "Wind Speed (m/s)", "color": "#6dca89" }, 
                { "parameter": "temperature", "name": "Temperature (C)", "color": "#df2b2b" }, 
                { "parameter": "mean_sea_level_pressure", "name": "Pressure (Pa)", "color": "#701379" }, 
                { "parameter": "total_precipitation", "name": "Precipitation 1 Hr Intervals (mm)", "color": "#3e669c" }
            ];
        }
    } else {
        let response = await triggerOikolabAPI(timeStep, startDate, endDate, zip, lat, lon);
        weatherForecast = response[0];
        queryParams = response[1];
    }
    
    return weatherForecast;
};

const getMeteostatHourlyData = async (startDate, endDate, zip, lat, lon, station) => {
    const timeStep = 'hourly';
    let [weatherData, queryParams, newStation] = await getMeteostatData(timeStep, startDate, endDate, zip, lat, lon, station);
    return [weatherData, queryParams, newStation];
};

const getMeteostatData = async (timeStep, minDate, maxDate, zip, lat, lon, station) => {
    let startDate = minDate;
    let endDate = maxDate;
    
    let [weatherForecast, queryParams, newStation] = await triggerMeteostatAPI(timeStep, startDate, endDate, zip, lat, lon, station);
    
    return [weatherForecast, queryParams, newStation];
};

export const convertOikolabDataToJSON = async (weatherData) => {
    let weatherTimeSeriesData = {};
    let skippedIndices = [0, 1, 2, 3];
    await Promise.all(weatherData.columns.map(async (parameter, cIndex) => {
        let skip = false;
        await Promise.all(skippedIndices.map((skipIndex) => {
            if(cIndex === skipIndex) {
                skip = true;
            }
            return true;
        }));

        if(!skip) {
            Object.assign(weatherTimeSeriesData, { [parameter]: { "data": {} } });
        }
        return true;
    }));

    await Promise.all(weatherData.data.map(async (weatherDataObject, index) => {
        // console.log(weatherDataObject);
        let date = (new Date(weatherData.index[index] * 1000)).toISOString();
        await Promise.all(weatherDataObject.map(async (dataPoint, dIndex) => {
            let skip = false;
            await Promise.all(skippedIndices.map((skipIndex) => {
                if(dIndex === skipIndex) {
                    skip = true;
                }
                return true;
            }));

            if(!skip) {
                let parameter = weatherData.columns[dIndex];
                let value = dataPoint;
                Object.assign(weatherTimeSeriesData[parameter].data, { [date]: value });
            }
            return true;
        }));
        return true;
    }));

    return weatherTimeSeriesData;
};

export const convertMeteostatDataToJSON = async (weatherData, queryParams, startDate, endDate) => {
    // It is bulk data from a csv file
    let timeSeriesData = {};
    if(typeof weatherData === "string") {
        timeSeriesData = await parseMeteostatCSV(weatherData, queryParams, startDate, endDate);
    } else {
        await Promise.all(queryParams.map((param) => {
            Object.assign(timeSeriesData, { [param.name]: { "data": {} }});
            return true;
        }));
        let arrayData = weatherData.data;
        // console.log(arrayData);

        await Promise.all(arrayData.map(async (dataPoint) => {
            let dataPointDate = new Date(dataPoint.time);
            if(dataPointDate >= startDate && dataPointDate <= endDate) {
                await Promise.all(queryParams.map((param) => {
                    let paramDataObject = timeSeriesData[param.name].data;
                    Object.assign(paramDataObject, { [dataPointDate.toISOString()]: dataPoint[param.parameter] });
                    return true;
                }));
            }
            return true;
        }));

    }
    return timeSeriesData;
};
  
export const getRawWeatherData = async (service, startDate, endDate, freeTier, zip, lat, lon) => {
    //Get NOAA, Meteomatics, or Oikolab data depending on instructions
    let weatherData = false;
    switch (service) {
        case "meteomatics":
            weatherData = await getMeteomaticsHourlyData(startDate, endDate, freeTier, zip, lat, lon);
            break;
        case "noaa":
            weatherData = await getNOAAData(startDate, endDate, zip);
            break;
        case "oikolab":
            weatherData = await getOikolabHourlyData(startDate, endDate, zip, lat, lon);
            break;
        default:
            throw new Error("Please select a weather service to get data from.");
    }
  
    return weatherData;
};

export const getTimeSeriesWeatherData = async (service, startDate, endDate, freeTier, zip, lat, lon, station) => {
    //Get NOAA or Meteomatics depending on instructions
    let weatherData = false;
    let weatherTimeSeriesData = false;
    let queryParams = null;
    let newStation = null;
    switch (service) {
        case "meteomatics":
            weatherData = await getMeteomaticsHourlyData(startDate, endDate, freeTier, zip, lat, lon);
            weatherTimeSeriesData = await convertMeteomaticsDataToJSON(weatherData);
            break;
        case "noaa":
            weatherData = await getNOAAData(startDate, endDate, zip);
            throw new Error("No time series data conversion has been implemented for NOAA weather data");
            break;
        case "oikolab":
            log(`${5 * (((new Date(endDate)).getMonth() - (new Date(startDate)).getMonth()) + 1)}`, "Oikolab Units to be Used:");
            weatherData = await getOikolabHourlyData(startDate, endDate, zip, lat, lon);
            weatherTimeSeriesData = await convertOikolabDataToJSON(weatherData);
            break;
        case "meteostat":
            [weatherData, queryParams, newStation] = await getMeteostatHourlyData(startDate, endDate, zip, lat, lon, station);
            weatherTimeSeriesData = await convertMeteostatDataToJSON(weatherData, queryParams, startDate, endDate);
            break;
        default:
            throw new Error("Please select a weather service to get data from.");
    }
  
    return [weatherTimeSeriesData, newStation];
};

const triggerMeteomaticsAPI = async (timeStep, startDate, endDate, zip, lat, lon, accessToken) => {
    let postData = { timeStep, startDate, endDate };
    if(lat && lon) {
        Object.assign(postData, { "latitude": lat, "longitude": lon });
    } else if(zip) {
        Object.assign(postData, { zip });
    }
    if(accessToken) {
        Object.assign(postData, { accessToken });
    }
    let response = await makeNetworkRequest("post", "meteomatics", { ...postData },  "meteomatics");
    // console.log(response);
  
    if(response && response.data.data) {
        return [response.data.data, response.data.queryParams];
    } else {
        return false;
    }
};
  
const triggerNOAAAPI = async (zip, startDate, endDate) => {
    let postData = { zip, startDate, endDate };
    let response = await makeNetworkRequest("post", "noaa", { ...postData },  "noaa");
    // console.log(response);
  
    if(response && response.data) {
        return response.data;
    } else {
        return false;
    }
};

const triggerOikolabAPI = async (timeStep, startDate, endDate, zip, lat, lon) => {
    let postData = { timeStep, startDate, endDate };
    if(lat && lon) {
        Object.assign(postData, { "latitude": lat, "longitude": lon });
    } else if(zip) {
        Object.assign(postData, { zip });
    }
    let response = await makeNetworkRequest("post", "oikolab", { ...postData },  "oikolab");
    // console.log(response);
  
    if(response && response.data.data) {
        return [JSON.parse(response.data.data), response.data.queryParams];
    } else {
        return false;
    }
};

export const Utf8ArrayToStr = async (array) => {
    return await new Promise((resolve) => {
        var out, i, len, c;
        var char2, char3;

        out = "";
        len = array.length;
        i = 0;
        while(i < len) {
            c = array[i++];
            switch(c >> 4) { 
                case 0: case 1: case 2: case 3: case 4: case 5: case 6: case 7:
                    // 0xxxxxxx
                    out += String.fromCharCode(c);
                    break;
                case 12: case 13:
                    // 110x xxxx   10xx xxxx
                    char2 = array[i++];
                    out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
                    break;
                case 14:
                    // 1110 xxxx  10xx xxxx  10xx xxxx
                    char2 = array[i++];
                    char3 = array[i++];
                    out += String.fromCharCode(((c & 0x0F) << 12) |
                                ((char2 & 0x3F) << 6) |
                                ((char3 & 0x3F) << 0));
                    break;
                default:
                    break;
            }
        }

        resolve(out);
    });
};

export const unzipFile = async (buffergz) => {
    return await new Promise((resolve, reject) => {
        Zlib.gunzip(buffergz, (err, unzipped) => {
            if(err) {
                reject(err);
            } else {
                resolve(unzipped);
            }
        });
    });
};

export const createBase64URI = (base64Data, contentType) => {
    return `data:${contentType};base64,${base64Data}`;
};

export const downloadBase64File = (contentType, base64Data, fileName) => {
    const linkSource = createBase64URI(base64Data, contentType);
    downloadFile(linkSource, fileName);
};

export const downloadFile = (linkSource, fileName) => {
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
};

export const downloadArrayBufferFile = (arrayBuffer, type, fileName) => {
    let compresedCSV = new Blob([arrayBuffer], { type });
    const url = window.URL.createObjectURL(compresedCSV);
    downloadFile(url, fileName);
};

export const toArrayBuffer = async (buffer) => {
    return await new Promise((resolve) => {
        const arrayBuffer = new ArrayBuffer(buffer.length);
        const view = new Uint8Array(arrayBuffer);
        for (let i = 0; i < buffer.length; ++i) {
            view[i] = buffer[i];
        }
        resolve(arrayBuffer);
    });
};

export const downloadCompressedCSVFile = async (compressedCSVFileBuffer, fileName) => {
    let arrayBuffer = await toArrayBuffer(compressedCSVFileBuffer);
    downloadArrayBufferFile(arrayBuffer, "application/x-gzip", `${fileName}.csv.gz`);
};

export const downloadCSVFile = async (csvFileBuffer, fileName) => {
    let arrayBuffer = await toArrayBuffer(csvFileBuffer);
    downloadArrayBufferFile(arrayBuffer, "text/csv", `${fileName}.csv`);
};

export const parseMeteostatCSV = async (text, queryParams, startDate, endDate) => {
    let csvDataObject = {};
    await Promise.all(queryParams.map((param) => {
        Object.assign(csvDataObject, { [param.name]: { "data": {} }});
        return true;
    }));
    try {
        const csvRows = text.slice(text.indexOf("\n") + 1).split("\n");
        // console.log(csvRows);

        await Promise.all(csvRows.map(async(row) => {
            // console.log(row);
            if(row) {
                let columnsOfData = row.split(",");
                let rowDate = new Date(columnsOfData[0]);
                if(rowDate >= startDate && rowDate <= endDate) {
                    rowDate.setHours(parseInt(columnsOfData[1]));
                    await Promise.all(queryParams.map((param) => {
                        let paramDataObject = csvDataObject[param.name].data;
                        Object.assign(paramDataObject, { [rowDate.toISOString()]: columnsOfData[param.bulkColumn] });
                        return true;
                    }));
                }
            }
            return true;
        }));
    } catch (error) {
        console.log(error);
        return false;
    }
    // console.log(csvDataObject);

    return csvDataObject;
};

export const getLunarData = async (weatherTimeSeriesData) => {
    let newWeatherTimeSeriesDataKey = "Illuminted Fraction of the Moon"
    let lunarFractionData = { [newWeatherTimeSeriesDataKey]: { "data": {} } };

    let dateArray = Object.keys(weatherTimeSeriesData[Object.keys(weatherTimeSeriesData)[0]].data);
    await Promise.all(dateArray.map((date) => {
        let moonDate = new Date(date);
        let illuminationObject = getMoonIllumination(moonDate);
        Object.assign(lunarFractionData[newWeatherTimeSeriesDataKey].data, { [date]: illuminationObject.fraction });
        return true;
    }));

    return lunarFractionData;
};

const triggerMeteostatAPI = async (timeStep, startDate, endDate, zip, lat, lon, station) => {
    let postData = { "frequency": timeStep, startDate, endDate };
    if(lat && lon) {
        Object.assign(postData, { "latitude": lat, "longitude": lon });
    } else if(zip) {
        Object.assign(postData, { zip });
    }

    if(station) {
        Object.assign(postData, { station });
    }
    // console.log(postData);

    let response = '';
    let queryParams = null;
    if(process.env.REACT_APP_NODE_ENV === "development") {
        let localResponse = '';
        try {
            let numberOfDays = Math.round(((new Date(endDate)).getTime() - (new Date(startDate)).getTime()) / (1000 * 3600 * 24));
            if(numberOfDays > 31) {
                localResponse = require('../images/meteostat_bulk_response.txt');
            } else {
                localResponse = require('../images/meteostat_response.txt');
            }
        } catch (error) {
            console.log("Development Weather Data could not be found.");
        }
        if(localResponse) {
            response = JSON.parse(await (await fetch(localResponse)).text());
            queryParams = [
                { "parameter": "wspd", "name": "Wind Speed (km/h)", "color": "#6dca89", "bulkColumn": 8 }, 
                { "parameter": "temp", "name": "Temperature (C)", "color": "#df2b2b", "bulkColumn": 2 }, 
                { "parameter": "pres", "name": "Pressure (hPa)", "color": "#701379", "bulkColumn": 10 }, 
                { "parameter": "prcp", "name": "Precipitation 1 Hr Intervals (mm)", "color": "#3e669c", "bulkColumn": 5 },
                { "parameter": "wdir", "name": "Wind Direction (deg)", "bulkColumn": 7 }
            ];
        }
    } else {
        response = await makeNetworkRequest("post", "meteostat", { ...postData },  "meteostat");
        if(response && response.data && response.data.queryParams) {
            queryParams = response.data.queryParams;
        }
    }
    // console.log(response);

    let newStation = '';
    if(response && getObjectValue(response, "data") && getObjectValue(response.data, "data") && getObjectValue(response.data, "bulk") && getObjectValue(response.data, "queryParams")) {
        let responseBuffer = Buffer.from(response.data.data.data);
        let csvFile = await unzipFile(responseBuffer);
        let csvFileContents = await Utf8ArrayToStr(csvFile);
        if(getObjectValue(response.data, "station")) {
            newStation = response.data.station;
        }
        
        return [csvFileContents, queryParams, newStation];
    } else if(response && getObjectValue(response, "data") && getObjectValue(response.data, "data") && getObjectValue(response.data, "queryParams")) {
        return [response.data.data, response.data.queryParams, newStation];
    } else {
        return false;
    }
};