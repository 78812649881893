import './App.css';
import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Authentication from './pages/Authentication';
import Store from './pages/Store';
import SingleProduct from './pages/SingleProduct';
import Cart from './pages/Cart';
import usePageTracking from "./components/usePageTracking";
import Error from "./pages/Error";
import { getAllProducts, modifyOrder, getCartSession, getStoreFrontProducts, saveCartSession, removeCartSession, updateProfile as updateAuth0Profile, getObjectValue } from './Utils/Utils';
import CommPreferences from './pages/CommPreferences';
import Auth from "./Auth";
import AccountSettings from './pages/AccountSettings';
import DeerVision from './pages/DeerVision';
import AddDistributor from './pages/AddDistributor';
import RouteToDocument from './pages/RouteToDocument';
import Locations from './pages/Locations';
import User from "./User";
import DeerVisionLanding from './pages/DeerVisionLanding';

const auth = new Auth();

const authorizedUsers = [process.env.REACT_APP_ADMIN_ID_T, process.env.REACT_APP_ADMIN_ID_J];

function App() {
    const [currentProfile, setCurrentProfile] = useState(null);
    const [products, setProducts] = useState(null);
    const [order, setOrder] = useState({
        selectRate: false,
        checkout: false,
        shipping: false,
        paidFor: false,
        emailSent: false,
        rate: '',
        shippingInfo: '',
        shippingInfoHasChanged: false,
        products: ''
    });
    const [authenticated, setAuthenticated] = useState(auth.isAuthenticated());
    const [isAdmin, setIsAdmin] = useState(false);

    let location = useLocation();
    
    usePageTracking();
  
    useEffect(() => {
        document.title = 'Vale-Outdoors';
    });

    // Start of Hook for mobile styling
    const isMobileThreshold = 768; // value in px
    const [isMobile, setIsMobile] = useState(window.innerWidth <= isMobileThreshold);
  
    function handleWindowSizeChange() {
        setIsMobile(window.innerWidth <= isMobileThreshold);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    // End of Hook for mobile styling

    useEffect(() => {
        if(process.env.REACT_APP_NODE_ENV === "development") {
            getAllProducts().then((products) => {
                if(products) {
                    setProducts(products);
                }
            });
        } else {
            getStoreFrontProducts().then((products) => {
                if(products) {
                    setProducts(products);
                }
            });
        }
    }, []);

    useEffect(() => {
        getCartSession(modifyOrderWrapper).then((orderObject) => {
            if(orderObject) {
                modifyOrderWrapper(orderObject);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const modifyOrderWrapper = (updateObject) => {
        modifyOrder(updateObject, order, setOrder);
    };
  
    useEffect(() => {
        if(order && order.rate) {
            let rateDate = new Date(order.rate.object_created);
            if(((new Date()).getTime() - rateDate.getTime()) > (24 * 3600 * 1000)) {
                modifyOrderWrapper({ "selectRate": false, "checkout": false, "shipping": false, "rate": '' });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order]);

    useEffect(() => {
        if(order && order.hasOwnProperty("products") && order.products && order.products.length > 0 && !order.checkout) {
            saveCartSession(order);
        } else if (order && order.hasOwnProperty("products") && order.products && order.products.length === 0) {
            removeCartSession(order);
        }
    }, [order]);

    useEffect(() => {
        if(authenticated && auth.getProfile()) {
            let profile = auth.getProfile();
            Object.assign(profile, { "accessToken": auth.accessToken });
            // console.log(profile);

            createProfile(profile);
        } else {
            auth.renewTokens().then((response) => {
                if(response) {
                    setAuthenticated(auth.isAuthenticated());
                }
            });
        }
    }, [authenticated]);

    useEffect(() => {
        if(currentProfile) {
            authorizedUsers.forEach((user) => {
                if(user && currentProfile.sub === user) {
                    setIsAdmin(true);
                    if(process.env.REACT_APP_NODE_ENV !== "development") {
                        getAllProducts().then((products) => {
                            if(products) {
                                setProducts(products);
                            }
                        });
                    }
                }
            });
        }
    }, [currentProfile]);

    const createProfile = (profile) => {
        let newCurrentProfile = new User(profile);
        // console.log(newCurrentProfile);

        setCurrentProfile(newCurrentProfile);
    };

    const updateProfile = async (updatedProfile) => {
        let newlyUpdatedProfile = await updateAuth0Profile(updatedProfile, currentProfile);
        if(getObjectValue(updatedProfile, "accessToken")) {
            Object.assign(newlyUpdatedProfile, { "accessToken": updatedProfile.accessToken });
        }
        // console.log(newlyUpdatedProfile);

        createProfile(newlyUpdatedProfile);
    };

    return (
        <>
            <Navbar currentProfile={currentProfile} isAdmin={isAdmin} isMobile={isMobile} />
            <Routes>
                <Route exact path="/" element={<Home isMobile={isMobile} products={products} />} />
                {['/login/', '/login/:referrer', '/register/', '/callback/', '/logout/'].map((path, index) => <Route key={index} exact path={path} element={<Authentication auth={auth} location={location} />} />)}
                {['/equipment', '/equipment/filters/:filters'].map((path, index) => <Route key={index + 76} exact path={path} element={<Store isMobile={isMobile} propProducts={products} />} />)}
                <Route exact path="/cart" element={<Cart isMobile={isMobile} modifyOrder={modifyOrderWrapper} order={order} products={products} />} />
                {['/equipment/:slug', '/equipment/:slug/:instructions', '/equipment/filters/:filters/:slug', '/equipment/filters/:filters/:slug/:instructions'].map((path, index) => <Route key={index + 101} exact path={path} element={<SingleProduct isMobile={isMobile} modifyOrder={modifyOrderWrapper} order={order} products={products} />} />)}
                <Route exact path="/account/:sub" element={<AccountSettings currentProfile={currentProfile} isMobile={isMobile} setCurrentProfile={updateProfile} />} />
                {['/deer_vision/:sub', '/deer_vision/:sub/locations/:location'].map((path, index) => <Route key={index + 76} exact path={path} element={<DeerVision currentProfile={currentProfile} isMobile={isMobile} setCurrentProfile={updateProfile} />} />)}
                {['/deer_vision/:sub/data', '/deer_vision/:sub/locations/:location/data'].map((path, index) => <Route key={index + 76} exact path={path} element={<DeerVision currentProfile={currentProfile} isMobile={isMobile} setCurrentProfile={updateProfile} dataView={true} />} />)}
                <Route exact path="/deer_vision" element={<DeerVisionLanding isMobile={isMobile} />} />
                <Route exact path="/deer_vision/:sub/locations" element={<Locations currentProfile={currentProfile} isMobile={isMobile} setCurrentProfile={updateProfile} />} />
                {['/unsubscribe/:refEmail', '/unsubscribe'].map((path, index) => <Route key={index + 85} exact path={path} element={<CommPreferences isMobile={isMobile} />} />)}
                <Route exact path="/add_distributor" element={<AddDistributor authenticated={authenticated} currentProfile={currentProfile} isAdmin={isAdmin} isMobile={isMobile} products={products} />} />
                <Route exact path="/edit_distributor" element={<AddDistributor authenticated={authenticated} currentProfile={currentProfile} edit={true} isAdmin={isAdmin} isMobile={isMobile} products={products} />} />
                <Route exact path="/documents/:document" element={<RouteToDocument isMobile={isMobile} />} />
                <Route path="*" element={<Error isMobile={isMobile} />} />
            </Routes>
            <Footer />
        </>
    );
}

export default App;  