import { CgTrash } from "react-icons/cg";
import PropTypes from 'prop-types';

function Quantity({ index, quantity, updateParent, remove, style }) {
    const handleChange = (e) => {
        if(typeof index === "number") {
            updateParent(e, index);
        } else {
            updateParent(e);
        }
    };

    return(
        <div style={{ ...style, "display": "flex", "alignItems": "center"}}>
            <div style={{ "display": "flex", "alignItems": "center"}} className="def-number-input number-input">
                {quantity > 1 || !remove ? (
                    <button style={{ "height": "40px", "fontSize": "1.5rem", "color": "#495057" }} id={`quantity-minus-button-${index}`} onClick={(e) => { e.preventDefault(); handleChange({ "quantity" : quantity - 1 }); }}>-</button>
                ) : (
                    <button style={{ "height": "40px", "color": "#495057" }} id={`quantity-trash-button-${index}`} onClick={() => updateParent("remove", index)}><CgTrash style={{ "marginTop":"8px", "transform": "scale(1.5)" }} /></button>
                )}
                <input
                    style={{ "height": "40px" }}
                    className="quantity"
                    name="quantity"
                    value={quantity}
                    onChange={(e) => updateParent(e)}
                    type="number"
                    id={`quantity-input-${index}`}
                />
                <button style={{ "height": "40px", "fontSize": "1.25rem", "color": "#495057" }} id={`quantity-plus-button-${index}`} onClick={(e) => { e.preventDefault(); handleChange({ "quantity" : quantity + 1 }); }} >+</button>
            </div>
            {remove ? (
            <button style={{ "marginLeft": "32px", "height": "40px", "paddingLeft":"16px", "paddingRight": "16px" }} id={`delete-button-${index}`} className="def-number-input number-input w3-button" onClick={() => updateParent("remove", index)}>
                Delete
            </button>) : null}
        </div>
    );
}

Quantity.propTypes = {
    index: PropTypes.number,
    quantity: PropTypes.number,
    style: PropTypes.object,
    updateParent: PropTypes.func,
    remove: PropTypes.bool
};  

export default Quantity;