import React, { useEffect, useState } from "react";
import Quantity from "../components/Quantity";
import { Link } from "react-router-dom";
import InfoForm from "../components/InfoForm";
import PropTypes from 'prop-types';
import { adjustShippingPrice, assignDistributorInfoProperties, checkForManufacturerWorkflow, combineItemsInCart, getDistributorInfo, removeCartSession, triggerSendMessageFunction, triggerShippingFunction, validateProducts } from "../Utils/Utils";
import { v4 as uuidv4 } from 'uuid';
import { IoChevronBackOutline } from "react-icons/io5";
import PaymentOptions from "../components/PaymentOptions";
// import ShippingRates from "../components/ShippingRates";

function Cart({ isMobile, modifyOrder, order, products }) {
    const [subtotal, setSubtotal] = useState('');
    const [total, setTotal] = useState('');
    // const [sortedShippingRates, setSortedShippingRates] = useState('');
    const [shippingError, setShippingError] = useState('');
    const [consolidated, setConsolidated] = useState(false);
    const [dataFetched, setDataFetched] = useState(false);
    const [orderError, setOrderError] = useState('');
    const [packedBoxes, setPackedBoxes] = useState('');
    const [orderId, setOrderId] = useState('');
    const [distributor, setDistributor] = useState(false);
    const [storeCode, setStoreCode] = useState('');
    const [buttonText, setButtonText] = useState('Shipping');
    // const pageLimit = 6;

    useEffect(() => {
        document.title = 'Cart';
    });

    const handleChange = async (e, index) => {
        // console.log(e);
        let productsClone = [...order.products];
        if(e.hasOwnProperty("target")) {
            e.preventDefault();
            let updateObject = { ...order.products[index] };
            Object.assign(updateObject, { [e.target.name]: e.target.value });
            if(e.target.name === "type") {
                let typeObject = order.products[index].types[order.products[index].types.findIndex((type) => type.type === e.target.value)];
                if(typeObject.hasOwnProperty("price") && typeObject.price) {
                    Object.assign(updateObject, { "price": typeObject.price });
                }
            }
            productsClone[index] = updateObject;
        } else if(e === "remove") {
            productsClone.splice(index, index + 1);
        } else {
            productsClone[index] = {
                ...order.products[index],
                ...e
            };
        }
        setDataFetched(false);
        modifyOrder({ "products": productsClone });
    };

    useEffect(() => {
        checkForOutOfStockItems();

        if(order.hasOwnProperty("storeCode") && order.storeCode) {
            setStoreCode(order.storeCode);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order]);

    const checkForOutOfStockItems = async () => {
        //Check for out of stock items in cart
        if(order && order.hasOwnProperty("shipping") && order.shipping) {
            let outOfStockQuantity = 0;
            await Promise.all(order.products.map((product) => {
                if(!product.stock) {
                    outOfStockQuantity++;
                }
                return true;
            }));
            if(outOfStockQuantity > 1) {
                setOrderError("Please remove out of stock item before proceeding.");
                return;
            } else if (outOfStockQuantity === 1) {
                setOrderError("Please remove out of stock items before proceeding.");
                return;
            }
            setOrderError('');
        }
        return true;
    };

    // Consolidate cart
    useEffect(() => {
        if(order && order.products && order.products.length > 0 && !consolidated) {
            // NECESSARY FOR REACT STRICT MODE USEEFFECT RACE CONDITION
            let ignore = false;
            
            const consolidateCart = async () => {
                let newCart = await combineItemsInCart(order.products);
                if (!ignore) {
                    // console.log("Cart Before Consolidation: ", order.products);
                    // console.log("Cart After Consolidation: ", newCart);
                    setConsolidated(true);
                    modifyOrder({ "products": newCart });
                }
            }
        
            consolidateCart();
        
            return () => {
                ignore = true;
            };
            // NECESSARY FOR REACT STRICT MODE USEEFFECT RACE CONDITION
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order]);

    // Get shipping rates on change of variable order
    useEffect(() => {
        if(order && order.products && order.products.length > 0 && order.shippingInfo && order.shippingInfoHasChanged && order.selectRate && dataFetched && consolidated) {
            Object.assign(order.shippingInfo, { products: order.products });
            if(!order.shippingInfo.hasOwnProperty("country")) {
                Object.assign(order.shippingInfo, { "country": "US" });
            }
            let manufacturerAddress = checkForManufacturerWorkflow(order.products);

            triggerShippingFunction("estimate", order.shippingInfo, manufacturerAddress).then((response) => {
                // console.log(response);

                let updateObject = { "shippingInfoHasChanged": false };
                
                if(response && response.length > 0) {
                    let uspsShippingRates = response[1].rates.filter((rate) => rate.provider === "USPS");
                    // console.log(uspsShippingRates);

                    // let sortedShippingRates = uspsShippingRates.sort((a, b) => a.amount - b.amount);
                    // setSortedShippingRates(sortedShippingRates);

                    let shippingRate = null;
                    uspsShippingRates.forEach((uspsRate) => {
                        if(uspsRate.hasOwnProperty("attributes") && uspsRate.attributes) {
                            let filteredAttributes = uspsRate.attributes.filter((attrib) => attrib === "CHEAPEST");
                            if(filteredAttributes.length > 0) {
                                shippingRate = uspsRate;
                            }
                        }
                    });

                    if(shippingRate) {
                        Object.assign(updateObject, { "selectRate": false, "checkout": true, "rate": shippingRate, "preorderOnly": false  });
                    } else {
                        setShippingError("There was a problem with calculating the shipping please refresh the page and try again.");
                    }
                    
                    if(response.length > 2) {
                        setPackedBoxes(response[2]);
                    }
                } else if (response) {
                    Object.assign(updateObject, { "selectRate": false, "checkout": true, "preorderOnly": true });
                }
                modifyOrder(updateObject);
            }).catch((error) => {
                console.log(error);
            });
        } else if (order && order.products && order.products.length > 0 && order.shippingInfo && !order.shippingInfoHasChanged && order.selectRate && dataFetched && consolidated) {
            modifyOrder({ "selectRate": false, "checkout": true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order, dataFetched]);

    // useEffect(() => {
    //     if(order.hasOwnProperty("shippingInfoHasChanged") && order.shippingInfoHasChanged === true) {
    //         setSortedShippingRates('');
    //     }
    // }, [order]);

    // Color Input Generator
    useEffect(() => {
        if(order && order.products && order.products.length > 0 && consolidated && !dataFetched) {
            dropDownPopulator().then((newProducts) => {
                modifyOrder({ "products": newProducts });
                setDataFetched(true);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order]);

    const dropDownPopulator = async () => {
        let newProducts = [...order.products];
        let colorInputs = await Promise.all(order.products.map((product, index) => {
            if(product.colors && product.colors.length > 0) {
                return(generateDropDown("color", product["color"], product.colors, index, "301"));
            } else {
                return false;
            }
        }));
        await Promise.all(colorInputs.map((colorInput, index) => {
            if(colorInput) {
                Object.assign(newProducts[index], { colorInput });
            }
            return true;
        }));
        let caliberInputs = await Promise.all(order.products.map((product, index) => {
            if(product.calibers && product.calibers.length > 0) {
                return(generateDropDown("caliber", product["caliber"], product.calibers, index, "301"));
            } else {
                return false;
            }
        }));
        await Promise.all(caliberInputs.map((caliberInput, index) => {
            if(caliberInput) {
                Object.assign(newProducts[index], { caliberInput });
            }
            return true;
        }));
        let typeInputs = await Promise.all(order.products.map((product, index) => {
            if(product.types && product.types.length > 0) {
                return(generateDropDown("type", product["type"], product.types, index, "301", product));
            } else {
                return false;
            }
        }));
        await Promise.all(typeInputs.map((typeInput, index) => {
            if(typeInput) {
                Object.assign(newProducts[index], { typeInput });
            }
            return true;
        }));
        return newProducts;
    };

    const generateDropDown = (key, keyValue, optionsValues, parentIndex, keyUniqueVal, product) => {
        let options = optionsValues.map((item, index) => {
            let value = item;
            let name = item;
            let caption = item;
            if(key === "type") {
                let currencyType = '$';
                value = item.type;
                name = key;
                caption = item.hasOwnProperty("price") && item.price !== product.price ? `${value} (${currencyType}${(item.price - product.price).toFixed(2)})` : value;
            }
            return(<option value={value} name={name} key={index + keyUniqueVal}>{caption}</option>);
        }); 
        return(
            <div style={isMobile ? { "marginTop": "24px" } : { "marginLeft": "32px" }}>
                {options && options.length > 0 ? (
                    <select
                        className={`color-input`}
                        name={key}
                        value={keyValue}
                        onChange={(e) => handleChange(e, parentIndex)}
                    >
                        <option value="" disabled defaultValue hidden>Choose a {key}</option>
                        {options}
                    </select>
                ) : (
                    <select>
                        <option value="" disabled defaultValue hidden>Choose a {key}</option>
                    </select>
                )}
            </div>
        );
    };

    const generateLabel = async () => {
        let response = await triggerShippingFunction("label", order.rate);
        // Should spit out { "labels": ["https://blah"], "trackingNumbers": ["blah blah"] }
        if(response) {
            return response;
        } else {
            return false;
        }
    };

    const postPayment = async (paidFor, orderDetails) => {
        if(paidFor && orderDetails) {
            //Generate unique order identifier
            let orderId = uuidv4();
            setOrderId(orderId);

            let emailData = { ...order, orderDetails, "id": orderId, subtotal, total };

            if(packedBoxes) {
                //Generate Shipping Label
                let labelObject = await generateLabel();

                Object.assign(emailData, { ...labelObject, packedBoxes, "shipping": (adjustShippingPrice(order.rate.amount)).toFixed(2) });
            }


            let manufacturer = checkForManufacturerWorkflow(order.products);
            if(manufacturer) {
                Object.assign(emailData, { manufacturer });
            }

            //Send Automated Email of Shipping Label to Nick and Confirmation Email of Purchase to Customer (with Tracking Number?)
            triggerSendMessageFunction("order", emailData).catch((error) => {
                console.log(error);
            });
            
            modifyOrder({ 'products': '' });

            removeCartSession(order);
        }
    };

    // Subtotal Calculation
    useEffect(() => {
        if(order && order.products && order.products.length > 0 && dataFetched) {
            let subtotal = 0;
            order.products.forEach((product) => {
                subtotal += parseFloat(product.price) * product.quantity;
            });
            setSubtotal(subtotal.toFixed(2));
        }
    }, [order, dataFetched]);

    // Total Calculation
    useEffect(() => {
        if(order && order.checkout) {
            let total = parseFloat(subtotal);
            if(order.taxes) {
                total = total + parseFloat(order.taxes);
            }
            setTotal(total.toFixed(2));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order, packedBoxes, subtotal]);

    const startCheckout = async () => {
        let distributorInfo = '';
        if(distributor && storeCode) {
            setOrderError('');
            distributorInfo = await getDistributorInfo(storeCode);
            if(distributorInfo) {
                let updatedProducts = await assignDistributorInfoProperties(order.products, distributorInfo);
                modifyOrder({ storeCode, "products": updatedProducts });

                //Validate that min quantity is met
                let validated = true;
                updatedProducts.forEach((product) => {
                    if(product && product.hasOwnProperty("minQuantity") && product.minQuantity && product.quantity < product.minQuantity) {
                        setOrderError(`Minimum order quantity of ${product.minQuantity} for ${product.name} not met!`);
                        validated = false;
                    }
                });
                if(!validated) {
                    return(false);
                }
            } else {
                setOrderError('There was a problem with your cart!');
                return(false);
            }
        }

        let validatedProducts = await validateProducts(order.products, products, distributorInfo);
        // console.log(validatedProducts);

        if(validatedProducts) {
            modifyOrder({ "shipping": true });
        } else {
            setOrderError('There was a problem with your cart!');

            setTimeout(() => {
                //Remove invalid order from localStorage and memory
                localStorage.removeItem("order");
                modifyOrder({ 'products': '' });
                setOrderError('');
            }, 1000);

        }
    };

    useEffect(() => {
        if(distributor) {
            setButtonText("Confirm Code");
        } else {
            setButtonText("Shipping");
        }
    }, [distributor]);

    if(order && order.paidFor && orderId) {
        return(
            <div style={{ "minHeight": "91.9vh" }} className="w3-content w3-container w3-padding-64" id="about">
                <div style={{ "display": "flex", "justifyContent": "center" }}>
                    <div>
                        {/* Title */}
                        <div id="order-title" className="title">
                            <h3>Order Placed</h3>
                        </div>
                        <div style={isMobile ? {} : { "minWidth": "650px" }}>
                            <p>
                                Thank you for your purchase! If you have any questions about your 
                                order please contact us at sales@sojourn-group.net with subject line Order #{orderId}
                            </p>
                        </div>
                    </div>
                </div>
            </div>    
        );
    } else if(order && order.checkout) {
        return(
            <div style={{ "minHeight": "91.9vh" }} className="w3-content w3-container w3-padding-64" id="about">
                <div style={{ "display": "flex", "justifyContent": "center" }}>
                    <div>
                        {/* Title */}
                        <div id="payment-title" className="title">
                            <h3>
                                <span>              
                                    <button style={{"background": "transparent", "border": "none", "cursor": "pointer", "outline": "none"}} onClick={() => modifyOrder({ "checkout": false, "shipping": true })}>
                                        <IoChevronBackOutline style={{"transform":"scale(1.5)", "paddingTop":"4px", "marginRight":"8px"}}/>
                                    </button>
                                </span>
                                Payment
                            </h3>
                        </div>
                        <div style={isMobile ? {} : { "width": "650px" }}>
                            {order.products.map((product, index) => {
                                return(
                                    <div key={index + 20} style={isMobile ? {} : { "minWidth": "650px" }}>
                                        <div>
                                            <div style={{ "marginTop": "16px", "marginLeft": "32px" }} className="item">
                                                <div style={{ "display": "flex", "paddingTop": "16px" }}>
                                                    <div className="image">
                                                        <img style={{ "maxWidth": "140px"}} src={Array.isArray(product.image) ? product.image[0] : product.image} alt={product.name} />
                                                    </div>
                                                    <div style={{ "marginLeft": "32px"}}>
                                                        <div className="description">
                                                            <p style={{ "marginTop": "0px", "marginBottom": "2px" }}>{product.name}</p>
                                                        </div>
                                                        <div style={{ "display": "flex" }} className="total-price">
                                                            <b style={{ "paddingTop": "6px" }}>$</b>
                                                            <h3 style={{ "marginTop": "0px"}}><b>{product.price}</b></h3>
                                                        </div>
                                                        <div style={{ "display": "flex" }} className="description">
                                                            <p style={{ "marginTop": "0px", "marginBottom": "2px" }}>Quantity: {product.quantity}</p>
                                                            <p style={{ "marginTop": "0px", "marginBottom": "2px", "marginLeft": "24px" }}>Color: {product.color}</p>
                                                        </div>
                                                    </div>
                                                    <div style={{ "display": "flex", "width": "30%", "justifyContent": "right", "alignItems": "center" }} className="total-price">
                                                            <b style={{ "paddingTop": "0px" }}>$</b>
                                                            <h3 style={{ "marginTop": "10px"}}><b>{(product.price * product.quantity).toFixed(2)}</b></h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr style={{ "marginTop":"48px", "height": "1px", "backgroundColor": "black", "border": "none" }}/>
                                    </div>
                                );
                            })}
                            <div style={{ "display": "flex", "justifyContent":"right" }}>
                                <h4 style={{ "margin": 0 }}>Subtotal:</h4>
                                <h4 style={{ "margin": 0, "marginLeft": "32px", "width": "90px", "textAlign": "right" }}>${subtotal}</h4>
                            </div>
                            <div style={{ "display": "flex", "justifyContent": "right"}}>
                                <h4 style={{ "margin": 0 }}>Shipping:</h4>
                                <h4 style={{ "margin": 0, "marginLeft": "32px", "width": "90px", "textAlign": "right" }}>Free</h4>
                            </div>
                            {order.taxes ? (
                                <div style={{ "display": "flex", "justifyContent": "right"}}>
                                    <h4 style={{ "margin": 0 }}>Taxes:</h4>
                                    <h4 style={{ "margin": 0, "marginLeft": "32px", "width": "90px", "textAlign": "right" }}>${order.taxes}</h4>
                                </div>
                            ) : null}
                            <div style={{ "display": "flex", "justifyContent": "right"}}>
                                <h3 style={{ "margin": 0 }}>Total:</h3>
                                <h3 style={{ "margin": 0, "marginLeft": "32px", "width": "90px", "textAlign": "right" }}>${total}</h3>
                            </div>
                            <div style={{ "width": "100%", "display": "flex", "justifyContent": "center", "marginTop": "32px" }}>
                                <div style={{ "width": "300px"}}>
                                    {total ? (
                                        <PaymentOptions cost={total} updateParent={modifyOrder} postPayment={postPayment} />
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
        );
    } else if (order && order.selectRate) {
        return(
            <div style={{ "minHeight": "91.9vh" }} className="w3-content w3-container w3-padding-64" id="about">
                <div style={{ "display": "flex", "justifyContent": "center" }}>
                    <div>
                        {/* Title */}
                        <div id="shipping-method-title" className="title">
                            <h3>
                                <span>              
                                    <button style={{"background": "transparent", "border": "none", "cursor": "pointer", "outline": "none"}} onClick={() => { modifyOrder({ "selectRate": false, "shipping": true }) }}>
                                        <IoChevronBackOutline style={{"transform":"scale(1.5)", "paddingTop":"4px", "marginRight":"8px"}}/>
                                    </button>
                                </span>
                                Calculating Shipping
                            </h3>
                        </div>
                        <div style={isMobile ? {} : { "width": "650px" }}>
                            {/* {sortedShippingRates && sortedShippingRates.length > 0 ? (
                                <div style={isMobile ? {} : { "width": "75%", "paddingLeft": "64px" }}>
                                    <ShippingRates isMobile={isMobile} packedBoxes={packedBoxes} pageLimit={pageLimit} object={{ "rate": order.rate }} pageNeighbours={1} rates={sortedShippingRates} updateParent={(e) => { modifyOrder(e)}} />
                                    <button id="select-rate-btn" style={{ "marginTop": "24px", "float": "right" }} onClick={() => { modifyOrder({ "selectRate": false, "checkout": true }); }} disabled={!order.rate} className="w3-button w3-padding-large w3-border"><b>Select Rate and Pay</b></button>
                                </div>
                            ) : (
                                <div style={isMobile ? {} : { "width": "50%", "paddingLeft": "64px" }}>
                                    <p>Estimating Shipping Costs...</p>
                                </div>
                            )} */}
                            <div style={isMobile ? {} : { "width": "50%", "paddingLeft": "64px" }}>
                                <p>Estimating Shipping Costs...</p>
                            </div>
                            <div style={{ "width": "100%" }} className="form-error">{shippingError}</div>
                        </div>
                    </div>
                </div>
            </div>        
        );
    } else if (order && order.shipping) {
        return (
            <div style={{ "minHeight": "91.9vh" }} className="w3-content w3-container w3-padding-64" id="about">
                <div style={{ "display": "flex", "justifyContent": "center" }}>
                    <div>
                        {/* Title */}
                        <div id="shipping-info-title" className="title">
                            <h3>
                                <span>              
                                    <button style={{"background": "transparent", "border": "none", "cursor": "pointer", "outline": "none"}} onClick={() => modifyOrder({ "shipping": false })}>
                                        <IoChevronBackOutline style={{"transform":"scale(1.5)", "paddingTop":"4px", "marginRight":"8px"}}/>
                                    </button>
                                </span>
                                Shipping Info
                            </h3>
                        </div>
                        <div style={isMobile ? {} : { "minWidth": "650px" }}>
                            <InfoForm buttonText={"Submit and Select Rate"} isMobile={isMobile} formType="Shipping" propObject={order.shippingInfo} updateParent={modifyOrder} />
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (order) {
        return (
            <div style={{ "minHeight": "91.9vh" }} className="w3-content w3-container w3-padding-64" id="about">
                <div style={{ "display": "flex", "justifyContent": "center" }}>
                    <div>
                        {/* Title */}
                        <div  className="title">
                            <h3 style={{ "marginLeft": "36px" }}>Cart</h3>
                        </div>
                        {dataFetched && order && order.products && order.products.length > 0 && order.products[0].hasOwnProperty("colorInput") && order.products[0].colorInput && order.products[0].colorInput.hasOwnProperty("$$typeof") ? (
                            <>
                                {order.products.map((product, index) => {
                                    return(
                                        <div key={index + 20} style={isMobile ? {} : { "minWidth": "650px" }}>
                                            <div>
                                                <div style={{ "marginTop": "16px", "marginLeft": "32px" }} className="item">
                                                    <div style={{ "display": "flex", "paddingTop": "16px" }}>
                                                        <div className="image">
                                                            <img style={{ "maxWidth": "140px"}} src={Array.isArray(product.image) ? product.image[0] : product.image} alt={product.name} />
                                                        </div>
                                                        <div style={{ "marginLeft": "32px"}}>
                                                            <div className="description">
                                                                <p style={{ "marginTop": "0px", "marginBottom": "2px" }} id={`${product.name}`}>{product.name}</p>
                                                            </div>
                                                            <div style={{ "display": "flex" }} className="total-price">
                                                                <b style={{ "paddingTop": "6px" }}>$</b>
                                                                <h3 style={{ "marginTop": "0px"}}><b>{product.price}</b></h3>
                                                            </div>
                                                            {product.preorder ? (
                                                                <React.Fragment>
                                                                    <p style={{ "marginTop": "0px" }}>Preorder{order.products.length > 1 ? " (Will not ship with order)" : ""}<span className="required-star">*</span></p>
                                                                    {product.shipDate ? (
                                                                        <p style={{ "marginTop": "0px", "marginBottom": "0px" }}><span className="required-star">*</span>Ships on or around {product.shipDate}.</p>
                                                                    ) : (
                                                                        <p style={{ "marginTop": "0px", "marginBottom": "0px" }}><span className="required-star">*</span>Shipping Date will be announced via email.</p>
                                                                    )}
                                                                </React.Fragment>
                                                            ) : product.stock ? (
                                                                <p style={{ "marginTop": "0px", "marginBottom": "0px" }}>In Stock</p>
                                                            ) : (
                                                                <p style={{ "marginTop": "0px", "marginBottom": "0px", "color": "red" }}>Out of Stock</p>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div style={ isMobile ? { "marginTop": "24px" } : { "display": "flex", "alignItems": "center", "marginTop": "24px" } }>
                                                        <div>
                                                            <Quantity updateParent={handleChange} remove={true} index={index} quantity={product.quantity}  />
                                                        </div>
                                                        {product.colorInput}
                                                        {product.hasOwnProperty("caliberInput") && product.caliberInput ? (
                                                            <React.Fragment>
                                                                {product.caliberInput}
                                                            </React.Fragment>
                                                        ) : null}
                                                        {product.hasOwnProperty("typeInput") && product.typeInput ? (
                                                            <React.Fragment>
                                                                {product.typeInput}
                                                            </React.Fragment>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <hr style={{ "marginTop":"48px", "height": "1px", "backgroundColor": "black", "border": "none" }}/>
                                        </div>
                                    );
                                })}
                                <div style={isMobile ? { "display": "flex", "justifyContent": "right"} : { "display": "flex" }}>
                                    <h3 style={{ "margin": 0 }}>Subtotal:</h3>
                                    <h3 style={{ "margin": 0, "marginLeft": "32px" }}>${subtotal}</h3>
                                    {isMobile ? null : (
                                        <React.Fragment>
                                            <div style={{ "width": "100%", "display":"flex", "alignItems": "center", "justifyContent": "right" }}>
                                                <button id="shipping-btn" className="w3-button w3-padding-large w3-border" onClick={() => startCheckout()}><b>{buttonText}</b></button>
                                            </div>
                                        </React.Fragment>
                                    )}
                                </div>
                                {isMobile ? (
                                    <React.Fragment>
                                        <div style={{ "marginTop":"20px", "width": "100%", "display":"flex", "alignItems": "center", "justifyContent": "right" }}>
                                            <button id="shipping-btn" className="w3-button w3-padding-large w3-border" onClick={() => startCheckout() }><b>{buttonText}</b></button>
                                            <div style={{ "width": "100%" }} className="form-error">{orderError}</div>
                                        </div>
                                    </React.Fragment>
                                ) : (
                                    <div style={{ "width": "100%", "textAlign": "right", "marginTop": "16px" }} className="form-error">{orderError}</div>
                                )}
                                <div style={{ "width": "100%", "display": "flex" }}>
                                    <div style={{ "display": "flex", "width": "100%", "marginBottom": "16px" }}>
                                        <input
                                            style={{ "marginRight":"6px" }}
                                            type="checkbox"
                                            name="distributor"
                                            checked={distributor}
                                            onChange={(e) => { setDistributor(!distributor); }}
                                            id="distributor"
                                        />
                                        <label>
                                            I'm a Distributor
                                        </label>
                                    </div>
                                    {distributor ? (
                                        <div style={isMobile ? { "width":"100%" } : { "width":"100%", "maxWidth": "150px" }}>
                                            <input
                                                className="w3-input w3-border"
                                                style={isMobile ? { "width":"100%", "marginTop": "16px" } : { "width":"100%" }}
                                                placeholder="Store Code"
                                                name="storeCode"
                                                value={storeCode}
                                                type="text"
                                                onChange={(e) => setStoreCode(e.target.value)}
                                                maxLength="64"
                                                id="storeCode"
                                            />
                                            {/* <div style={{ "width": "100%" }} className="form-error">{formObjectErrors.stateError}</div> */}
                                        </div>
                                    ) : null}
                                </div>
                            </>
                        ) : (
                            <div style={isMobile ? {} : { "minWidth": "650px", "marginTop": "16px", "marginLeft": "32px" }}>
                                <h3>Your Cart is empty.</h3>
                                <p><Link to="/equipment">Continue shopping</Link> and checkout our amazing products.</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

Cart.propTypes = {
    isMobile: PropTypes.bool,
    modifyOrder: PropTypes.func.isRequired,
    order: PropTypes.object,
    products: PropTypes.array
};

export default Cart;