import React, { useMemo, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import { SlPicture } from "react-icons/sl";
import { AiOutlineClose } from "react-icons/ai";
import { IoCloudUploadOutline } from "react-icons/io5";
import HoverButton from './HoverButton';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const activeStyle = {
    borderColor: '#2196f3'
};
const scroll = 3;

function Dropzone(props) {
    const { updateParent } = props;
    const [dropzoneError, setDropzoneError] = useState('');
    const onDrop = useCallback((acceptedFiles, unacceptableFiles) => {
        if(unacceptableFiles && unacceptableFiles.length > 0) {
            setDropzoneError(unacceptableFiles[0].errors[0].message);
            return;
        }

        if(props.individual) {
            acceptedFiles.forEach((file) => {
                updateParent(file);
            });
        } else {
            updateParent(acceptedFiles);
        }
    }, [updateParent, props.individual]);

    let maxFiles = 5;
    if(props.maxFiles) {
        maxFiles = props.maxFiles
    }

  const { getRootProps, getInputProps, isDragActive, /* acceptedFiles,*/ } = useDropzone({ accept: props.acceptedFileTypes, onDrop, maxFiles });
  
    let files = '';
    let oldFiles = '';
    if(props.files) {
        //Update files array for graphical display here
        oldFiles = props.files.map((file, index) => {
            if(file && (file.path || file.name)) {
                let display = file.path ? file.path : file.name;
                let size = file.size ? file.size : null;
                return (
                    <div
                        style={props.files.length > scroll ? { "width": "95%" } : {}}
                        className='dropzone-file'
                        key={`${display}${index}`}
                    >   
                        <div style={{ "marginRight": "32px", "display": "flex", "alignItems": "center", "padding": "12px", "border": "2px solid #e8eaf6", "borderRadius": "8px" }}>
                            <SlPicture style={{ "transform": "scale(1.5)" }} />
                        </div>
                        <div>
                            <p style={{ "margin": "0px" }}>{display}</p>
                            {size ? (
                                <p style={{ "margin": "0px", "fontStyle": "italic" }}>{(size / 1000000).toFixed(2)} MB</p>
                            ) : null}
                        </div>
                        <div style={{ "width": "100%", "textAlign": "right", "paddingRight": "6px" }}>
                            <HoverButton 
                                normalIcon={<AiOutlineClose style={{ "transform": "scale(1.5)", "color": "grey" }} className="ai-edit-icon"/>}
                                hoverIcon={<AiOutlineClose style={{ "transform": "scale(1.5)", "color": "black" }} className="ai-edit-icon"/>}
                                updateParent={updateParent} 
                                updateObject={file}
                                type={"delete"}
                                style={{ "display": "inline" }}
                            />
                        </div>
                    </div>
                );
            } else {
                return false;
            }
        });
        files = [...oldFiles];    
    } else {
        files = [];
    }

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
    }), [isDragActive]);

    let text = `Drag 'n' drop some files here, or click to select files`;
    if(props.text) {
        text = props.text
    }

    return (
        <div style={{ "cursor": "pointer" }} className="container">
            <div {...getRootProps({style})}>
                <input {...getInputProps()} />
                <IoCloudUploadOutline style={{ "transform": "scale(1.5)" }} />
                <p style={{ "marginTop": "4px", "textAlign": "justify" }}>{text}</p>
            </div>
            <div style={{ "textAlign":"center", "marginTop":"16px", "paddingBottom": "0px" }} className="form-error">{dropzoneError}</div>
            {props.search ? (
                <div>
                
                </div>
            ) : null}
            {props.display && files && files.length > 0 ? (
                <div style={files.length > scroll ? { "width": "100%", "whiteSpace":"nowrap", "overflowY": "scroll", "maxHeight": "325px" } : { "width": "100%", "whiteSpace":"nowrap", "maxHeight": "325px" }}>
                    {files}
                </div>
            ) : null}
        </div>
    );
}

Dropzone.propTypes = {
    acceptedFileTypes: PropTypes.object.isRequired,
    display: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool,
    updateParent: PropTypes.func,
    text: PropTypes.string,
    files: PropTypes.array
};

export default Dropzone;