import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import { getObjectValue, getJPEGMetaData, log, roundToHour, createDeepClone, simpleAmericanDate, removeSecondsFromAmericanDate, generateDatesArray, convertLocaletoFullISOFormat, setObjectFunc } from "../Utils/Utils";
import { loadLayersModel, optimalImageObjectDetection } from "../Utils/TensorflowUtils";
import DropZone from "../components/DropZone";
import { getLunarData, getTimeSeriesWeatherData } from "../Utils/WeatherUtils";
import { convertTimeSeriesChartDataToExcelData, downloadExcelFile } from "../Utils/ExcelUtils";
import SideNavbar from "../components/SideNavbar";
import Modal from "../components/Modal";
import Error from "./Error";
import Loading from "./Loading";
import AnimatedEllipsis from "../components/AnimatedEllipsis";
import DeerTinder from "../components/DeerTinder";
import { buildImageDBObjects, deriveImageKey, getImageSession, removeImageObject, saveImageSession, stringSeperator } from "../Utils/ImageDBUtils";
import { FaArrowLeft } from "react-icons/fa";
import { SlPicture } from "react-icons/sl";
import { CiCalendarDate } from "react-icons/ci";
import { v4 as uuidv4 } from 'uuid';
import Charts from "../components/Charts";
import HoverButton from "../components/HoverButton";
import { BsTrash3, BsTrash3Fill } from "react-icons/bs";
import { RiFileEditFill, RiFileEditLine } from "react-icons/ri";
import FormInput from "../components/FormInput";
import ToolTip from "../components/ToolTip";

const loadingGIF = 'https://vale-prod-images.s3.us-east-2.amazonaws.com/deer-running.gif';
const errorGIF = 'https://vale-prod-images.s3.us-east-2.amazonaws.com/oh-deer.gif';
const dropZoneText = 'Drag and Drop trailcam images to identify deer and gather data on this location.';
const modelUrl = `${process.env.PUBLIC_URL}/deer_vision_model/model.json`;
const freeTierMonthDataLimit = 12;
// const deerUnitDataToGenerate = [{ "name": "Deer Sighting", "unitValue": 0 }, { "name": "Deer Quantity", "unitValue": 0 }, { "name": "Doe Quantity", "unitValue": 0 }, { "name": "Buck Quantity", "unitValue": 0 }];
const deerUnitDataToGenerate = [{ "name": "Deer Sighting", "unitValue": 0 }];

function DeerVision({ currentProfile, dataView, isMobile, setCurrentProfile }) {
    const [dataFetched, setDataFetched] = useState(false);
    const [images, setImages] = useState([]);
    const [deerVisionModel, setDeerVisionModel] = useState(null);
    const [timeSeriesData, setTimeSeriesData] = useState({});
    const [maxDate, setMaxDate] = useState(null);
    const [minDate, setMinDate] = useState(null);
    const [timeSeriesCoordinateKey, setTimeSeriesCoordinateKey] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [spreadsheetError, setSpreadsheetError] = useState('');
    const [analyzingPhotos, setAnalyzingPhotos] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [locationNotFound, setLocationNotFound] = useState(true);
    const [showLabeler, setShowLabeler] = useState(false);
    const [imagesObject, setImagesObject] = useState({});
    const [duplicatesCount, setDuplicatesCount] = useState(0);
    const [tableHeaders, setTableHeaders] = useState(null);
    const [tableData, setTableData] = useState(null);
    const [editDatapoint, setEditDatapoint] = useState(null);
    const [removeDatapoint, setRemoveDatapoint] = useState(null);
    const [originalDatapoint, setOriginalEditDatapoint] = useState(null);
    const [timeSeriesToIndexedDBTranslationObject, setTimeSeriesToIndexedDBTranslationObject] = useState({});
    const { sub, location } = useParams();
    const navigate = useNavigate();

    const handleImageUpload = async (e, type) => {
        setDuplicatesCount(0);
        setSpreadsheetError('');
        if(type === "delete") {
            let newImages = images.filter(item => item !== e);
            setImages(newImages);
        } else {
            let newImages = [];
            let duplicatesFound = 0;
            await Promise.all(e.map((image) => {
                let key = deriveImageKey(image);
                if(!getObjectValue(imagesObject, key)) {
                    newImages.push(image);
                } else {
                    duplicatesFound++;
                }
                return(true);
            }));
            if(duplicatesFound) {
                setDuplicatesCount(duplicatesFound);
            }
            setImages([...images, ...newImages]);
        }
    };

    // Load Keras Model
    useEffect(() => {
        loadLayersModel(modelUrl).then((model) => {
            setDeerVisionModel(model);
            setDataFetched(true);
        });
    }, []);

    const populateDeerTimeSeriesData = async (images) => {
        let validImages = [];
        let invalidImages = [];
        let exifData = []
        await Promise.all(images.map(async (image) => {
            let exifImageData = await getJPEGMetaData(image);
            let imageId = deriveImageKey(image);
            if(exifImageData && !getObjectValue(imagesObject, imageId)) {
                exifData.push(exifImageData);
                validImages.push(image);
            } else {
                invalidImages.push(image);
            }
            return true;
        }));

        if(validImages.length > 0) {
            let newImagesObject = await buildImageDBObjects(validImages);

            let predictions = await optimalImageObjectDetection(deerVisionModel, validImages, "Deer Vision", false);

            await Promise.all(predictions.map(async (prediction, index) => {
                let exifImageData = exifData[index];

                let imageToUpdate = deriveImageKey(validImages[index]);

                // Get Date Time Stamp from Image Exif Data
                let dateTime = exifImageData.DateTimeOriginal;
                updateDeerSightingTimeSeriesData(dateTime, prediction, imageToUpdate);               

                // Assign Image Object Data for local storage in IndexDB
                Object.assign(newImagesObject[imageToUpdate].data, { "DateTimeOriginal": exifImageData.DateTimeOriginal.getTime(), prediction });

                return true;
            }));
            log(timeSeriesData, "Updated Time Series Data:");

            setDateRange();

            log(newImagesObject, "Data to be updated in IndexedDB:", false, false);
            setImagesObject({ ...imagesObject, ...newImagesObject });
            updateImagesObject(newImagesObject, true);
        }

        setTimeout(() => {
            setAnalyzingPhotos(false);
            setShowModal(false);
        }, 1000);
    };

    const setDateRange = () => {
        if(timeSeriesData && getObjectValue(timeSeriesData, timeSeriesCoordinateKey) && Object.keys(timeSeriesData[timeSeriesCoordinateKey]).length > 0) {
            let dateRange = Object.keys(timeSeriesData[timeSeriesCoordinateKey]["Deer Sighting"].data);
            dateRange = dateRange.map((date) => new Date(date));
            let earliestDate = new Date(Math.min(...dateRange));
            setMinDate(earliestDate);

            let latestDate = new Date(Math.max(...dateRange));
            setMaxDate(latestDate);

            return [earliestDate, latestDate];
        } else {
            return [false, false];
        }
    };

    const updateDeerSightingTimeSeriesData = (dateTime, prediction, imageKey) => {
        let dateTimeDate = new Date(dateTime);
        dateTimeDate = roundToHour(dateTimeDate);
        let isoDateTime = dateTimeDate.toISOString();

        // If time series data has not been defined for this lat lon, assign it and pre-populate sighting data with unit values
        if(!getObjectValue(timeSeriesData, timeSeriesCoordinateKey)) {
            Object.assign(timeSeriesData, { [timeSeriesCoordinateKey]: {} });
    
            deerUnitDataToGenerate.map((dataToGenerate) => {
                Object.assign(timeSeriesData[timeSeriesCoordinateKey], { [dataToGenerate.name]: { "data": {} } });
                return true;
            });
        }
        
        let timeSeriesCoordinateObject = timeSeriesData[timeSeriesCoordinateKey];
        let deerSightingTimeSeriesCoordinateData = timeSeriesCoordinateObject["Deer Sighting"].data;

        // Deer was detected in image
        let wasDeerSighted = 0;
        if(prediction >= 0.5) {
            wasDeerSighted = 1;
        }

        // Assign Time Series Data
        Object.assign(deerSightingTimeSeriesCoordinateData, { [isoDateTime]: wasDeerSighted });
        if(!getObjectValue(timeSeriesToIndexedDBTranslationObject, isoDateTime)) {
            Object.assign(timeSeriesToIndexedDBTranslationObject, { [isoDateTime]: imageKey });
        }
    }

    const populateWeatherTimeSeriesData = async (startDate, endDate) => {
        let lat = timeSeriesCoordinateKey.split(" ")[0];
        let lon = timeSeriesCoordinateKey.split(" ")[1];

        if((((new Date(endDate)).getMonth() - (new Date(startDate)).getMonth()) + 1) > freeTierMonthDataLimit) {
            return false;
        }

        let station = '';
        if(selectedLocation && getObjectValue(selectedLocation, "station")) {
            station = selectedLocation.station;
        }

        // Get weather time series data
        let [weatherTimeSeriesData, newStation]  = await getTimeSeriesWeatherData("meteostat", startDate, endDate, true, '', lat, lon, station);

        // If weather station has changed due to a lat/lon change or if this is the first time API call has been made save the station to this 
        // location to save API calls later
        if(newStation && newStation !== station) {
            updateLocation({ "station": newStation });
        }

        // Get  lunar time series data
        let lunarData = await getLunarData(weatherTimeSeriesData);
        // console.log(lunarData);

        // Get data associated to specific lat lon
        let timeSeriesCoordinateObject = timeSeriesData[timeSeriesCoordinateKey];

        // Merge lunar and lunar data to the selected location
        Object.assign(timeSeriesCoordinateObject, { ...lunarData, ...weatherTimeSeriesData });

        // Populate Time Series Data with 0s for all dates within date range that did not have a deer sighting
        await populateDeerAbsenseTimeSeriesData(Object.keys(weatherTimeSeriesData[Object.keys(weatherTimeSeriesData)[0]].data));

        // console.log(timeSeriesData);

        return true;
    };

    const populateDeerAbsenseTimeSeriesData = async (dateArray) => {
        let timeSeriesCoordinateObject = timeSeriesData[timeSeriesCoordinateKey];
        let deerSightingData = timeSeriesCoordinateObject["Deer Sighting"].data;
        await Promise.all(dateArray.map((date) => {
            if(!getObjectValue(deerSightingData, date)) {
                Object.assign(deerSightingData, { [date]: 0 });
            }
            return true;
        }));
    };

    const updateLocation = (locationChanges) => {
        let locationsClone = createDeepClone(currentProfile.locations);
        Object.assign(locationsClone[timeSeriesCoordinateKey], { ...locationChanges });

        setCurrentProfile({ ...currentProfile, "locations": locationsClone });
    };

    const buildSpreadsheetFromTimeSeries = async () => {
        setSpreadsheetError('');
        let lat = timeSeriesCoordinateKey.split(" ")[0];
        let lon = timeSeriesCoordinateKey.split(" ")[1];
        if(maxDate && minDate && currentProfile && currentProfile.locations) {
            let result = await populateWeatherTimeSeriesData(minDate, maxDate);
            if(!result) {
                setSpreadsheetError('Cannot get more than 12 months of weather data on Free Tier.');
            } else {
                let timeSeriesCoordinateObject = timeSeriesData[timeSeriesCoordinateKey];
                let [spreadsheetData, headers] = await convertTimeSeriesChartDataToExcelData(timeSeriesCoordinateObject, timeSeriesCoordinateKey, minDate, maxDate);
                // console.log(spreadsheetData);
    
                downloadExcelFile(minDate, maxDate, spreadsheetData, headers, `${lat}${lon}`);
            }
        } else {
            setSpreadsheetError("Could not download spreadsheet, no dates set!");
        }
    };

    const convertImagesObjectToTimeSeries = (storedImagesObject, addToImagesObject) => {
        Object.values(storedImagesObject).map((storedImageObject) => {
            let imageObjectData = storedImageObject.data;
            let prediction = getObjectValue(imageObjectData, "Deer Sighting") ? imageObjectData["Deer Sighting"] : imageObjectData.prediction;
            updateDeerSightingTimeSeriesData(imageObjectData.DateTimeOriginal, prediction, storedImageObject.id);
            if(addToImagesObject) {
                if(getObjectValue(imagesObject, storedImageObject.id)) {
                    Object.assign(imagesObject, { [storedImageObject.id]: { ...imagesObject[storedImageObject.id], ...storedImageObject } });
                } else {
                    Object.assign(imagesObject, { [storedImageObject.id]: storedImageObject });
                }
            }
            return true;
        });
    };

    useEffect(() => {
        if(location) {
            setTimeSeriesCoordinateKey(location);
        }
    }, [location]);

    useEffect(() => {
        if(currentProfile && getObjectValue(currentProfile, "locations") && location) {
            if(getObjectValue(currentProfile.locations, location)) {
                setLocationNotFound(false);
                setSelectedLocation(currentProfile.locations[location]);
            } else {
                setLocationNotFound(true);
            }
        }
    }, [location, currentProfile]);

    useEffect(() => {
        document.title = 'Deer Vision Dashboard';
    });

    const convertTimeSeriesDataToLists = async (timeSeriesCoordinateObject) => {
        let headers = ["Date", ...Object.keys(timeSeriesCoordinateObject)];
    
        let listDataObject = {};
        await Promise.all(Object.keys(timeSeriesCoordinateObject).map(async(dataType) => {
            let data = timeSeriesCoordinateObject[dataType].data;
            let datakeys = [...Object.keys(data)];
            datakeys.sort((a,b) => {
                // Turn your strings into dates, and then subtract them to get a value that is either negative, positive, or zero.
                return new Date(a) - new Date(b);
            });
            await Promise.all(datakeys.map((date) => {
                let existingSpreadsheetDateData = getObjectValue(listDataObject, date) ? listDataObject[date] : {}
                Object.assign(listDataObject, { [date]: { ...existingSpreadsheetDateData, [dataType]: data[date] } });
                return true;
            }));
        }));
    
        let listData = await Promise.all(Object.keys(listDataObject).map((date) => {
            return { "Date": date, ...listDataObject[date] };
        }));
    
        // console.log(listData);
    
        return [listData, headers];
    };

    useEffect(() => {
        if(timeSeriesData && timeSeriesCoordinateKey && minDate && maxDate) {
            let timeSeriesCoordinateObject = timeSeriesData[timeSeriesCoordinateKey];
            if(timeSeriesCoordinateObject) {
                convertTimeSeriesDataToLists(timeSeriesCoordinateObject).then((data) => {
                    let [listData, headers] = data;
                    setTableData(listData);
                    setTableHeaders(headers);
                });
            }
        } else {
            setTableData(null);
            setTableHeaders(null);
        }
    }, [timeSeriesData,timeSeriesCoordinateKey, minDate, maxDate]);

    const loadIndexedDBData = async () => {
        getImageSession(selectedLocation.labeledDataId).then((response) => {
            let storedImages = null;
            let storedImagesObject = null;
            if(response) {
                storedImages = response[0];
                storedImagesObject = response[1];
            }
            
            if(storedImages && storedImages.length > 0) {
                setImages(storedImages);
            }
            if(storedImagesObject && Object.keys(storedImagesObject).length > 0) {
                convertImagesObjectToTimeSeries(storedImagesObject);
                let [startDate, endDate] = setDateRange();
                // generateDatesArray(startDate, endDate).then((dates) => {
                    // populateDeerAbsenseTimeSeriesData(dates);
                // });
                setImagesObject({ ...storedImagesObject });
            }
        });
    };

    // Load IndexedDB Entries on page load
    useEffect(() => {
        if(timeSeriesCoordinateKey && currentProfile && selectedLocation) {
            loadIndexedDBData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeSeriesCoordinateKey, currentProfile, selectedLocation, dataView]);

    // Load Cloud Entries into Database if they are missing
    useEffect(() => {
        if(currentProfile && getObjectValue(currentProfile, "labeledData") && selectedLocation && getObjectValue(currentProfile.labeledData, selectedLocation.labeledDataId)) {
            let databaseObjectsHaveBeenLoaded = Object.keys(timeSeriesToIndexedDBTranslationObject).length === Object.keys(imagesObject).length;
            if(databaseObjectsHaveBeenLoaded) {
                convertImagesObjectToTimeSeries(currentProfile.labeledData[selectedLocation.labeledDataId], true);
                setDateRange();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentProfile, timeSeriesToIndexedDBTranslationObject, imagesObject, selectedLocation]);
    
    const updateImagesObject = async (updatedImagesObject, updateProfile) => {
        if(updatedImagesObject && Object.keys(updatedImagesObject)) {
            // Update DB Objects
            log(Object.values(updatedImagesObject), "Database Objects to be Updated:");
            saveImageSession(selectedLocation.labeledDataId, Object.values(updatedImagesObject));

            // Update Profile DB Objects
            let updateDBAgain = false;
            let updatedLabeledDataObject = {};
            if(getObjectValue(currentProfile.labeledData, selectedLocation.labeledDataId)) {
                updatedLabeledDataObject = createDeepClone(currentProfile.labeledData[selectedLocation.labeledDataId]);
            }
            await Promise.all(Object.keys(updatedImagesObject).map((updatedImageObjectKey) => {
                let updatedImageObject = createDeepClone(updatedImagesObject[updatedImageObjectKey]);
                delete updatedImageObject.url;
                if(getObjectValue(updatedImageObject, "cloudId") && getObjectValue(updatedLabeledDataObject, updatedImageObject.cloudId)) {
                    Object.assign(updatedLabeledDataObject[updatedImageObject.cloudId], { ...updatedImageObject });
                } else {
                    let cloudId = uuidv4();
                    Object.assign(updatedLabeledDataObject, { [cloudId]: { ...updatedImageObject, cloudId }});
                    Object.assign(updatedImagesObject[updatedImageObjectKey], { cloudId});
                    updateDBAgain = true;
                }
                return true;
            }));
            if(updateProfile) {
                setCurrentProfile({ ...currentProfile, "labeledData": { ...currentProfile.labeledData, [selectedLocation.labeledDataId]: updatedLabeledDataObject }});
            }

            if(updateDBAgain) {
                saveImageSession(selectedLocation.labeledDataId, Object.values(updatedImagesObject));
            }
        }
    };

    const editImagesObject = (dataPoint) => {
        let prediction = dataPoint.hasOwnProperty("Deer Sighting") ? dataPoint["Deer Sighting"] : '';
        prediction = typeof prediction === "number" ? prediction : prediction === 'Yes' ? 1 : prediction === 'No' ? 0 : '';
        
        let imageKey = getObjectValue(timeSeriesToIndexedDBTranslationObject, dataPoint.key);
        updateDeerSightingTimeSeriesData(dataPoint.key, prediction, imageKey);

        // If no image key is present, a piece of inferred data is being edited which is not allowed rn
        if(imageKey) {
            let newImagesObject = createDeepClone(imagesObject);

            Object.assign(newImagesObject[imageKey].data, { "Deer Sighting": prediction });
            setImagesObject({ ...imagesObject, ...newImagesObject });
            updateImagesObject(newImagesObject, true);
        }
    };

    const deleteDatapoint = (dataPoint) => {
        let imageKey = getObjectValue(timeSeriesToIndexedDBTranslationObject, dataPoint.key);
        let cloudId = getObjectValue(dataPoint, "cloudId");

        // Remove from TimeSeriesData
        let timeSeriesCoordinateObject = timeSeriesData[timeSeriesCoordinateKey];
        Object.keys(timeSeriesCoordinateObject).map((dataType) => {
            let dataObject = timeSeriesCoordinateObject[dataType].data;
            if(getObjectValue(dataObject, dataPoint.key) || typeof getObjectValue(dataObject, dataPoint.key) === "number") {
                delete dataObject[dataPoint.key];
            }
            return true;
        });
        
        // Remove from IndexedDB
        if(imageKey) {
            removeImageObject(selectedLocation.labeledDataId, imageKey);
        }

        
        // Remove from ImagesObject
        if(imageKey) {
            delete imagesObject[imageKey];
        }

        // Remove from Translation Object
        if(imageKey && getObjectValue(timeSeriesToIndexedDBTranslationObject, dataPoint.key)) {
            delete timeSeriesToIndexedDBTranslationObject[dataPoint.key];
        }

        // Remove from the Cloud
        if(cloudId) {
            let labeledDataClone = createDeepClone(currentProfile.labeledData[selectedLocation.labeledDataId]);
            delete labeledDataClone[cloudId];
            setCurrentProfile({ ...currentProfile, "labeledData": { ...currentProfile.labeledData, [selectedLocation.labeledDataId]: labeledDataClone  } });
        }

        setRemoveDatapoint(null);
    };

    const handleChange = (e, _type, _index) => {
        // console.log(e);
        // console.log(type);

        setObjectFunc(setEditDatapoint, { ...editDatapoint, ...e });
    };

    if(currentProfile && currentProfile.sub === sub && dataFetched && deerVisionModel) {
        let title = "Add New Location";
        let sideNavbarFilters = null;
        if(currentProfile && getObjectValue(currentProfile, "locations") && Object.keys(currentProfile.locations).length > 0) {
            title = "Locations";
            sideNavbarFilters = Object.keys(currentProfile.locations).map((location) => { 
                let display = getObjectValue(currentProfile.locations[location], "nickname") ? currentProfile.locations[location].nickname : location;
                return({ display, "url": `/deer_vision/${currentProfile.sub}/locations/${location}`}); 
            });
        }
        let locationsURI = `/deer_vision/${currentProfile.sub}/locations`;
        let dashboardURI = `/deer_vision/${currentProfile.sub}/locations/${timeSeriesCoordinateKey}`;
        let backButtonTitle = showLabeler ? 'Back to Deer Vision Dashboard.' : dataView ? 'Back to Dashboard' : 'Back to Locations';
        return (
            <>
                <SideNavbar isMobile={isMobile} propFilters={sideNavbarFilters} title={title} titleUrl={locationsURI} initialShow={true} />
                <div style={isMobile ? { "minHeight":"92vh" } : { "minHeight":"92vh", "maxWidth": "90%", "display": "flex", "justifyContent": "center" }} className="w3-content w3-container w3-padding-64" id="about">
                    {dataView ? (
                        <div>
                            <div style={{ "display": "flex" }}>
                                <button 
                                    onClick={() => {
                                        if(showLabeler) {
                                            setShowLabeler(false);
                                        } else {
                                            navigate(dashboardURI);
                                        }
                                    }}
                                    title={backButtonTitle}
                                    className="hidden-btn"
                                    style={{ "paddingTop": "8px" }}
                                >
                                    <FaArrowLeft style={{ "transform": "scale(1.5)", "marginRight": "16px" }}/>
                                </button>
                            <h3 style={{ "margin": "0px", "textAlign": "center" }}>Deer Vision Report Data: <b>{timeSeriesCoordinateKey}</b></h3>
                            </div>
                            {tableHeaders && tableData ? (
                                <div style={{ "marginTop": "16px" }} className="table-container">
                                    <table style={isMobile ? { "borderCollapse": "separate", "borderSpacing": "0 1em" } : {}}>
                                        {isMobile ? (
                                            null
                                        ) : (
                                            <thead>
                                                <tr>
                                                    {tableHeaders.map((header) => {
                                                        return(
                                                            <th key={header}>{header}</th>
                                                        );
                                                    })}
                                                    <th>Image</th>
                                                    <th>Edit</th>
                                                    <th>Remove</th>
                                                </tr>
                                            </thead>
                                        )}
                                        <tbody>
                                            {tableData.map((dataPoint) => {
                                                let aliasedDatapoint = createDeepClone(dataPoint);
                                                Object.assign(aliasedDatapoint, { key: dataPoint.Date });
                                                if(getObjectValue(timeSeriesToIndexedDBTranslationObject, dataPoint.Date)) {
                                                    let imageKey = timeSeriesToIndexedDBTranslationObject[dataPoint.Date];
                                                    if(getObjectValue(imagesObject, imageKey) && getObjectValue(imagesObject[imageKey], "cloudId")) {
                                                        Object.assign(aliasedDatapoint, { "cloudId": imagesObject[imageKey].cloudId });
                                                    }
                                                }
                                                return(
                                                    <tr style={isMobile ? { "height": "165px" } : {}} key={JSON.stringify(dataPoint)}>
                                                        {tableHeaders.map((header, index) => {
                                                            let displayValue = '';
                                                            switch(header) {
                                                                case 'Date':
                                                                    displayValue = getObjectValue(dataPoint, "Date") ? removeSecondsFromAmericanDate(dataPoint.Date) : '';
                                                                    break;
                                                                case 'Deer Sighting':
                                                                    let deerSighting = typeof getObjectValue(dataPoint, "Deer Sighting") === "number" ? dataPoint["Deer Sighting"] : '';
                                                                    displayValue = typeof deerSighting === "number" && deerSighting === 1 ? "Yes" : typeof deerSighting === "number" && deerSighting === 0 ? "No" : '';
                                                                    break;
                                                                default:
                                                                    displayValue = dataPoint[header];
                                                                    break;
                                                            }
                                                            Object.assign(aliasedDatapoint, { [header]: displayValue });
                                                            if(isMobile) {
                                                                return(
                                                                    <td key={`${displayValue}${index}`} style={{ "minHeight": "30px" }}>
                                                                        <div style={{ "float":"left", "display": "flex", "justifyContent": "left", "width": "50%" }}>
                                                                            <b>{header}</b>
                                                                        </div>
                                                                        <div style={{ "float":"right", "display": "flex", "justifyContent": "left", "width": "50%" }}>
                                                                            {displayValue}
                                                                        </div>
                                                                    </td>
                                                                );
                                                            } else {
                                                                return(
                                                                    <td key={`${displayValue}${index}`}>{displayValue}</td>
                                                                );
                                                            }
                                                        })}
                                                        {isMobile ? (
                                                            <td style={{ "minHeight": "30px" }}>
                                                                <div style={{ "float":"left", "display": "flex", "justifyContent": "left", "width": "50%" }}>
                                                                    <b>Image</b>
                                                                </div>
                                                                <div style={{ "float":"right", "display": "flex", "justifyContent": "left", "width": "50%" }}>
                                                                    {getObjectValue(timeSeriesToIndexedDBTranslationObject, aliasedDatapoint.key) ? timeSeriesToIndexedDBTranslationObject[aliasedDatapoint.key].split(stringSeperator)[0] : ''}
                                                                </div>
                                                            </td>
                                                        ) : (
                                                            <td>
                                                                {getObjectValue(timeSeriesToIndexedDBTranslationObject, aliasedDatapoint.key) ? timeSeriesToIndexedDBTranslationObject[aliasedDatapoint.key].split(stringSeperator)[0] : ''}
                                                            </td>
                                                        )}
                                                        {isMobile ? (
                                                            <td style={{ "minHeight": "30px" }}>
                                                                <div style={{ "float":"left", "display": "flex", "justifyContent": "left", "width": "50%" }}>
                                                                    <b>Edit</b>
                                                                </div>
                                                                <div style={{ "float":"right", "display": "flex", "justifyContent": "left", "width": "50%" }}>
                                                                    <HoverButton
                                                                        normalIcon={<RiFileEditLine style={{ "transform": "scale(1.25)"}} />} 
                                                                        hoverIcon={<RiFileEditFill style={{ "transform": "scale(1.25)"}} />}
                                                                        updateParent={() => { setOriginalEditDatapoint(aliasedDatapoint); setEditDatapoint(aliasedDatapoint);  }}
                                                                        title={"Edit Datapoint"}
                                                                    />
                                                                </div>
                                                            </td>
                                                        ) : (
                                                            <td>
                                                                <HoverButton
                                                                    normalIcon={<RiFileEditLine style={{ "transform": "scale(1.25)"}} />} 
                                                                    hoverIcon={<RiFileEditFill style={{ "transform": "scale(1.25)"}} />}
                                                                    updateParent={() => { setOriginalEditDatapoint(aliasedDatapoint); setEditDatapoint(aliasedDatapoint);  }}
                                                                    title={"Edit Datapoint"}
                                                                />
                                                            </td>
                                                        )}
                                                        {isMobile ? (
                                                            <td style={{ "minHeight": "30px" }}>
                                                                <div style={{ "float":"left", "display": "flex", "justifyContent": "left", "width": "50%" }}>
                                                                    <b>Remove</b>
                                                                </div>
                                                                <div style={{ "float":"right", "display": "flex", "justifyContent": "left", "width": "50%" }}>
                                                                    <HoverButton
                                                                        normalIcon={<BsTrash3 style={{ "transform": "scale(1.25)"}} />} 
                                                                        hoverIcon={<BsTrash3Fill style={{ "transform": "scale(1.25)"}} />}
                                                                        updateParent={() => { setRemoveDatapoint(aliasedDatapoint); }}
                                                                        title={"Delete Datapoint"}
                                                                    />
                                                                </div>
                                                            </td>
                                                        ) : (
                                                            <td>
                                                                <HoverButton
                                                                    normalIcon={<BsTrash3 style={{ "transform": "scale(1.25)"}} />} 
                                                                    hoverIcon={<BsTrash3Fill style={{ "transform": "scale(1.25)"}} />}
                                                                    updateParent={() => { setRemoveDatapoint(aliasedDatapoint); }}
                                                                    title={"Delete Datapoint"}
                                                                />
                                                            </td>
                                                        )}
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            ) : null}
                            <div style={{ "display": "flex", "width": "100%", "alignItems": "center", "marginTop": "8px" }}>
                                <p style={isMobile ? { "width": "90%", "margin": "0px" } : { "margin": "0px" }}><span className="required-star">*</span>This data is only what has been uploaded and analyzed/labeled.</p>
                                <ToolTip text={"Additional hourly deer sighting data in the reports is inferred from a lack of photos."}/>
                            </div>
                            {editDatapoint ? (
                                <Modal style={isMobile ? {} : { "width": "450px" }} closeButton={true} fadeIn={0.25} isMobile={isMobile} onClose={() => { setOriginalEditDatapoint(null); setEditDatapoint(null); }}>
                                    <h4>Edit Data for {removeSecondsFromAmericanDate(convertLocaletoFullISOFormat(new Date(editDatapoint.Date)))}</h4>
                                    <p>Edit incorrect data or fix discrepancies.</p>
                                    {Object.keys(editDatapoint).map((dataType, index) => {
                                        let dropdownOptions = '';
                                        let disabled = false;
                                        let inputType = '';
                                        let returnNull = false;
                                        switch(dataType) {
                                            case 'Deer Sighting':
                                                dropdownOptions = ['No', 'Yes'];
                                                inputType = 'dropdown';
                                                break;
                                            default:
                                                returnNull = true;
                                                break;
                                        }
                                        if (returnNull) {
                                            return null;
                                        } else {
                                            return(
                                                <React.Fragment key={`${dataType}${index}`}>
                                                    <p style={{ "marginBottom": "4px" }}>{dataType}</p>
                                                    <FormInput 
                                                        isMobile={isMobile}
                                                        dropDownOptions={dropdownOptions}
                                                        updateParent={handleChange}
                                                        name={dataType}
                                                        object={editDatapoint}
                                                        placeholder={dataType}
                                                        type={inputType}
                                                        disabled={disabled}
                                                    />
                                                </React.Fragment>
                                            );
                                        }
                                    })}
                                    <button 
                                        style={{ "marginTop": "24px", "width": "100%" }} 
                                        className="main-button"
                                        onClick={() => {
                                            let editedDatapoint = createDeepClone(editDatapoint);
                                            editImagesObject(editedDatapoint); 
                                            setOriginalEditDatapoint(null); 
                                            setEditDatapoint(null);  
                                        }}
                                        title={"Save changes to data"}
                                        disabled={JSON.stringify(editDatapoint) === JSON.stringify(originalDatapoint)}
                                    >
                                        Save Changes
                                    </button>
                                </Modal>
                            ) : removeDatapoint ? (
                                <Modal style={isMobile ? {} : { "width": "450px" }} closeButton={true} fadeIn={0.25} isMobile={isMobile} onClose={() => setRemoveDatapoint(null)}>
                                    <h4>Delete Data for {removeSecondsFromAmericanDate(convertLocaletoFullISOFormat(new Date(removeDatapoint.Date)))}</h4>
                                    <p>Are you sure you want to permanently delete this data?</p>
                                    <button style={{ "marginTop": "16px", "width": "100%" }} className="main-button" onClick={() => { deleteDatapoint(removeDatapoint) }}>
                                        Delete Data
                                    </button>
                                </Modal>
                            ) : null}
                        </div>
                    ) : (
                        <div>
                            <div style={{ "display": "flex", "alignItems": "center", "marginBottom": "16px" }}>
                                <button 
                                    onClick={() => {
                                        if(showLabeler) {
                                            setShowLabeler(false);
                                        } else {
                                            navigate(locationsURI);
                                        }
                                    }}
                                    title={backButtonTitle}
                                    className="hidden-btn"
                                    style={{ "paddingTop": "8px" }}
                                >
                                    <FaArrowLeft style={{ "transform": "scale(1.5)", "marginRight": "16px" }}/>
                                </button>
                                {selectedLocation && getObjectValue(selectedLocation, "nickname") && showLabeler ? (
                                    <h3 style={{ "margin": "0px" }}>Labeling {selectedLocation.nickname} Images</h3>
                                ) : selectedLocation && getObjectValue(selectedLocation, "nickname") ? (
                                    <h3 style={{ "margin": "0px" }}>{selectedLocation.nickname}</h3>
                                ) : (
                                    <h3 style={{ "margin": "0px" }}>Deer Vision</h3>
                                )}
                            </div>
                            {showLabeler ? (
                                <p>Manually labeling data overrides the "Deer Sighting" value in the report</p>
                            ) : null}
                            {locationNotFound ? (
                                <div>
                                    <img style={{ "maxWidth": "400px" }} src={errorGIF} alt="Deer looking at the camera in fear" />
                                    <p>That location could not be found...</p>
                                </div>
                            ) : showLabeler ? (
                                <div style={{ "display": "flex", "justifyContent": "center" }}>
                                    <DeerTinder isMobile={isMobile} images={images} imagesObject={imagesObject} updateImagesObject={updateImagesObject} />
                                </div>
                            ) : timeSeriesCoordinateKey ? (
                                <div>
                                    <div style={isMobile ? {} : { "display": "flex" }}>
                                        <div style={{ "minWidth": "200px" }}>
                                            <div style={{ "width": "100%" }}>
                                                <button 
                                                    onClick={() => setShowModal(true)} 
                                                    className="main-button" 
                                                    style={{ "width": "100%" }}
                                                    title={"Upload Deer Photos and Run them through our Deer Recognition AI"}
                                                >
                                                    Upload Trailcam Photos
                                                </button>
                                            </div>
                                            <div style={{ "width": "100%", "marginTop": "16px" }}>
                                                <button 
                                                    onClick={() => buildSpreadsheetFromTimeSeries() } 
                                                    className={!getObjectValue(timeSeriesData, timeSeriesCoordinateKey) ? "main-button" : "main-button main-button-pulse"} 
                                                    style={{ "width": "100%" }} 
                                                    disabled={!getObjectValue(timeSeriesData, timeSeriesCoordinateKey)}
                                                    title={"Generate a Report with Weather, Lunar, and Deer Sighting Data"}
                                                >
                                                    Generate Report
                                                </button>
                                            </div>
                                            <div style={{ "width": "100%", "marginTop": "16px" }}>
                                                <button 
                                                    onClick={() => navigate(`/deer_vision/${currentProfile.sub}/locations/${timeSeriesCoordinateKey}/data`) } 
                                                    className={!getObjectValue(timeSeriesData, timeSeriesCoordinateKey) ? "main-button" : "main-button"} 
                                                    style={{ "width": "100%" }} 
                                                    disabled={!getObjectValue(timeSeriesData, timeSeriesCoordinateKey)}
                                                    title={"View and Edit the Data downloaded to the report"}
                                                >
                                                    View Report Data
                                                </button>
                                            </div>
                                            <div style={{ "width": "100%", "marginTop": "16px" }}>
                                                <button 
                                                    onClick={() => setShowLabeler(true)} 
                                                    className="main-button" 
                                                    style={{ "width": "100%" }} 
                                                    disabled={!(images && images.length > 0)}
                                                    title={"Manually Label Recently Uploaded Photos"}
                                                >
                                                    Label Data
                                                </button>
                                            </div>
                                            <div style={{ "color": "red", "marginTop": "16px"}}>{spreadsheetError}</div>
                                        </div>
                                        <div>
                                            <div style={{ "display": "flex" }}>
                                                <div className="dark-border button-shadow" style={isMobile ? { "padding": "16px", "borderRadius": "16px" } : { "marginLeft": "16px", "padding": "16px", "borderRadius": "16px" }}>
                                                    <div style={{ "display": "flex", "alignItems": "center" }}>
                                                        <p>Photos Analyzed</p>
                                                        <SlPicture style={{ "transform": "scale(1.5)", "marginLeft": "32px" }} />
                                                    </div>
                                                    <div style={{ "marginTop": "64px"}}>
                                                        {currentProfile.labeledData ? (
                                                            <h1 style={{ "margin": "0px" }}>{getObjectValue(currentProfile.labeledData, selectedLocation.labeledDataId) ? Object.keys(currentProfile.labeledData[selectedLocation.labeledDataId]).length : 0}</h1>
                                                        ) : (
                                                            <h1 style={{ "margin": "0px" }}>0</h1>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="dark-border button-shadow" style={{ "marginLeft": "16px", "padding": "16px", "borderRadius": "16px" }}>
                                                    <div style={{ "display": "flex", "alignItems": "center" }}>
                                                        <p>Dates Analyzed</p>
                                                        <CiCalendarDate style={{ "transform": "scale(2.0)", "marginLeft": "32px" }} />
                                                    </div>
                                                    <div style={{ "marginTop": "16px"}}>
                                                        {minDate && maxDate ? (
                                                            <React.Fragment>
                                                                <h2 style={{ "margin": "0px" }}>{simpleAmericanDate(minDate)}</h2>
                                                                <p style={{ "margin": "0px", "textAlign": "center" }}>to</p>
                                                                <h2 style={{ "margin": "0px" }}>{simpleAmericanDate(maxDate)}</h2>
                                                            </React.Fragment>
                                                        ) : (
                                                            <React.Fragment>
                                                                <h2 style={{ "marginTop": "64px"}}>No Dates Found</h2>
                                                            </React.Fragment>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {getObjectValue(timeSeriesData, timeSeriesCoordinateKey) && getObjectValue(timeSeriesData[timeSeriesCoordinateKey], "Deer Sighting") && getObjectValue(timeSeriesData[timeSeriesCoordinateKey]["Deer Sighting"], "data") ? (
                                        <div style={{ "marginTop": "32px" }}>
                                            <Charts
                                                isMobile={isMobile}
                                                type='line' 
                                                data={{ 
                                                    labels: Object.keys(timeSeriesData[timeSeriesCoordinateKey]["Deer Sighting"].data).sort().map((date) => { return removeSecondsFromAmericanDate(date); }), 
                                                    datasets: [{ 
                                                        label: 'Deer Sightings', 
                                                        data: Object.keys(timeSeriesData[timeSeriesCoordinateKey]["Deer Sighting"].data).sort().map((key) => { 
                                                            let dataPoint = timeSeriesData[timeSeriesCoordinateKey]["Deer Sighting"].data[key];
                                                            return { x: removeSecondsFromAmericanDate(key), y: dataPoint };
                                                        }),
                                                        // stepped: true
                                                    }]
                                                }} 
                                                options={{ 
                                                    responsive: true, 
                                                    plugins: { 
                                                        legend: { 
                                                            position: 'top' 
                                                        }, 
                                                        title: { 
                                                            display: true, 
                                                            text: 'Hourly Deer Sightings' 
                                                        }
                                                    },
                                                    scales: {
                                                        y: {
                                                            min: 0,
                                                            max: 1
                                                        }
                                                    } 
                                                }}
                                            />
                                        </div>
                                    ) : null}
                                </div>
                            ) : null}
                            {showModal ? (
                                <Modal style={isMobile ? {} : { "width": "450px" }} closeButton={true} fadeIn={0.25} isMobile={isMobile} onClose={() => setShowModal(false)}>
                                    <h4>Upload Trailcam Photos</h4>
                                    {analyzingPhotos ? (
                                        <div style={{ "marginTop": "16px", "textAlign": "center" }}>
                                            <img style={{ "width": "50%" }} src={loadingGIF} alt="Deer Running" />
                                            <p style={{ "marginBottom": "0px" }}>Spotting Deer<AnimatedEllipsis/></p>
                                        </div>
                                    ) : (
                                        <React.Fragment>
                                            <p>Identify deer in your trailcam photos using our AI/Machine Learning model.</p>
                                            <div style={{ "marginTop": "16px" }}>
                                                <DropZone acceptedFileTypes={{ 'image/*': ['.jpg', '.jpeg'] }} updateParent={handleImageUpload} files={images} display={true} text={dropZoneText} maxFiles={32} />
                                            </div>
                                            <button 
                                                style={{ "marginTop": "24px", "width": "100%" }} 
                                                className="main-button" 
                                                disabled={images.length === 0 || analyzingPhotos} 
                                                onClick={() => { setAnalyzingPhotos(true); populateDeerTimeSeriesData(images); }}
                                                title={"Identify deer in your photos using AI"}
                                            >
                                                Identify Deer
                                            </button>
                                            {duplicatesCount ? (
                                                <div style={{ "color": "red", "marginTop": "16px"}}>Duplicate Photos Found (Not Added): {duplicatesCount}</div>
                                            ) : null}
                                        </React.Fragment>
                                    )}
                                </Modal>
                            ) : null}
                        </div>
                    )}
                </div>
            </>
        );
    } else if(!currentProfile && typeof currentProfile === "boolean") {
        return(
            <Error isMobile={isMobile} type={"login"} />
        );
    } else {
        return(
            <Loading isMobile={isMobile} text={"Deer Vision Dashboard"} />
        );
    }
}

DeerVision.propTypes = {
    dataView: PropTypes.bool,
    isMobile: PropTypes.bool,
    currentProfile: PropTypes.object
};

export default DeerVision;