import React from "react";

function Footer() {
    let today = new Date().toISOString().substring(0, 4);
    return (
        <footer className="w3-center footer w3-padding-16">
            <div className="w3-xlarge w3-section">
            </div>
            <p>Copyright © {today} Vale Outdoors, LLC All rights reserved. <a style={{ "marginLeft": "32px" }} href={process.env.REACT_APP_TERMS_OF_SERVICE_LINK} target="_blank" rel="noopener noreferrer">Terms & Conditions</a></p>
            
        </footer>
    );
}

export default Footer;