import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaCaretLeft, FaCaretRight, FaAlignRight } from "react-icons/fa";
import PropTypes from 'prop-types';
import { getObjectValue } from "../Utils/Utils";

function SideNavbar({ handleChange, isMobile, propFilters, show, title, titleUrl, initialShow }) {
    const [filters, setFilters] = useState('');
    const [localShow, setLocalShow] = useState(false);

    useEffect(() => {
        if(propFilters && propFilters.length > 0) {
            let links = propFilters.map((filter, index) => {
                // console.log(filter);
                if(getObjectValue(filter, "nestedFilter")) {
                    return(
                        <div key={index + 31}>
                            <button onClick={() => myAccFunc()} className="w3-button w3-block w3-white w3-left-align" id="myBtn">
                                {filter} <FaCaretLeft />
                            </button>
                            <div id="demoAcc" className="w3-bar-block w3-hide w3-padding-large w3-medium">
                                <Link to="/equipment" className="w3-bar-item w3-button w3-light-grey"><span><FaCaretRight className="fa fa-caret-right w3-margin-right" />Skinny</span></Link>
                                <Link to="/equipment" className="w3-bar-item w3-button">Relaxed</Link>
                                <Link to="/equipment" className="w3-bar-item w3-button">Bootcut</Link>
                                <Link to="/equipment" className="w3-bar-item w3-button">Straight</Link>
                            </div>
                        </div>
                    );
                } else {
                    return(
                        <div key={index + 31}>
                            <Link to={filter.url} className="w3-bar-item w3-button">{filter.display}</Link>
                        </div>
                    );
                }
            });
            setFilters(links);
        }
    }, [propFilters]);

    // Accordion 
    const myAccFunc = () => {
        var x = document.getElementById("demoAcc");
        if (x.className.indexOf("w3-show") === -1) {
            x.className += " w3-show";
        } else {
            x.className = x.className.replace(" w3-show", "");
        }
    }
    
    // Open sidebar
    const w3_open = () => {
        if(handleChange) {
            handleChange(true);
        } else {
            setLocalShow(true);
        }
    }

    // Close sidebar
    const w3_close = () => {
        if(handleChange) {
            handleChange(false);
        } else {
            setLocalShow(false);
        }
    }

    useEffect(() => {
        if(initialShow) {
            setLocalShow(initialShow);
        }
    }, [initialShow]);

    if(localShow || show) {
        return (
            <>  
                {/* Side Navbar */}
                <nav className="w3-sidebar w3-bar-block w3-white w3-collapse w3-top" style={{ "position":"absolute", "top":" 50px", "zIndex": 3, "width": "170px", "maxHeight":"60%" }} id="mySidebar">
                    <div style={{ "display": "flex", "alignItems": "center" }} className="w3-padding-16 w3-large w3-text-grey">
                        {titleUrl && title ? (
                            <Link to={titleUrl} className="w3-bar-item w3-button">{title}</Link>
                        ) : title ? (
                            <p className="w3-bar-item w3-button">{title}</p>
                        ) : null}
                        <button className="hidden-btn" onClick={() => w3_close()} title="Toggle Navigation Menu">
                            <FaAlignRight />
                        </button>
                    </div>
                    <div className="w3-large w3-text-grey" style={{ "fontWeight": "bold" }} >
                        {filters}
                    </div>
                </nav>
                {/* Overlay effect when opening sidebar on small screens */}
                {/* <div className="w3-overlay w3-hide-large" onClick={() => w3_close()} style={{ "cursor": "pointer" }} title="close side menu" id="myOverlay"></div> */}
            </>
        );
    } else {
        return(
            <nav className="w3-sidebar w3-bar-block w3-white w3-collapse w3-top" style={{ "position":"absolute", "top":" 50px", "zIndex": 3, "width": "50px", "maxHeight":"60%" }} id="mySidebar">
                <div style={{ "display": "flex", "justifyContent": "center" }} className="w3-padding-24 w3-large w3-text-grey">
                    <button className="hidden-btn" onClick={() => w3_open()} title="Toggle Navigation Menu">
                        <FaAlignRight />
                    </button>
                </div>
            </nav>
        );
    }
}

SideNavbar.propTypes = {
    handleChange: PropTypes.func,
    isMobile: PropTypes.bool,
    products: PropTypes.array,
    show: PropTypes.bool,
    title: PropTypes.string,
    titleUrl: PropTypes.string,
    initialShow: PropTypes.bool
};  

export default SideNavbar;