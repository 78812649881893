import React from "react";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';

function Error({ isMobile, text, type }) {
    let displayText = 'that page could not be found';
    if(text) {
        displayText = text;
    } else {
        document.title = 'Oops...'
    }

    if(type === "login") {
        return(
            <div style={{ "minHeight":"92vh" }} className="w3-content w3-container w3-padding-64" id="about">
                <div style={{ "display": "flex", "justifyContent": "center" }}>
                    <div style={{ "maxWidth": "650px" }}>
                        <h3>You need to login in order to access this page...</h3>
                        <Link to="/login/account">
                            <button className="w3-button contact-us-btn w3-section">
                                Login
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div style={{ "minHeight":"92vh" }} className="w3-content w3-container w3-padding-64" id="about">
                <div style={{ "display": "flex", "justifyContent": "center" }}>
                    <div style={{ "maxWidth": "650px" }}>
                        <h3>Hmmm, {displayText}...</h3>
                        <Link to="/">
                            <button className="w3-button contact-us-btn w3-section">
                                Back to Home
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
    
}

Error.propTypes = {
    isMobile: PropTypes.bool,
    text: PropTypes.string,
};
  
export default Error;