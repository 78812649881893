import React from "react";
import PropTypes from 'prop-types';
import Paypal from "./Paypal";

function PaymentOptions({ isMobile, cost, description, type, updateParent, postPayment }) {
    return (
        <React.Fragment>
            <Paypal cost={cost} description={description} type={type} updateParent={updateParent} postPayment={postPayment} />
        </React.Fragment>
    );
}

PaymentOptions.propTypes = {
    isMobile: PropTypes.bool,
    cost: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    description: PropTypes.string, 
    type: PropTypes.string, 
    updateParent: PropTypes.func, 
    postPayment: PropTypes.func
};  

export default PaymentOptions;