import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import Error from "./Error";
import { camelCaseToHumanReadable, createDeepClone } from "../Utils/Utils";

function AccountSettings({ currentProfile, isMobile, setCurrentProfile }) {
    const [updateSuccess, setUpdateSuccess] = useState('');
    const [updateError, setUpdateError] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [profileChanges, setProfileChanges] = useState({});
    const [profileChangesMade, setProfleChangesMade] = useState(false);
    const [profileChangesErrors, setProfileChangesErrors] = useState(null);

    useEffect(() => {
        if(currentProfile) {
            setProfileChanges({ ...currentProfile });
            setProfileChangesErrors(createDeepClone(currentProfile.defaultProfileErrors));
        }
    }, [currentProfile]);

    const handleChange = (e) => {
        setUpdateSuccess('');
        setUpdateError('');
        setProfleChangesMade(true);
        setSubmitted(false);
        let profileChangesClone = ({ ...profileChanges });
        Object.assign(profileChangesClone, { [e.target.name]: e.target.value });

        setProfileChanges(profileChangesClone);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitted(true);
        setProfleChangesMade(false);
        let result = await setCurrentProfile(profileChanges);
        if(result) {
            setUpdateSuccess('Successfully upated profile');
        } else {
            setUpdateError('Failed to update profile');
        }
    };

    useEffect(() => {
        document.title = 'Account Info';
    });
    
    if(currentProfile && profileChangesErrors) {
        return (
            <div style={{ "minHeight":"92vh" }} className="w3-content w3-container w3-padding-64" id="about">
                <h3>Account Info</h3>
                <form onSubmit={(e) => handleSubmit(e)}>
                    {Object.keys(currentProfile.defaultProfile).map((key, index) => {
                        let placeholder = key;
                        if(key === "locations" || key === "labeledData") {
                            return null;
                        } else {
                            return(
                                <React.Fragment key={index + 300}>
                                    <input
                                        name={key}
                                        placeholder={camelCaseToHumanReadable(placeholder)}
                                        value={profileChanges[key]}
                                        onChange={(e) => handleChange(e)}
                                        style={{ "marginTop": "8px", "marginBottom": "8px" }}
                                    />
                                    <div className="form-error">{profileChangesErrors[`${key}Error`]}</div>
                                </React.Fragment>
                            );
                        }
                    })}
                    <button className="main-button" type="submit" disabled={submitted || !profileChangesMade}>Apply Changes</button>
                    <div style={{ "color": "red "}}>{updateError}</div>
                    <div style={{ "color": "green "}}>{updateSuccess}</div>                                                                
                </form>
            </div>
        );
    } else {
        return(
            <Error isMobile={isMobile} type="login" />
        );
    }
}

AccountSettings.propTypes = {
    isMobile: PropTypes.bool,
    isPage: PropTypes.bool,
    currentProfile: PropTypes.object,
    setCurrentProfile: PropTypes.func.isRequired
};

export default AccountSettings;
