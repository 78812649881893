import React, { useEffect, useState } from "react"
// import StorePromoHeader from "../components/StorePromoHeader";
import PropTypes from 'prop-types';
import ProductContainer from "../components/ProductContainer";
import SideNavbar from "../components/SideNavbar";
import { useParams } from "react-router-dom";
import { getUnique, includes } from "../Utils/Utils";

// const storeBannerImage = '';

function Store({ isMobile, propProducts }) {
    const [show, setShow] = useState(true);
    const [products, setProducts] = useState('');
    const [sideNavbarFilters, setSideNavbarFilters] = useState(null);
    const  { filters } = useParams();

    useEffect(() => {
        if(propProducts) {
            let sideNavbarFilterStrings = getUnique(propProducts, "filter");
            let additionalFilterStrings = [];
            sideNavbarFilterStrings = sideNavbarFilterStrings.map((filterString) => {
                let refinedFilterString = filterString
                if(includes(filterString, "/")) {
                    let filterStrings = refinedFilterString.split("/");
                    refinedFilterString = filterStrings[0];
                    filterStrings.splice(0, 1).map((splitString) => {
                        additionalFilterStrings.push({ "filter": splitString });
                        return true;
                    });
                }
                return({ "filter": refinedFilterString });
            });
            sideNavbarFilterStrings = [...sideNavbarFilterStrings, ...additionalFilterStrings];
            sideNavbarFilterStrings = getUnique(sideNavbarFilterStrings, "filter");

            let sideNavbarFilters = sideNavbarFilterStrings.map((filterString) => {
                return({ "display": filterString, "url": `/equipment/filters/${filterString}` });
            });
            setSideNavbarFilters(sideNavbarFilters);
        }
    }, [propProducts]);

    useEffect(() => {
        if(filters && propProducts) {
            let filteredProducts = propProducts;
            if(includes(filters, "+")) {
                let mFilters = filters.split("+");
                mFilters.forEach((mFilter) => {
                    // filteredProducts = filteredProducts.filter((product) => product.tag === filters);
                });
                setProducts(filteredProducts);
            } else {
                filteredProducts = filteredProducts.filter((product) => {
                    let matches = false;
                    if(includes(product.filter, "/")) {
                        let productFilters = product.filter.split("/");
                        productFilters.map((productFilter) => {
                            if(filters === productFilter) {
                                matches = true;
                            }
                            return true;
                        });
                    } else if(product.filter === filters) {
                        matches = true;
                    }
                    return matches;
                });
                setProducts(filteredProducts);
            }
        } else if(propProducts) {
            setProducts(propProducts);
        }
    }, [filters, propProducts]);

    useEffect(() => {
        document.title = "Store";
    });

    const handleChange = (e) => {
        setShow(e);
    };

    return (
        <>  
            <SideNavbar handleChange={handleChange} isMobile={isMobile} propFilters={sideNavbarFilters} show={show} title={"Products"} titleUrl={"/equipment"} />
            {/* !PAGE CONTENT! */}
            <div className="w3-main w3-padding-64" style={show ? { "marginLeft": "170px" } : { "marginLeft": "50px" }} >
                <div style={{ "minHeight": "80vh" }}>
                    {/* Push down content on small screens */}
                    {/* <div className="w3-hide-large" style={{ "marginTop": "83px" }}></div> */}

                    {isMobile ? (
                        <h1 className="w3-container">Store</h1>
                    ) : (
                        <React.Fragment>
                            {/* <StorePromoHeader isMobile={isMobile} image={storeBannerImage} heading={"New Products"} subtext={"Collection 2023"} buttonText={"Shop Now"} /> */}
                        </React.Fragment>
                    )}
                    <div className="w3-container w3-text-grey" id="jeans">
                        {products && products.length > 1 ? (
                            <p>{products.length} items</p>
                        ) : products && products.length === 0 ? (
                            <p>{products.length} item</p>
                        ) : (
                            <p>Loading...</p>
                        )}
                    </div>
                    {/* Product grid */}
                    {products && products.length > 0 ? (
                        <div className="w3-container w3-row">
                            <ProductContainer isMobile={isMobile} products={products} />
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    );
}

Store.propTypes = {
    isMobile: PropTypes.bool,
    propProducts: PropTypes.array
};  

export default Store;