import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PropTypes from 'prop-types';
import Error from "./Error";
import Loading from "./Loading";

function RouteToDocument() {
    const  { document } = useParams();
    const [dataFetched, setDataFetched] = useState(false);

    useEffect(() => {
        if(document) {
            let newLocation = '';
            switch (document) {
                case "pack_sling_product_sheet":
                    newLocation = 'https://vale-prod-images.s3.us-east-2.amazonaws.com/Pack%2BSling.pdf';
                    break;
                default:
                    break;
            }
            if(newLocation) {
                window.location = newLocation;
            }
        }
        setTimeout(() => {
            setDataFetched(true);
        }, 3000);
    }, [document])
    
    if(!dataFetched) {
        return(
            <Loading text={"Loading Document..."} />
        );
    } else {
        return(
            <Error text={"that document could not be found"} />
        );
    }
}

RouteToDocument.propTypes = {
    isMobile: PropTypes.bool
};  

export default RouteToDocument;