import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PropTypes from 'prop-types';
import Quantity from "../components/Quantity";
//Style sheet for image gallery
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import { createDeepClone, scrollTo, setObjectFunc, validateInput } from "../Utils/Utils";
import Error from "./Error";
import ProductContainer from "../components/ProductContainer";

function SingleProduct({ isMobile, modifyOrder, order, products }) {
    const [newItem, setNewItem] = useState('');
    const [orderErrors, setOrderErrors] = useState({
        caliberError: '',
        colorError: '',
        quantityError: '',
        stockError: '',
    });
    const [orderError, setOrderError] = useState('');
    const [product, setProduct] = useState(null);
    const [colorOptions, setColorOptions] = useState(null);
    const [caliberOptions, setCaliberOptions] = useState(null);
    const [typeOptions, setTypeOptions] = useState(null);
    const [relatedItems, setRelatedItems] = useState(null);
    const [hasAdditionalFields, setHasAdditionalFields] = useState(false);
    const [addedToCart, setAddedToCart] = useState('');
    const [galleryImages, setGalleryImages] = useState('');
    const [prevSlug, setPrevSlug] = useState(null);
    const [price, setPrice] = useState(null);
    const { slug, instructions } = useParams();

    useEffect(() => {
        if(order && products && products.length > 0 && (!product || (prevSlug && prevSlug !== slug))) {
            let product = products.filter((product) => product.slug === slug);
            if(product && product.length > 0) {
                product = product[0];
                // console.log(product);

                let newOrderObject = { color: '', quantity: 1, ...product };
                let hasAdditionalFields = false;

                document.title = product.name;
                
                let colorOptions = '';
                if(product.colors && product.colors.length > 0) {
                    colorOptions = product.colors.map((color, index) => {
                        return(<option value={color} name="color" key={index}>{color}</option>);
                    });
                    Object.assign(newOrderObject, { "color": '' });
                }
                // console.log(colorOptions);

                let caliberOptions = '';
                if(product.calibers && product.calibers.length > 0) {
                    caliberOptions = product.calibers.map((caliber, index) => {
                        return(<option value={caliber} name="caliber" key={index}>{caliber}</option>);
                    });
                    hasAdditionalFields = true;
                    Object.assign(newOrderObject, { "caliber": '' });
                }
                // console.log(caliberOptions);

                let relatedItems = [];
                if(product.relatedItems && product.relatedItems.length > 0) {
                    product.relatedItems.map((item) => {
                        let itemIndex = products.findIndex((nProduct) => nProduct.productId === item);
                        if(itemIndex >= 0) {
                            relatedItems.push(products[itemIndex]);
                        }
                        return true;
                    });
                }
                // console.log(relatedItems);

                if(relatedItems.length === 0) {
                    relatedItems = '';
                }

                // For multi type products with varied prices
                let typeOptions = '';
                if(product.types && product.types.length > 0) {
                    typeOptions = product.types.map((typeObject, index) => {
                        let type = typeObject.type;
                        let currencyType = '$';
                        let caption = typeObject.hasOwnProperty("price") && typeObject.price !== product.price ? `${type} (${currencyType}${(typeObject.price - product.price).toFixed(2)})` : type;
                        return(<option value={type} name="type" key={index}>{caption}</option>);
                    });
                    hasAdditionalFields = true;
                    Object.assign(newOrderObject, { "type": '' });
                }
                // console.log(typeOptions);

                setNewItem(newOrderObject);
                setHasAdditionalFields(hasAdditionalFields);
                setColorOptions(colorOptions);
                setCaliberOptions(caliberOptions);
                setTypeOptions(typeOptions);
                setRelatedItems(relatedItems);
                setPrice(product.price);
                setProduct(product);
                setPrevSlug(slug);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [order, products, slug]);

    const handleChange = (e) => {
        setAddedToCart('');
        let updateData = {};
        if(e.hasOwnProperty('target')) {
            e.preventDefault();
            Object.assign(updateData, { [e.target.name]: e.target.value });
            if(e.target.name === "type") {
                let typeObject = product.types[product.types.findIndex((type) => type.type === e.target.value)];
                if(typeObject.hasOwnProperty("price") && typeObject.price) {
                    setPrice(typeObject.price)
                }
            }
        } else {
            if(e.hasOwnProperty("quantity") && e.quantity === 0) {
                return;
            } else {
                updateData = { ...updateData, ...e };
            }
        }
        setObjectFunc(setNewItem, updateData)
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setOrderErrors({
            quantity: '',
            colorError: '',
            stockError: ''
        });
        setOrderError('');

        let orderClone = createDeepClone(newItem);
        delete orderClone.filter;
        delete orderClone.hidden;
        delete orderClone.preorder;

        //Check validity of input from profile
        /* Validate Object Prototype:
        validateArray = [{
            name: "",
            displayName: "",
            input: "",
            type: "",
            specialConditions: []
        }, ...additional error JSON objects] 
        *Special Condition can also contain array for multiple conditions
        */

        let validateArray = [];
        let keys = Object.keys(orderClone);
        validateArray = await Promise.all(keys.map((key) => {
            let type = '';
            if(key === "quantity") {
                type = "number";
            } else if (key === "new" || key === "showcase") {
                type = "boolean";
            } else if (key === "stock") {
                type = "stock";
            } else {
                type = "dropdown";
            }
            let displayName = key;
            // if(key === "firstName") {
            //     displayName = "first name";
            // }
            
            //NEED to add Special Conditions for verifying state based on zipcode,
            let specialConditionsArray = [];
            // if(key === "firstName" || key === "lastName") {
            //     specialConditionsArray.push(`!hasNumber(${submitObject[key]})`);
            // }
            let validateObject = {
                name: key,
                displayName,
                input: orderClone[key],
                type,
                specialConditions: specialConditionsArray
            }
            return validateObject;
        }));
        // console.log(validateArray);
        
        let errorsArray = await validateInput(validateArray);
        // console.log(errorsArray);
        
        if(errorsArray) {
            if(errorsArray[0]) {
                // Add order to cart
                modifyOrder({ "products": [...order.products, newItem] });
                setAddedToCart("Item added to cart.");
            } else {
                let orderErrorsClone = { ...orderErrors };
                let errorCount = 0;
                let onlyError = '';
                await errorsArray[1].map((item) => {
                    if(item.errorDisplay) {
                        orderErrorsClone = {
                            ...orderErrorsClone,
                            [`${item.name}Error`]:  item.errorDisplay
                        };
                        errorCount++;
                        onlyError = item.errorDisplay;
                        return true;
                    } else {
                        return false;
                    }
                });
                if(errorCount > 1) {
                    setOrderError("Item could not be added to cart.");
                } else if (errorCount === 1) {
                    setOrderError(onlyError);
                }
                setOrderErrors(orderErrorsClone);
            } 
        } else {
            setOrderError('Please update information in response to found errors');
        }
    };

    useEffect(() => {
        if(!instructions) {
            window.scrollTo(0, 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(product && instructions) {
            scrollTo(`${product.name}-instructions`, "instant");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [instructions, product]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
      //Check for product image(s) and set GalleryImages
      if(product && product.image && Array.isArray(product.image) && product.image.length > 0) {
        //Behold the miracle that is, creating a JSON object within a loop v2
        let images = product.image.map((image) => {
          return({ original: image, thumbnail: image });
        });
        setGalleryImages(images);
      }
    }, [product]);

    if(product && newItem && order) {
        let disableAddToCart = true;
        if(product.stock && newItem.quantity && newItem.quantity > 0 && newItem.color) {
            if(hasAdditionalFields) {
                let keepDisabled = false;
                if(product.hasOwnProperty("calibers") && product.calibers && product.calibers.length > 0) {
                    if(!newItem.caliber) {
                        keepDisabled = true;
                    }
                }
                if(product.hasOwnProperty("types") && product.types && product.types.length > 0) {
                    if(!newItem.type) {
                        keepDisabled = true;
                    }
                }
                disableAddToCart = keepDisabled;
            } else {
                disableAddToCart = false;
            }
        }
        return (
            <>
                {/* About section */}
                <div className="w3-container w3-padding-64" style={{ "minHeight": "91.95vh"}} id="about">
                    <div style={isMobile ? {} : { "display": "flex", "justifyContent": "center", "alignItems": "center" }}>
                        <div style={isMobile ? {} : { "display": "flex", "justifyContent": "center", "alignItems": "center", "maxWidth":"800px" }}>
                            <div style={isMobile ? {} : { "width": "100%" }}>
                                {Array.isArray(product.image) && galleryImages ? (
                                    <ImageGallery
                                        items={galleryImages}
                                        showFullscreenButton={false}
                                        showPlayButton={false}
                                        showNav={false}
                                    />
                                ) : !Array.isArray(product.image) ? (
                                    <img src={product.image} alt="Me" className="w3-image w3-padding-32" width="600" height="650" />
                                ) : null}
                            </div>
                            <form onSubmit={(e) => handleSubmit(e)} style={isMobile ? {} : { "width": "55%", "marginLeft": "64px" }}>
                                <h1 id={`${product.name}`}>{product.name}</h1>
                                <h3><b>${price}</b></h3>
                                <p style={{ "maxWidth": "300px" }}>{product.description}</p>
                                {product.rifleCompatibility && product.rifleCompatibility.length > 0 ? (
                                    <>
                                        <p style={{ "maxWidth": "300px" }}>Rifle Platforms:</p>
                                        <ul>
                                            {product.rifleCompatibility.map((rifle, index) => {
                                                return(
                                                    <li key={index + 20}>{rifle}</li>
                                                );
                                            })}
                                        </ul>
                                    </>
                                ) : null}
                                {product.padCompatibility && product.padCompatibility.length > 0 ? (
                                    <>
                                        <p style={{ "maxWidth": "300px" }}>Compatible With:</p>
                                        <ul>
                                            {product.padCompatibility.map((pad, index) => {
                                                return(
                                                    <li key={index + 20}>{pad}</li>
                                                );
                                            })}
                                        </ul>
                                    </>
                                ) : null}
                                {product.railCompatibility && product.railCompatibility.length > 0 ? (
                                    <>
                                        <p style={{ "maxWidth": "300px" }}>Fits:</p>
                                        <ul>
                                            {product.railCompatibility.map((rail, index) => {
                                                return(
                                                    <li key={index + 40}>{rail}</li>
                                                );
                                            })}
                                        </ul>
                                    </>
                                ) : null}
                                {product.stock ? (
                                    <p style={{ "marginTop": "0px", "marginBottom": "0px" }}>In Stock</p>
                                ) : (
                                    <p style={{ "marginTop": "0px", "marginBottom": "0px", "color": "red" }}>Out of Stock</p>
                                )}
                                <div style={isMobile ? {} : { "display": "flex"}}>
                                    <div>
                                        <p><span className="required-star">*</span>Quantity</p>
                                        <Quantity updateParent={handleChange} remove={false} quantity={newItem.quantity} />
                                        <div style={{ "width": "100%" }} className="form-error">{orderErrors.quantityError}</div>
                                    </div>
                                    <div style={isMobile ? {} : { "marginLeft": "32px" }}>
                                        <p><span className="required-star">*</span>Color</p>
                                        {colorOptions && colorOptions.length > 0 ? (
                                            <select
                                                className="color-input"
                                                name="color"
                                                value={newItem.color}
                                                onChange={(e) => handleChange(e)}
                                                id={`color-selector`}
                                            >
                                                <option value="" disabled defaultValue hidden>Choose a Color</option>
                                                {colorOptions}
                                            </select>
                                        ) : (
                                            <select>
                                                <option value="" disabled defaultValue hidden>Choose a Color</option>
                                            </select>
                                        )}
                                        <div style={{ "width": "100%" }} className="form-error">{orderErrors.colorError}</div>
                                    </div>
                                    {caliberOptions ? (
                                        <div style={isMobile ? {} : { "marginLeft": "32px" }}>
                                            <p><span className="required-star">*</span>Caliber</p>
                                            {caliberOptions && caliberOptions.length > 0 ? (
                                                <select
                                                    className="color-input"
                                                    name="caliber"
                                                    value={newItem.caliber}
                                                    onChange={(e) => handleChange(e)}
                                                    id={`caliber-selector`}
                                                >
                                                    <option value="" disabled defaultValue hidden>Choose a Caliber</option>
                                                    {caliberOptions}
                                                </select>
                                            ) : (
                                                <select>
                                                    <option value="" disabled defaultValue hidden>Choose a Caliber</option>
                                                </select>
                                            )}
                                            <div style={{ "width": "100%" }} className="form-error">{orderErrors.caliberError}</div>
                                        </div>
                                    ) : null}
                                    {typeOptions ? (
                                        <div style={isMobile ? {} : { "marginLeft": "32px" }}>
                                            <p><span className="required-star">*</span>Type</p>
                                            {typeOptions && typeOptions.length > 0 ? (
                                                <select
                                                    className="color-input"
                                                    name="type"
                                                    value={newItem.type}
                                                    onChange={(e) => handleChange(e)}
                                                    id={`caliber-selector`}
                                                >
                                                    <option value="" disabled defaultValue hidden>Choose a Type</option>
                                                    {typeOptions}
                                                </select>
                                            ) : (
                                                <select>
                                                    <option value="" disabled defaultValue hidden>Choose a Type</option>
                                                </select>
                                            )}
                                            <div style={{ "width": "100%" }} className="form-error">{orderErrors.typeError}</div>
                                        </div>
                                    ) : null}
                                </div>
                                <button 
                                    style={{ "marginTop": "32px" }} 
                                    type="submit" 
                                    disabled={disableAddToCart} 
                                    className="w3-button w3-padding-large w3-border button-shadow"
                                    id="add-to-cart-button"
                                >
                                    {product && product.hasOwnProperty("preorder") && product.preorder ? (
                                        <b>PREORDER</b>
                                    ) : (
                                        <b>ADD TO CART</b>
                                    )}
                                </button>
                                <div style={{ "width": "100%" }} className="form-error">{orderError}</div>
                                <div style={{ "width": "100%", "marginTop": "16px", "marginLeft": "2px", "color": "green" }} id="add-to-cart-message" className="form-error">{addedToCart}</div>
                            </form>
                        </div>
                    </div>
                    <div className="w3-content" style={{ "maxWidth": "600px", "marginTop": "48px" }}>
                        {product.bottom && product.bottom.description ? (
                            <div>
                                <p style={{ "textAlign": "justify" }}>{product.bottom.description}</p>
                            </div>
                        ) : null}
                        {product.subdescriptionLines && product.subdescriptionLines.length > 0 ? (
                            <div>
                                {product.subdescriptionLines.map((line) => {
                                    return(
                                        <p style={{ "margin": "0px" }}>{line}</p>
                                    )
                                })}
                            </div>
                        ) : null}
                        {product.subdescription ? (
                            <div>
                                <p>{product.subdescription}</p>
                            </div>
                        ) : null}
                        {product.bottom && product.bottom.systemComponents ? (
                            <div style={{ "marginTop": "28px", "marginBottom": "32px" }}>
                                <h3 style={{ "marginTop": "0px" }}>System Components</h3>
                                <img src={product.bottom.systemComponents} style={{ "maxWidth": "100%" }} alt="QD Swivel Component Diagram" />
                            </div>
                        ) : null}
                        {product.additionalDescription ? (
                            <div>
                                <p style={{ "textAlign": "justify" }}>{product.additionalDescription.pretext}</p>
                                <ul>
                                    {product.additionalDescription.bullets.map((desc, index) => {
                                        if(typeof desc === "object") {
                                            return(
                                                <li key={index + 60} style={{ "marginTop": "6px" }}>
                                                    {Object.keys(desc)[0]}
                                                    {desc[Object.keys(desc)[0]].hasOwnProperty("subBullets") && desc[Object.keys(desc)[0]].subBullets ? (
                                                        <ul>
                                                            {desc[Object.keys(desc)[0]].subBullets.map((subDesc, subIndex) => {
                                                                return(
                                                                    <li key={(index * 200) + (subIndex + 80)} style={{ "marginTop": "6px" }}>
                                                                        {subDesc}
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    ) : null}

                                                </li>
                                            );
                                        } else {
                                            return(
                                                <li key={index + 60} style={{ "marginTop": "6px" }}>
                                                    {desc}
                                                </li>
                                            );
                                        }
                                    })}
                                </ul>
                            </div>
                        ) : null}
                        {product.bottom && product.bottom.science ? (
                            <div>
                                <h3>{product.bottom.science.title}</h3>
                                {Object.keys(product.bottom.science).map((key, index) => {
                                    if(key !== "title") {
                                        return(<p key={index + 50} style={{ "textAlign": "justify" }}>{product.bottom.science[key]}</p>);
                                    } else {
                                        return(null);
                                    }
                                })}
                            </div>
                        ) : null}
                        {product.bottom && product.bottom.features ? (
                            <div>
                                <h3>Features:</h3>
                                <ul>
                                    {product.bottom.features.map((feature, index) => {
                                        return(<li key={index + 60} style={{ "marginTop": "6px" }}>{feature}</li>);
                                    })}
                                </ul>
                            </div>
                        ) : null}
                        {product.bottom && product.bottom.benefits ? (
                            <div>
                                <h3>Key Benefits:</h3>
                                <div>
                                    {Object.keys(product.bottom.benefits).map((key, index) => {
                                        return(
                                            <div key={index + 70}>
                                                <h4>{product.bottom.benefits[key].name}</h4>
                                                <ul>
                                                    {product.bottom.benefits[key].items.map((item, tIndex) => {
                                                        return(
                                                            <li key={tIndex + (80 * index)}>{item}</li>
                                                        )
                                                    })}
                                                </ul>
                                                {product.bottom.benefits[key].followOn ? (
                                                    <p>{product.bottom.benefits[key].followOn}</p>
                                                ) : null}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        ) : null}
                        <div className="w3-justify">
                            {product.compatibility ? (
                                <>
                                    <h3>Compatibility</h3>
                                    <p>{product.compatibility}</p>                            
                                </>
                            ) : null}
                            {product.materials ? (
                                <>
                                    <h3>Materials</h3>
                                    <p>{product.materials}</p>
                                </>
                            ) : null}
                            {product.features ? (
                                <>
                                    <h3>Features</h3>
                                    <p>{product.features}</p>
                                </>
                            ) : null}
                        </div>
                    </div>
                    {product.hasOwnProperty("instructions") && product.instructions ? (
                        <div className="w3-content" style={{ "maxWidth": "600px", "marginTop": "48px" }}>
                            <h3 id={`${product.name}-instructions`}>Instructions</h3>
                            {product.instructions && product.instructions.hasOwnProperty("steps") && product.instructions.steps ? (
                                <div>
                                    <ol>
                                        {product.instructions.steps.map((step, index) => {
                                            let stepString = step.split(`${index + 1}. `)[1];
                                            return(<li key={index + 80} style={{ "marginTop": "12px" }}>{stepString}</li>);
                                        })}
                                    </ol>
                                </div>
                            ) : null}
                            {product.instructions && Array.isArray(product.instructions) && product.instructions.length > 0 ? (
                                <div>
                                    <ol>
                                        {Object.keys(product.instructions[0]).map((heading, hIndex) => {
                                            let headingString = heading.split(`${hIndex + 1}. `)[1];
                                            return(
                                                <React.Fragment key={hIndex + 90}>
                                                    <h4 style={{ "marginTop": "12px" }}><b>{headingString}</b></h4>
                                                    <div style={{ "marginLeft": "32px" }}>
                                                    <ol>
                                                        {product.instructions[0][heading].map((step, index) => {
                                                            let stepString = step.split(`${index + 1}. `)[1];
                                                            return(<li key={index + 80} style={{ "marginTop": "12px" }}>{stepString}</li>);
                                                        })}
                                                    </ol>
                                                    </div>
                                                </React.Fragment>
                                            );
                                        })}
                                    </ol>
                                </div>
                            ) : null}
                        </div>
                    ) : null}
                    <div className="w3-content" style={{ "maxWidth": "600px", "marginTop": "48px" }}>
                        {relatedItems && relatedItems.length > 0 ? (
                            <React.Fragment>
                                <h1 style={{ "marginBottom": "32px", "marginLeft": "16px" }}>Related Items:</h1>
                                <div className="w3-container w3-row">
                                    <ProductContainer isMobile={isMobile} products={relatedItems} defaultproductSize={"200px"} />
                                </div>
                            </React.Fragment>
                        ) : null}
                    </div>
                </div>
            </>
        );
    } else {
        return(
            <Error text={"that product could not be found"} />
        )
    }
}

SingleProduct.propTypes = {
    isMobile: PropTypes.bool,
    products: PropTypes.array
};  

export default SingleProduct;