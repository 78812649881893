import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    // Google Analytics Page Tracking
    window.gtag('send', 'page_view', {
      page_location: `${process.env.REACT_APP_BASE_URL}${location.pathname}`,
      page_path: location.pathname            
    });
  }, [location]);
};

export default usePageTracking;