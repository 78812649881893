import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';
import { addNewDistributor, createDeepClone, getAllDistributors, getUnique, setObjectFunc } from "../Utils/Utils";
import Loading from "./Loading";
import FormInput from "../components/FormInput";
import RemovalButton from "../components/RemovalButton";

const defaultPriceListItem = {
    "name": "",
    "productId": "",
    "price": "",
    "minQuantity": ""
};

const defaultDistributorInfo = {
    "name": "",
    "storeCode": "", 
    "priceList": [{ ...defaultPriceListItem }]
};

function AddDistributor({ authenticated, currentProfile, edit, isAdmin, isMobile, products }) {
    const [productNamesDropdown, setProductNamesDropdown] = useState(null);
    const [distributorInfo, setDistributorInfo] = useState(createDeepClone(defaultDistributorInfo));
    const [addDistributorSuccess, setAddDistributorSuccess] = useState('');
    const [addDistributorError, setAddDistributorError] = useState('');
    const [distributors, setDistributors] = useState(null);
    const [distributorNames, setDistributorNames] = useState(null);
    const [modifyName, setModifyName] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if(edit) {
            document.title = 'Edit a Distributor';
        } else {
            document.title = 'Add a Distributor';
        }
    });

    useEffect(() => {
        if(!currentProfile && authenticated) {
            navigate("/login/add_distributor");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentProfile]);

    useEffect(() => {
        if(products && products.length > 0) {
            let dropdownItems = getUnique(products, "name");
            setProductNamesDropdown(dropdownItems);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products]);

    useEffect(() => {
        if(edit && isAdmin && products && products.length > 0) {
            getAllDistributors().then((response) => {
                // console.log(response);
                if(response && response.length > 0) {
                    let distributors = response.map((distributor) => {
                        let formattedDistributor = { "name": distributor.name, "storeCode": distributor.storeCode, "oldStoreCode": distributor.storeCode };
                        let priceList = '';
                        if(distributor.hasOwnProperty("priceList") && distributor.priceList) {
                            let productIds = Object.keys(distributor.priceList);
                            priceList = productIds.map((productId) => {
                                let priceListItem = distributor.priceList[productId];
                                let product = products[products.findIndex((product) => product.productId === productId)];
                                let newPriceListItem = { "productId": productId, "price": priceListItem.price, "name": product.name };
                                if(priceListItem.hasOwnProperty("minQuantity") && priceListItem.minQuantity) {
                                    Object.assign(newPriceListItem, { "minQuantity": priceListItem.minQuantity });
                                }
                                return newPriceListItem;
                            });
                        }
                        if(priceList) {
                            Object.assign(formattedDistributor, { priceList });
                        }
                        return formattedDistributor;
                    });
                    // console.log(distributors);

                    let dropdownItems = getUnique(distributors, "name");

                    setDistributors(distributors);
                    setDistributorNames(dropdownItems);
                }
            });
        }
    }, [edit, isAdmin, products]);

    const handleChange = (e, type, index) => {
        let updateObject = {};
        if(typeof index === "number") {
            let objectToBeUpdated = distributorInfo.priceList[index];
            if(e && e.hasOwnProperty("target")) {
                Object.assign(objectToBeUpdated, { [e.target.name]: e.target.value });
            } else if (e) {
                Object.assign(objectToBeUpdated, { ...e });
            } else {
                distributorInfo.priceList.splice(index, index + 1);
            }
            updateObject = { "priceList": distributorInfo.priceList };
        } else if (e && e.hasOwnProperty("target")) {
            updateObject = { [e.target.name]: e.target.value };
        } else {
            if(type === "distributor") {
                updateObject = { ...distributors[distributors.findIndex((distributor) => distributor.name === e.name)] };
            } else {
                updateObject = { ...e };
            }
        }
        // console.log("Update Object:", updateObject);

        setObjectFunc(setDistributorInfo, updateObject);
    };

    const addDistributor = async (distributorInfo) => {
        if(distributorInfo) {
            let vDistributors = distributors;
            if(!vDistributors) {
                vDistributors = await getAllDistributors();
                setDistributors(vDistributors);
            }
            let isValid = true;
            let validityError = '';
            // Validate that name and store codes do not match other distributors
            vDistributors.forEach((distributor) => { 
                if(distributor.storeCode === distributorInfo.storeCode || distributor.name === distributorInfo.name) {
                    validityError = distributor.storeCode === distributorInfo.storeCode ? "Store with that code already exists." : "Store with that name already exists.";
                    isValid = false;
                }
            });

            if(!isValid) {
                setAddDistributorError(validityError);
                return false;
            }
            let distributorInfoClone = createDeepClone(distributorInfo);
            if(distributorInfo.hasOwnProperty("priceList") && distributorInfo.priceList && distributorInfo.priceList.length > 0) {
                let newPriceList = {};
                distributorInfo.priceList.forEach((item) => {
                    if(item && item.hasOwnProperty("price") && item.price && item.hasOwnProperty("name") && item.name) {
                        let productId = products[products.findIndex((product) => product.name === item.name)].productId;
                        let nestedObject = { "price": parseFloat(item.price) };
                        if(item.hasOwnProperty("minQuantity") && item.minQuantity) {
                            Object.assign(nestedObject, { "minQuantity": parseInt(item.minQuantity) });
                        }
                        Object.assign(newPriceList, { [productId]: nestedObject });
                    }
                });
                if(Object.keys(newPriceList).length > 0) {
                    Object.assign(distributorInfoClone, { "priceList": newPriceList });
                }
                
            }
            if(distributorInfo.hasOwnProperty("oldStoreCode") && distributorInfo.oldStoreCode && distributorInfo.oldStoreCode === distributorInfo.storeCode) {
                delete distributorInfoClone.oldStoreCode;
            }
            // console.log(distributorInfoClone);

            let result = await addNewDistributor(distributorInfoClone);
            if(result) {
                if(edit) {
                    setAddDistributorSuccess("Distributor Successfully Modified!");
                    setModifyName(false);
                } else {
                    setAddDistributorSuccess("Distributor Successfully Added!");
                }
            } else {
                if(edit) {
                    setAddDistributorError("Failed to modify Distributor please refresh the page and try again.");
                } else {
                    setAddDistributorError("Failed to add Distributor please refresh the page and try again.");
                }
            }
        }
    };

    if(currentProfile && authenticated && isAdmin) {
        return (
            <div style={{ "minHeight":"92vh", "display": "flex", "justifyContent": "center" }} className="w3-content w3-container w3-padding-64" id="about">
                <div>
                    {edit ? (
                        <h1>Edit a Distributor</h1>
                    ) : (
                        <h1>Add a Distributor</h1>
                    )}
                    <div style={{ "width":"100%" }}>
                        {edit && !modifyName ? (
                            <div style={{ "display": "flex" }}>
                                <FormInput dropDownOptions={distributorNames} name="name" object={distributorInfo} placeholder={"Distributor Name"} updateParent={handleChange} value={distributorInfo.name} type="dropdown" resultType={"distributor"} />
                                {distributorInfo && distributorInfo.name ? (
                                    <button
                                        className="hidden-btn"
                                        style={{ "color": "blue", "marginLeft": "8px" }}
                                        onClick={() => {
                                            setModifyName(true);
                                        }}
                                    >
                                        edit name
                                    </button>
                                ) : null}
                            </div>
                        ) : (
                            <input 
                                className="w3-input w3-border"
                                style={{ "width":"100%", "marginTop": "32px" }}
                                placeholder="Distributor Name"
                                name="name"
                                value={distributorInfo.name}
                                type="text"
                                onChange={(e) => handleChange(e)}
                            />
                        )}
                        <input 
                            className="w3-input w3-border"
                            style={{ "width":"100%", "marginTop": "32px" }}
                            placeholder="Distributor Store Code"
                            name="storeCode"
                            maxLength="64"
                            value={distributorInfo.storeCode}
                            type="text"
                            onChange={(e) => handleChange(e)}
                        />
                        <h3>Price List</h3>
                        {distributorInfo && distributorInfo.priceList.length > 0 && productNamesDropdown && productNamesDropdown.length > 0 ? (
                            <React.Fragment>
                                {distributorInfo.priceList.map((item, index) => {
                                    return(
                                        <div key={index * 101} style={{ "display": "flex", "marginTop": "32px" }}>
                                            <div style={{ "marginRight": "32px"}}>
                                                <FormInput arrayIndex={index} dropDownOptions={productNamesDropdown} name="name" object={item} placeholder={"Product"} updateParent={handleChange} value={item.name} type="dropdown" />
                                            </div>
                                            <div>
                                                <input 
                                                    className="w3-input w3-border"
                                                    style={{ "width":"100%" }}
                                                    placeholder="Price"
                                                    name="price"
                                                    value={item.price}
                                                    type="number"
                                                    onChange={(e) => handleChange(e, '', index)}
                                                />
                                                <input 
                                                    className="w3-input w3-border"
                                                    style={{ "width":"100%", "marginTop": "32px" }}
                                                    placeholder="Minimum Quantity"
                                                    name="minQuantity"
                                                    value={item.minQuantity}
                                                    type="number"
                                                    onChange={(e) => handleChange(e, '', index)}
                                                />
                                            </div>
                                            <div>
                                                {index === 0 ? null : <RemovalButton style={{ "paddingBottom": "10px" }} deleteObject={index} updateParent={(e) => handleChange('', '', e)} />}
                                            </div>
                                        </div>
                                    );
                                })}
                                <div style={{ "width": "100%" }}>
                                    <button
                                        className="add-materials"
                                        style={{ "margin": "0px", "marginTop": "16px" }}
                                        onClick={ (e) => {
                                            e.preventDefault();
                                            handleChange({ "priceList": [...distributorInfo.priceList, defaultPriceListItem]});
                                        }}
                                    >
                                        Add Item
                                    </button>
                                </div>
                            </React.Fragment>
                        ) : null}
                    </div>
                    <div style={{ "width": "100%", "textAlign": "right" }}>
                        {edit ? (
                            <button style={{ "marginTop": "32px" }} className="w3-button w3-padding-large w3-border" disabled={false} onClick={() => { addDistributor(distributorInfo); }}><b>Modify Distributor</b></button>
                        ) : (
                            <button style={{ "marginTop": "32px" }} className="w3-button w3-padding-large w3-border" disabled={false} onClick={() => { addDistributor(distributorInfo); }}><b>Add Distributor</b></button>
                        )}
                    </div>
                    <div style={{ "width": "100%", "textAlign": "right", "marginTop": "16px", "color": "green" }} className="form-error">{addDistributorSuccess}</div>
                    <div style={{ "width": "100%", "textAlign": "right", "marginTop": "16px" }} className="form-error">{addDistributorError}</div>
                </div>
            </div> 
        );
    } else {
        return(
            <>
                {currentProfile ? (
                    <Loading isMobile={isMobile} override="Loading..." />
                ) : (
                    <Loading isMobile={isMobile} override="Please sign in to access this page." />
                )}
            </>
        );
    }
}

AddDistributor.propTypes = {
    authenticated: PropTypes.bool,
    isAdmin: PropTypes.bool,
    isMobile: PropTypes.bool
};  

export default AddDistributor;