import React from "react";

function AnimatedEllipsis({ style }) {
    return (
        <span style={{ "marginLeft": "4px", ...style }}>
            <span className="dot1">.</span>
            <span className="dot2">.</span>
            <span className="dot3">.</span>
        </span>
    );
};

export default AnimatedEllipsis;