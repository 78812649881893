import auth0 from "auth0-js";
import jwtDecode from "jwt-decode";

export default class Auth {
    webAuth = new auth0.WebAuth({
        domain: process.env.REACT_APP_AUTH0_DOMAIN,
        clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
        redirectUri: `${process.env.REACT_APP_BASE_URL}/callback`,
        audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`,
        issuer: `https://${process.env.REACT_APP_AUTH0_DOMAIN}`,
        responseType: "token id_token",
        scope: "openid email profile",
    });

    constructor() {
        //lets you use login method in other pages
        this.login = this.login.bind(this);
    }
    
    login(location) {
        if(location && location.split("/").length > 1 && location.split("/")[location.split("/").length - 1] === "register") {
            this.webAuth.authorize({ action: "signup" });
        } else {
            this.webAuth.authorize();
        }
    }
    
    handleAuthentication() {
        try {
            this.webAuth.parseHash((err, authResults) => {
                if(authResults && authResults.accessToken && authResults.idToken) {
                    this.accessToken = authResults.accessToken;
                    this.idToken = authResults.idToken;
                    this.expiresAt = authResults.expiresIn * 1000 + new Date().getTime();
                    window.location.hash = "";
                    return true;
                } else if (err) {
                    // console.log(err);
                    return false;
                }
            });
          return true;
        } catch (error) {
            console.log(error);
            return false;
        }
    }
    
    isAuthenticated() {
        return new Date().getTime() < this.expiresAt;
    }
    
    async renewTokens() {
        return new Promise((resolve) => {
            this.webAuth.checkSession({}, (err, authResult) => {
                if (authResult && authResult.accessToken && authResult.idToken) {
                    this.accessToken = authResult.accessToken;
                    this.idToken = authResult.idToken;
                    this.expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
                    resolve(true)
                } else if (err) {
                    console.log(err);
                    resolve(false);
                }
            });
        });
    }
    
    getProfile() {
        if (this.idToken) {
            return jwtDecode(this.idToken);
        } else {
            return false;
        }
    }
    
    logout() {
        localStorage.removeItem("cookie_status");
        setTimeout(() => {
            window.location.href = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/v2/logout?returnTo=${process.env.REACT_APP_BASE_URL}/&client_id=${process.env.REACT_APP_AUTH0_CLIENT_ID}`;
        }, 1000);
    }
}
