import React from "react";
import PropTypes from 'prop-types';
import { Chart } from "react-chartjs-2";
/* eslint-disable no-unused-vars */
import { Chart as ChartJS } from 'chart.js/auto';
/* eslint-enable no-unused-vars */

function Charts({ isMobile, type, data, options }) {
    /* Example Arguments for each parameter:
        type = 'line'
        data = {
            labels: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            datasets: [
                {
                    label: 'Revenue',
                    data: [637507, 1650497, 2105770, 3072847, 3854158, 4977068, 5801991, 6445464, 7199314, 8282879, 8832589, 9762319],
                    borderColor: '#b69393',
                    backgroundColor: '#b69393',
                },
                {
                    label: 'Revenue using the Service',
                    data: [669382.35, 1733021.85, 2211058.5, 3226489.35, 4046865.9000000004, 5225921.4, 6092090.55, 6767737.2, 7559279.7, 8697022.950000001, 9274218.450000001, 10250434.950000001],
                    borderColor: '#99c77b',
                    backgroundColor: '#99c77b',
                }
            ]
        }
        options = { responsive: true, plugins: { legend: { position: 'top' }, title: { display: true, text: 'Trailing Twelve Months Revenue' } } }
    */
    return(
        <div style={isMobile ? { "height": "300px", "width": "auto" } : {}}>
            {type && data && options ? (
                <Chart
                    type={type}
                    data={data}        
                    options={options}
                />
            ) : null}
        </div>
    );
}

Charts.propTypes = {
    isMobile: PropTypes.bool,
    type: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired,
    options: PropTypes.object.isRequired
};

export default Charts;
