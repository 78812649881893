import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import Error from "./Error";
import { BsTrash3, BsTrash3Fill } from "react-icons/bs";
import HoverButton from "../components/HoverButton";
import { GrStatusGoodSmall } from "react-icons/gr";
import { RiFileEditFill, RiFileEditLine } from "react-icons/ri";
import Modal from "../components/Modal";
import { BsPlusSquare, BsPlusSquareFill } from "react-icons/bs";
import { createDeepClone, getObjectValue } from "../Utils/Utils";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "./Loading";
import { v4 as uuidv4 } from 'uuid';
import { deleteImageDB } from "../Utils/ImageDBUtils";

const locationDisplayKeys = ["status", "nickname", ["lat", "lon"]];
const locationForms = ["Nickname", "Latitude", "Longitude", "Status"];
const locationFormNames = ["nickname", "lat", "lon", "status"];
const headers = [{ "name": "Status", "display": true }, { "name": "Nickname", "display": true }, { "name": "Location (Lat/Lon)", "display": true }, { }];
const tempKey = "tbd";

function Locations({ currentProfile, isMobile, setCurrentProfile }) {
    const [allLocations, setAllLocations] = useState(null);
    const [updatedLocations, setUpdatedLocations] = useState(null);
    const [updateLocation, setUpdateLocation] = useState(null);
    const [removeLocation, setRemoveLocation] = useState(null);
    const [updatedLocation, setUpdatedLocation] = useState(false);
    const [maxLocations, setMaxLocations] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);

    let navigate = useNavigate();
    let { sub } = useParams();

    useEffect(() => {
        document.title = 'Deer Vision Locations';
    });

    const handleChange = (e) => {
        e.preventDefault();
        setUpdatedLocation(true);

        let updatedLocationsClone = { ...updatedLocations };
        let locationToEdit = updatedLocations[updateLocation];
        Object.assign(locationToEdit, { [e.target.name]: e.target.value });

        setUpdatedLocations(updatedLocationsClone);
    };

    const saveLocation = () => {
        let updatedLocationsClone = createDeepClone(updatedLocations);
        if(updateLocation && !getObjectValue(updatedLocations, `${updatedLocations[updateLocation].lat} ${updatedLocations[updateLocation].lon}`)) {
            let newlatlonObject = createDeepClone(updatedLocations[updateLocation]);
            if(getObjectValue(newlatlonObject, "station")) {
                delete newlatlonObject.station;
            }
            Object.assign(updatedLocationsClone, { [`${newlatlonObject.lat} ${newlatlonObject.lon}`]: newlatlonObject });
            delete updatedLocationsClone[updateLocation];
        }
        // console.log(updatedLocationsClone);

        setAllLocations(updatedLocationsClone);
        setUpdatedLocation(false);
        setUpdateLocation(null);
        setCurrentProfile({ ...currentProfile, "locations": updatedLocationsClone });
    };

    const deleteLocation = () => {
        let allLocationsClone = createDeepClone(allLocations);
        delete allLocationsClone[removeLocation];

        // Remove LabeledData Key from Cloud Object
        let labeledDataClone = createDeepClone(currentProfile.labeledData);
        let labeledDataId = currentProfile.locations[removeLocation].labeledDataId;
        if(getObjectValue(currentProfile.labeledData, labeledDataId)) {
            delete labeledDataClone[labeledDataId];
        }

        //Remove Indexed DB related to deleted location
        deleteImageDB(labeledDataId);
        
        setCurrentProfile({ ...currentProfile, "locations": allLocationsClone, "labeledData": labeledDataClone });

        if(Object.keys(allLocationsClone).length === 0) {
            allLocationsClone = null;
        }

        setAllLocations(allLocationsClone);
        setRemoveLocation(null);
    };

    const addLocation = () => {
        if(!allLocations || (allLocations && Object.keys(allLocations).length < maxLocations)) {
            let defaultLocationObject = { ...currentProfile.defaultLocation };
            let newUpdatedLocations = { [tempKey]: { ...defaultLocationObject, "labeledDataId": uuidv4() } };
            if(allLocations) {
                newUpdatedLocations = { ...allLocations, ...newUpdatedLocations };
            }
            setUpdatedLocations(newUpdatedLocations);

            setUpdateLocation(tempKey);
        }
    };

    useEffect(() => {
        if(currentProfile && getObjectValue(currentProfile, "locations")) {
            setAllLocations(createDeepClone(currentProfile.locations));
            setUpdatedLocations(createDeepClone(currentProfile.locations));
        }
    }, [currentProfile]);

    // Set maximum location amount based on tier
    useEffect(() => {
        if(currentProfile) {
            setMaxLocations(12);
        }
    }, [currentProfile]);

    const viewLocationData = (location) => {
        let newSelectedLocation = location ? location : selectedLocation;
        if(newSelectedLocation) {
            navigate(`/deer_vision/${currentProfile.sub}/locations/${newSelectedLocation}`);
        }
    };

    if (currentProfile && currentProfile.sub === sub) {
        const width = isMobile ? `${(100 / headers.length) - 2}%` : `${100 / (headers.length + 2)}%`;
        return (
            <div style={{ "minHeight":"92vh" }} className="w3-content w3-container w3-padding-64" id="about">
                <h3>Locations</h3>
                <p>These are all of the locations that you would like to associate image and weather data with.</p>
                {updateLocation ? (
                    <Modal style={isMobile ? {} : { "width": "450px" }} closeButton={true} fadeIn={0.25} isMobile={isMobile} onClose={() => setUpdateLocation(null)}>
                        <h4>Edit Location</h4>
                        {locationForms.map((locationForm, index) => {
                            let locationToEdit = updatedLocations[updateLocation];
                            let name = locationFormNames[index];
                            let value = locationToEdit[name];
                            if(name === "status") {
                                return(
                                    <div key={locationForm} style={{ "display": "flex", "marginTop": "16px" }}>
                                        <input
                                            key={name + (value === "active")}
                                            style={{ "marginRight":"6px" }}
                                            type="radio"
                                            checked={value === "active"}
                                            name={name}
                                            value={"active"}
                                            onChange={(e) => { handleChange(e); }}
                                        />
                                        <label style={{ "marginRight": "16px" }}>
                                            Active
                                        </label>
                                        <input
                                            key={name + (value === "inactive")}
                                            style={{ "marginRight":"6px" }}
                                            type="radio"
                                            checked={value === "inactive"}
                                            name={name}
                                            value={"inactive"}
                                            onChange={(e) => { handleChange(e); }}
                                        />
                                        <label>
                                            Inactive
                                        </label>
                                    </div>
                                );
                            } else {
                                return(
                                    <React.Fragment key={locationForm}>
                                        <p><b>{locationForm}</b></p>
                                        <input
                                            style={{ "width": "100%", "padding": "6px", "border": "2px solid #e8eaf6" }}
                                            name={name}
                                            value={value}
                                            onChange={(e) => handleChange(e)}
                                        />
                                    </React.Fragment>
                                );
                            }
                        })}
                        <button style={{ "marginTop": "16px", "width": "100%" }} className="main-button" onClick={() => saveLocation()} disabled={!updatedLocation}>
                            Save Location
                        </button>
                    </Modal>
                ) : removeLocation ? (
                    <Modal style={isMobile ? {} : { "width": "450px" }} closeButton={true} fadeIn={0.25} isMobile={isMobile} onClose={() => setRemoveLocation(null)}>
                        <h4>Delete Location</h4>
                        <p>Are you sure you want to delete this location?</p>
                        <button style={{ "marginTop": "16px", "width": "100%" }} className="main-button" onClick={() => deleteLocation()}>
                            Delete Location
                        </button>
                    </Modal>
                ) : null}
                {allLocations && Object.keys(allLocations).length > 0 ? (
                    <React.Fragment>
                        <div style={{ "width": "100%", "display": "flex"}}>
                            {headers.map((header) => {
                                if(header.display) {
                                    return(
                                        <div key={header.name} style={{ "width": width, "marginLeft": "32px" }}>
                                            <p>{header.name}</p>
                                        </div>
                                    );
                                } else {
                                    return(null);
                                }
                            })}
                        </div>
                        {Object.keys(allLocations).map((latlonKey) => {
                            let divStyle = { "paddingTop": "32px", "paddingBottom": "32px", "marginBottom": "16px", "border": "2px solid white", "borderRadius": "8px", "height": "113px", "display": "flex", "alignItems": "center" };
                            let location = allLocations[latlonKey];
                            if(latlonKey === selectedLocation) {
                                divStyle = { ...divStyle, "border": "2px solid black" };
                            }
                            return(
                                <div 
                                    onClick={() => { 
                                        if(selectedLocation === latlonKey) {
                                            setSelectedLocation(null);
                                        } else {
                                            setSelectedLocation(latlonKey);
                                        }
                                    }}
                                    onDoubleClick={() => {
                                        viewLocationData(latlonKey);
                                    }}
                                    key={JSON.stringify(location)} 
                                    style={divStyle} 
                                    className="w3-card" 
                                >
                                    <div style={{ "display": "flex", "width": "100%", "alignItems": "center" }}>
                                        {isMobile && selectedLocation === latlonKey ? (
                                            <div style={{ "width": width, "marginLeft": "32px" }}>
                                                <div style={{ "display": "flex" }}>
                                                    {location.status === 'active' ? (
                                                        <GrStatusGoodSmall style={{ "color": "green", "marginTop": "4px", "marginRight": "4px" }} />
                                                    ) : (
                                                        <GrStatusGoodSmall style={{ "color": "red", "marginTop": "4px", "marginRight": "4px" }} />
                                                    )}
                                                    <p style={{ "margin": "0px" }}>
                                                        {location.status.charAt(0).toUpperCase() + location.status.slice(1)}
                                                    </p>
                                                </div>
                                            </div>
                                        ) : (
                                            <React.Fragment>
                                                {locationDisplayKeys.map((locationDisplayKey) => {
                                                    return(
                                                        <div key={locationDisplayKey} style={{ "width": width, "marginLeft": "32px" }}>
                                                            {typeof locationDisplayKey === "object" ? (
                                                                <React.Fragment>
                                                                    {locationDisplayKey.map((key) => {
                                                                        return(
                                                                            <p key={key} style={{ "margin": "0px" }}>
                                                                                {location[key]}
                                                                            </p>
                                                                        );
                                                                    })}
                                                                </React.Fragment>
                                                            ) : locationDisplayKey === "status" ? (
                                                                <div style={{ "display": "flex" }}>
                                                                    {location[locationDisplayKey] === 'active' ? (
                                                                        <GrStatusGoodSmall style={{ "color": "green", "marginTop": "4px", "marginRight": "4px" }} />
                                                                    ) : (
                                                                        <GrStatusGoodSmall style={{ "color": "red", "marginTop": "4px", "marginRight": "4px" }} />
                                                                    )}
                                                                    <p style={{ "margin": "0px" }}>
                                                                        {location[locationDisplayKey].charAt(0).toUpperCase() + location[locationDisplayKey].slice(1)}
                                                                    </p>
                                                                </div>
                                                            ) : (
                                                                <p style={{ "margin": "0px" }}>
                                                                    {location[locationDisplayKey]}
                                                                </p>
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                            </React.Fragment>
                                        )}
                                        {!isMobile || (isMobile && selectedLocation === latlonKey) ? (
                                            <React.Fragment>
                                                <div style={{ "width": width, "display": "flex", "justifyContent": "center" }}>
                                                    <HoverButton
                                                        normalIcon={<RiFileEditLine style={{ "transform": "scale(2.0)"}} />} 
                                                        hoverIcon={<RiFileEditFill style={{ "transform": "scale(2.0)"}} />}
                                                        updateParent={() => setUpdateLocation(latlonKey)}
                                                        title={"Edit Location"}
                                                    />
                                                </div>
                                                <div style={{ "width": width, "display": "flex", "justifyContent": "center" }}>
                                                    <HoverButton
                                                        normalIcon={<BsTrash3 style={{ "transform": "scale(2.0)"}} />} 
                                                        hoverIcon={<BsTrash3Fill style={{ "transform": "scale(2.0)"}} />}
                                                        updateParent={() => { setRemoveLocation(latlonKey); }}
                                                        title={"Delete Location"}
                                                    />
                                                </div>
                                            </React.Fragment>
                                        ) : null}
                                    </div>
                                </div>
                            )
                        })}
                    </React.Fragment>
                ) : null}
                <div style={{ "width": "100%", "display": "flex", "justifyContent": "right", "paddingRight": "16px" }}>
                    {allLocations && Object.keys(allLocations).length > 0 ? (
                        <button style={{ "marginTop": "16px" }} className="main-button" onClick={() => { viewLocationData(); }} disabled={!selectedLocation}>
                            View Location Data
                        </button>
                    ) : null}
                    {maxLocations && allLocations && Object.keys(allLocations).length === maxLocations ? (
                        null
                    ) : (
                        <HoverButton 
                            normalIcon={<BsPlusSquare style={{ "transform": "scale(3.0)"}} />}
                            hoverIcon={<BsPlusSquareFill style={{ "transform": "scale(3.0)"}} />}
                            updateParent={() => { addLocation(); }}
                            style={{ "marginTop": "16px", "marginLeft": "32px" }}
                            title={"Add New Location"}
                        />
                    )}
                </div>
            </div>
        );
    } else if(!currentProfile && typeof currentProfile === "boolean") {
        return(
            <Error isMobile={isMobile} type={"login"} />
        );
    } else {
        return(
            <Loading isMobile={isMobile} text={"your locations"}/>
        );
    }
}

Locations.propTypes = {
    isMobile: PropTypes.bool,
    isPage: PropTypes.bool,
    currentProfile: PropTypes.object,
    setCurrentProfile: PropTypes.func.isRequired
};

export default Locations;
