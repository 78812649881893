import React, { useEffect, useState } from "react";
import { triggerSendMessageFunction, validateInput } from "../Utils/Utils";
import PropTypes from 'prop-types';

function InfoForm({ buttonText, hideButton, isMobile, formType, propObject, updateParent }) {
    const [formObject, setFormObject] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        company: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        type: '',
        description: '',
        message: '',
    });
    const [formObjectErrors, setFormObjectErrors] = useState({
        firstNameError: '',
        lastNameError: '',
        phoneError: '',
        emailError: '',
        companyError: '',
        address1Error: '',
        address2Error: '',
        cityError: '',
        stateError: '',
        zipError: '',
        typeError: '',
        descriptionError: '',
        messageError: '',
    });
    const [formError, setFormError] = useState('');
    const [formSuccess, setFormSuccess] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [submitButtonText, setSubmitButtonText] = useState('Submit');

    const onSubmit = async (e) => {
        e.preventDefault();
        setSubmitted(true);
        let submitObject = {
            "firstName": formObject.firstName,
            "lastName": formObject.lastName,
            "phone": formObject.phone,
            "email": formObject.email,
        };
        if(formObject.company) {
            Object.assign(submitObject, { "company": formObject.company });
        }
        if(formObject.address1) {
            Object.assign(submitObject, { "address1": formObject.address1 });
        }
        if(formObject.address2) {
            Object.assign(submitObject, { "address2": formObject.address2 });
        }
        if(formObject.city) {
            Object.assign(submitObject, { "city": formObject.city });
        }
        if(formObject.state) {
            Object.assign(submitObject, { "state": formObject.state });
        }
        if(formObject.zip) {
            Object.assign(submitObject, { "zip": formObject.zip });
        }
        if(formObject.type) {
            Object.assign(submitObject, { "type": formObject.type });
        }
        if(formObject.description) {
            Object.assign(submitObject, { "description": formObject.description });
        }
        if(formObject.message) {
            Object.assign(submitObject, { "message": formObject.message });
        }

        // console.log(submitObject);

        setFormObjectErrors({
            firstNameError: '',
            lastNameError: '',
            phoneError: '',
            emailError: '',
            companyError: '',
            address1Error: '',
            address2Error: '',
            cityError: '',
            stateError: '',
            zipError: '',
            typeError: '',
            descriptionError: '',
            messageError: '',
        });
        setFormError('');
        
        //console.log(this.state.profileDBUpdate);
        //Check validity of input from profile
        /* Validate Object Prototype:
        validateArray = [{
            name: "",
            displayName: "",
            input: "",
            type: "",
            specialConditions: []
        }, ...additional error JSON objects] 
        *Special Condition can also contain array for multiple conditions
        */

        let validateArray = [];
        let keys = Object.keys(submitObject);
        validateArray = await Promise.all(keys.map((key) => {
            let type = '';
            if(key === "phone" || key === "zip") {
            type = "number";
            } else if (key === "type" || key === "description") {
            type = "dropdown";
            } else {
            type = "text";
            }
            let displayName = key;
            if(key === "firstName") {
                displayName = "first name";
            } else if(key === "lastName") {
                displayName = "last name";
            } else if(key === "address1" || key === "address2") {
                displayName = "address";
            } else if(key === "phone") {
                displayName = "phone number";
            } else if(key === "type") {
                displayName = "type of business";
            } else if(key === "description") {
                displayName = "description of your business";
            }
            //Need to add Special Conditions for verifying state based on zipcode,
            let specialConditionsArray = [];
            if(key === "firstName" || key === "lastName") {
                specialConditionsArray.push(`!hasNumber(${submitObject[key]})`);
            } else if(key === "city") {
                specialConditionsArray.push(`!hasNumber(${submitObject[key]})`);
            } else if(key === "state") {
                specialConditionsArray.push(`!hasNumber(${submitObject[key]})`);
                specialConditionsArray.push(`checkLength(${submitObject[key]},2)`);
            } else if(key === "location") {
                specialConditionsArray.push(`checkLength(${submitObject[key]},5)`);
            } else if(key === "email") {
                specialConditionsArray.push(`includes(${submitObject[key]},@)`);
            }
            let validateObject = {
                name: key,
                displayName,
                input: submitObject[key],
                type,
                specialConditions: specialConditionsArray
            }
            return validateObject;
        }));
        // console.log(validateArray);
        
        let errorsArray = await validateInput(validateArray);
        // console.log(errorsArray);
        
        if(errorsArray) {
            if(errorsArray[0]) {
                if(formType === "Shipping") {
                    //Trigger Shipping Rate Estimation
                    updateParent({ "selectRate": true, "shipping": false, "shippingInfo": submitObject, /* "rate": rate */ });
                } else {
                    triggerSendMessageFunction(formType.toLowerCase(), submitObject).then((response) => {
                        if(response) {
                            setFormSuccess('Someone from Vale Outdoors will contact you shortly.');
                        } else {
                            setFormError('Email failed to send, please refresh the page and try again.');
                        }
                    }).catch((error) => {
                        console.log(error);
                        setFormError('Email failed to send, please refresh the page and try again.');
                        return false;
                    });
                }
                // If check is successful send email?
                //NEED CODE FOR SENDING EMAIL
            } else {
                let formObjectErrorsClone = { ...formObjectErrors };
                await errorsArray[1].map((item) => {
                    if(item.errorDisplay) {
                        formObjectErrorsClone = {
                            ...formObjectErrorsClone,
                            [`${item.name}Error`]:  item.errorDisplay
                        };
                        return true;
                    } else {
                        return false;
                    }
                });
                setFormObjectErrors(formObjectErrorsClone);
            } 
        } else {
            setFormError('Please update information in response to found errors');
        }
    };
    
    const handleChange = async (e) => {
        setSubmitted(false);
        // console.log(`${e.target.name}: ${e.target.value}`);
        setFormObject({
            ...formObject,
            [e.target.name]: e.target.value
        });
        if(formType === "AdminShipping") {
            updateParent({ [e.target.name]: e.target.value });
        }
    };

    useEffect(() => {
        if(propObject){
            setFormObject({ ...formObject, ...propObject });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propObject]);

    useEffect(() => {
        if(buttonText) {
            setSubmitButtonText(buttonText);
        }
    }, [buttonText]);

    return (
        <div style={{ "width":"100%" }}>
            <form onSubmit={(e) => onSubmit(e)}>
                <p style={{ "margin": "0px 0px 0px 0px"}}>
                    <span className="required-star">*</span>Name
                </p>
                <div style={isMobile ? {} : { "display": "flex" }}>
                    <div style={{ "width":"100%" }}>
                        <input 
                            className="w3-input w3-border"
                            style={{ "width":"100%" }}
                            placeholder="First Name"
                            name="firstName"
                            value={formObject.firstName}
                            type="text"
                            onChange={(e) => handleChange(e)}
                            id="firstName"
                        />
                        <div style={{ "width": "100%" }} className="form-error">{formObjectErrors.firstNameError}</div>
                    </div>
                    <div style={{ "width":"100%" }}>
                        <input
                            className="w3-input w3-border"
                            style={isMobile ? { "width":"100%", "marginTop": "16px" } : { "width":"100%", "marginLeft": "16px" }}
                            placeholder="Last Name"
                            name="lastName"
                            value={formObject.lastName}
                            type="text"
                            onChange={(e) => handleChange(e)}
                            id="lastName"
                        />
                        <div style={{ "width": "100%" }} className="form-error">{formObjectErrors.lastNameError}</div>
                    </div>
                </div>
                <p style={{ "margin": "16px 0px 0px 0px"}}>
                    <span className="required-star">*</span>Phone
                </p>
                <input
                    className="w3-input w3-border hide-number"
                    style={{ "width":"100%" }}
                    placeholder="Phone Number"
                    name="phone"
                    value={formObject.phone}
                    type="number"
                    onChange={(e) => handleChange(e)}
                    maxLength="10"
                    id="phone"
                />
                <div className="form-error">{formObjectErrors.phoneError}</div>
                <p style={{ "margin": "16px 0px 0px 0px"}}>
                    <span className="required-star">*</span>Email
                </p>
                <input
                    className="w3-input w3-border"
                    style={{ "width":"100%" }}
                    placeholder="Email Address"
                    name="email"
                    value={formObject.email}
                    type="text"
                    onChange={(e) => handleChange(e)}
                    id="email"
                />
                <div className="form-error">{formObjectErrors.emailError}</div>
                {formType !== "Shipping" ? (
                    <>
                        <p style={{ "margin": "16px 0px 0px 0px"}}>
                            Company
                        </p>
                        <input
                            className="w3-input w3-border"
                            style={{ "width":"100%" }}
                            placeholder="Company"
                            name="company"
                            value={formObject.company}
                            type="text"
                            onChange={(e) => handleChange(e)}
                            id="company"
                        />
                        <div className="form-error">{formObjectErrors.companyError}</div>
                    </>
                ) : null}

                {formType === "Contact" || formType === "Shipping" || formType === "AdminShipping" ? (
                    <>
                        <p style={{ "margin": "16px 0px 0px 0px"}}>
                            <span className="required-star">*</span>Address
                        </p>
                        <div style={isMobile ? {} : { "display": "flex" }}>
                            <div style={{ "width":"100%" }}>
                                <input 
                                    className="w3-input w3-border"
                                    style={{ "width":"100%" }}
                                    placeholder="Address Line 1"
                                    name="address1"
                                    value={formObject.address1}
                                    type="text"
                                    onChange={(e) => handleChange(e)}
                                    id="address1"
                                />
                                <div style={{ "width": "100%" }} className="form-error">{formObjectErrors.address1Error}</div>
                            </div>
                            <div style={{ "width":"100%" }}>
                                <input
                                    className="w3-input w3-border"
                                    style={isMobile ? { "width":"100%", "marginTop": "16px" } : { "width":"100%", "marginLeft": "16px" }}
                                    placeholder="Address Line 2"
                                    name="address2"
                                    value={formObject.address2}
                                    type="text"
                                    onChange={(e) => handleChange(e)}
                                    id="address2"
                                />
                                <div style={{ "width": "100%" }} className="form-error">{formObjectErrors.address2Error}</div>
                            </div>
                        </div>
                        <div style={isMobile ? { "marginTop": "16px" } : { "display": "flex", "margin": "16px 0px 0px 0px" }}>
                            <div style={isMobile ? { "width":"100%" } : { "width":"100%", "marginRight": "16px" }}>
                                <input 
                                    className="w3-input w3-border"
                                    style={{ "width":"100%", "marginRight": "16px" }}
                                    placeholder="City"
                                    name="city"
                                    value={formObject.city}
                                    type="text"
                                    onChange={(e) => handleChange(e)}
                                    id="city"
                                />
                                <div style={{ "width": "100%" }} className="form-error">{formObjectErrors.cityError}</div>
                            </div>
                            <div style={isMobile ? { "width":"100%" } : { "width":"100%", "maxWidth": "75px" }}>
                                <input
                                    className="w3-input w3-border"
                                    style={isMobile ? { "width":"100%", "marginTop": "16px" } : { "width":"100%" }}
                                    placeholder="State"
                                    name="state"
                                    value={formObject.state}
                                    type="text"
                                    onChange={(e) => handleChange(e)}
                                    maxLength="2"
                                    id="state"
                                />
                                <div style={{ "width": "100%" }} className="form-error">{formObjectErrors.stateError}</div>
                            </div>
                            <div style={{ "width":"100%" }}>
                                <input
                                    className="w3-input w3-border hide-number"
                                    style={isMobile ? { "width":"100%", "marginTop": "16px" } : { "width":"100%", "marginLeft": "16px" }}
                                    placeholder="Zip Code"
                                    name="zip"
                                    value={formObject.zip}
                                    type="number"
                                    onChange={(e) => handleChange(e)}
                                    maxLength="5"
                                    id="zip"
                                />
                                <div style={{ "width": "100%" }} className="form-error">{formObjectErrors.zipError}</div>
                            </div>
                        </div>
                        {formType !== "Shipping" && formType !== "AdminShipping" ? (
                            <>
                                <p style={{ "margin": "16px 0px 0px 0px"}}>
                                    Type of Business
                                </p>
                                <div style={{ "marginLeft":"16px" }}>
                                    <div style={{ "marginTop": "8px", "marginBottom": "8px" }}>
                                        <label>
                                            <input type="radio" style={{ "marginRight": "4px" }} onChange={(e) => handleChange(e)} value="Dealer" name="type" data-testid="type" />
                                            Dealer
                                        </label>
                                    </div>
                                    <div style={{ "marginTop": "8px", "marginBottom": "8px" }}>
                                        <label>
                                            <input type="radio" style={{ "marginRight": "4px" }} onChange={(e) => handleChange(e)} value="Distributor" name="type" data-testid="type" />
                                            Distributor
                                        </label>
                                    </div>
                                    <div style={{ "marginTop": "8px", "marginBottom": "8px" }}>
                                        <label>
                                            <input type="radio" style={{ "marginRight": "4px" }} onChange={(e) => handleChange(e)} value="Manufacturer" name="type" data-testid="type" />
                                            Manufacturer
                                        </label>
                                    </div>
                                    <div style={{ "marginTop": "8px", "marginBottom": "16px" }}>
                                        <label>
                                            <input type="radio" style={{ "marginRight": "4px" }} onChange={(e) => handleChange(e)} value="Government" name="type" data-testid="type" />
                                            Government
                                        </label>
                                    </div>
                                </div>
                                <div className="form-error">{formObjectErrors.typeError}</div>
                                <p style={{ "margin": "16px 0px 0px 0px"}}>
                                    Describe Your Business
                                </p>
                                <div style={{ "marginLeft":"16px" }}>
                                    <div style={{ "marginTop": "8px", "marginBottom": "8px" }}>
                                        <label>
                                            <input type="radio" style={{ "marginRight": "4px" }} onChange={(e) => handleChange(e)} value="Brick and Mortar" name="description" data-testid="description" />
                                            Brick and Mortar
                                        </label>
                                    </div>
                                    <div style={{ "marginTop": "8px", "marginBottom": "8px" }}>
                                        <label>
                                            <input type="radio" style={{ "marginRight": "4px" }} onChange={(e) => handleChange(e)} value="eCommerce" name="description" data-testid="description" />
                                            eCommerce
                                        </label>
                                    </div>
                                    <div style={{ "marginTop": "8px" }}>
                                        <label>
                                            <input type="radio" style={{ "marginRight": "4px" }} onChange={(e) => handleChange(e)} value="Both" name="description" data-testid="description" />
                                            Both
                                        </label>
                                    </div>
                                </div>
                                <div className="form-error">{formObjectErrors.descriptionError}</div>
                        </>
                        ) : null}                     
                    </>
                ) : null}
                {formType !== "Shipping" && formType !== "AdminShipping" ? (
                    <>
                        <p style={{ "margin": "16px 0px 0px 0px"}}>
                            <span className="required-star">*</span>Comment or Message
                        </p>
                        <textarea
                            className="w3-input w3-border"
                            style={{ "width":"100%", "maxWidth": "650px", "height": "100px" }}
                            placeholder="Comment or Message"
                            name="message"
                            value={formObject.message}
                            type="text"
                            onChange={(e) => handleChange(e)}
                            data-testid="message"
                        />
                        <div className="form-error">{formObjectErrors.messageError}</div>
                    </>
                ) : null}
                {hideButton ? (
                    null
                ) : submitButtonText && formType === "Contact" && formObject.firstName && formObject.lastName && formObject.phone && 
                formObject.email && formObject.address1 && formObject.city && formObject.state && formObject.zip && 
                formObject.message && !submitted ? (
                    <button id="info-form-submit-button" style={{ "marginTop": "24px" }} className="w3-button w3-padding-large w3-border" type="submit" ><b>{submitButtonText}</b></button>
                ) : submitButtonText && formType === "Support" && formObject.firstName && formObject.lastName && formObject.phone && 
                formObject.email && formObject.message && !submitted ? (
                    <button id="info-form-submit-button" style={{ "marginTop": "24px" }} className="w3-button w3-padding-large w3-border" type="submit" ><b>{submitButtonText}</b></button>
                ) : submitButtonText && formType === "Shipping" && formObject.firstName && formObject.lastName && formObject.phone && 
                formObject.email && formObject.address1 && formObject.city && formObject.state && formObject.zip && !submitted ? (
                    <button id="info-form-submit-button" style={{ "marginTop": "24px", "float": "right" }} className="w3-button w3-padding-large w3-border" type="submit" ><b>{submitButtonText}</b></button>
                ) : submitButtonText && formType === "Shipping" ? (
                    <button id="info-form-submit-button" style={{ "marginTop": "24px", "float": "right" }} disabled className="w3-button w3-padding-large w3-border" type="submit" ><b>{submitButtonText}</b></button>
                ) : submitButtonText ? (
                    <button id="info-form-submit-button" style={{ "marginTop": "24px" }} disabled className="w3-button w3-padding-large w3-border" type="submit" ><b>{submitButtonText}</b></button>
                ) : null}
                <div className="form-error">{formError}</div>
                <div style={{ "color": "green", "marginTop": "16px" }} className="form-error">{formSuccess}</div>
            </form>
        </div>
    );
}

InfoForm.propTypes = {
    buttonText: PropTypes.string,
    hideButton: PropTypes.bool,
    isMobile: PropTypes.bool,
    formType: PropTypes.string,
    propObject: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ]),
    updateParent: PropTypes.func
};

export default InfoForm;